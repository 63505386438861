import React from "react";
import Footer from "../common/footer";

const Digitalenablement = () => {
	return (
		<>
			{/* <NewNavbar /> */}
			<div class="container-fluid bg-primary py-5 mb-5 page-header">
				<div class="container py-5">
					<div class="row justify-content-center">
						<div class="col-lg-10 text-center">
							<h1 class="display-3 text-white animated slideInDown">
								Digital Enablement
							</h1>
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb justify-content-center">
									<li class="breadcrumb-item">
										<a class="text-white" href="index.php">
											Home
										</a>
									</li>
									<li
										class="breadcrumb-item text-white active"
										aria-current="page">
										Digital Enablement
									</li>
								</ol>
								<a href="/home" class="btn btn-success btn-sm">
									Go Back
								</a>
							</nav>
						</div>
					</div>
				</div>
			</div>
			<div class="container">
				<div class="row g-5">
					<div
						class="col-lg-12 wow fadeInUp"
						data-wow-delay="0.3s"
						style={{
							visibility: " visible",
							animationDelay: "0.3s",
							animationName: "fadeInUp",
						}}>
						<h4 class="mb-4">Digital Platform Access</h4>
						<p class="mb-4">
							Providing a one-stop digital platform access for guidance on
							global food safety and sustainable standards with ease and
							scalable implementation.
						</p>

						<h4 class="mb-4">Skill Development Programs</h4>
						<p class="mb-4">
							Conducting skill development programs for farmer’s organizations/
							processors with the knowledge needed for implementation.
						</p>

						<h4 class="mb-4">On-Farm Experience Zones</h4>
						<p class="mb-4">
							Promoting on-farm experience zones that showcase the
							implementation of the on farm production standards- Practical
							learning hubs for farmers.
						</p>

						<h4 class="mb-4">Buyer Certification Connection</h4>
						<p class="mb-4">Connecting buyers to certified organizations.</p>

						<h4 class="mb-4">Information Repository</h4>
						<p class="mb-4">
							Creating a comprehensive repository of information on the
							standards implementation.
						</p>

						<h4 class="mb-4">Collaboration</h4>
						<p class="mb-4">
							Fostering collaboration with Startups in the agri supply chain
						</p>
					</div>
				</div>
			</div>
			<div>
				<Footer className="jnav"></Footer>
			</div>
		</>
	);
};

export default Digitalenablement;
