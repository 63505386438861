import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";
const Aboutus = () => {
	return (
		<>
			<NewNavbar />
			<div
				style={{
					backgroundImage: "url('../images/globalacademybg.jpg')",
					backgroundRepeat: "no-repeat",
				}}>
				<div
					className="container"
					style={{ paddingTop: "30px", paddingBottom: "30px" }}>
					{/* <h1 className="">About Us</h1>
          <div className="row">
            <div class="col" style={{}}>
              <p class="mb-4 fs">
                We are committed to digitally empowering stakeholders across the
                food supply chain by promoting compliance with global Food
                Safety and Sustainability Standards. Through our innovative
                approach, we bridge the gap between knowledge and practice,
                supporting farmers, processors, and other food supply chain
                actors in meeting international standards.
              </p>
            </div>
          </div> */}

					<h1 className="">Key Objectives</h1>
					<div className="row">
						<div class="col" style={{}}>
							{/* <p class="mb-4">
							Our objective is to tackle the critical challenges faced by
							farmers' organizations and food processors in adopting and scaling
							global standards. Our key objectives are to:
						</p> */}
							<ul style={{ paddingLeft: "50px" }} className="mx-5 fs">
								<li style={{ listStyle: "disc" }}>
									Empowering stakeholders across the food supply chain by
									promoting compliance with Global Food Safety and
									Sustainability Standards through digital platform.
								</li>
								<li style={{ listStyle: "disc" }}>
									Bridging the gap between knowledge and practice and empowering
									farmers to reduce their carbon footprint by adhering to these
									standards.
								</li>
								<li style={{ listStyle: "disc" }}>
									Expanding the availability of skilled professionals across the
									food supply chain.
								</li>
								<li style={{ listStyle: "disc" }}>
									Enhancing the financial sustainability of farmers and
									processors.
								</li>
								<li style={{ listStyle: "disc" }}>
									<li style={{ listStyle: "disc" }}>
										Ensuring the production of safe, high-quality food for
										consumers worldwide.
									</li>
								</li>
								<li style={{ listStyle: "disc" }}>
									Supporting the achievement of the United Nations Sustainable
									Development Goals (SDGs).
								</li>
							</ul>
						</div>
					</div>

					<h1 className="">Vision</h1>
					<div className="row">
						<div class="col" style={{}}>
							<p class="mb-4 fs">
								To empower all food supply chain stakeholders through a holistic
								solution for global compliance with food safety and
								sustainability standards.
							</p>
						</div>
					</div>

					<h1 className="">Mission</h1>
					<div className="row">
						<div class="col" style={{}}>
							<p class="mb-4 fs">
								To create a digital platform offering virtual and instructor-led
								training alongside model farms showcasing best practices and
								standards compliance.
							</p>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Aboutus;
