import React from "react";
// import { Navbar } from "react-bootstrap";
import "font-awesome/css/font-awesome.min.css";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
const NewDigitalLibrary = () => {
	return (
		<>
			<NewNavbar />
			<div className="row container-fluid" style={{ paddingTop: "30px" }}>
				<div className="col-2">
					<div class="sidenav">
						<a href="#about">Courses</a>
						<a href="#services">Discussion&nbsp;Board</a>
						<a href="#clients">Library</a>
						<a href="#contact">Placements</a>
					</div>
				</div>
				<div className="col">
					<div
						className="container"
						style={{
							backgroundColor: "#ffffff",
							height: "130px",
							paddingTop: "30px",
							marginBottom: "30px",
							borderRadius: "20px",
						}}>
						{/* <ul class="nav nav-tabs" style={{ paddingTop: "30px" }}>
              <li class="active" style={{ paddingRight: "30px" }}>
                <a
                  href="#"
                  style={{
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Videos
                </a>
              </li>
              <li class="" style={{ paddingRight: "30px" }}>
                <a
                  class=""
                  href="#"
                  style={{
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Files and Docs
                </a>
              </li>
              <li style={{ paddingRight: "30px" }}>
                <a
                  href="#"
                  style={{
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  eBooks
                </a>
              </li>
            </ul> */}
						<div className="newcoures">
							<ul class="nav nav-tabs " role="tablist">
								<li class="nav-item" role="presentation">
									<a
										class="nav-link active"
										id="simple-tab-0"
										data-bs-toggle="tab"
										href="#simple-tabpanel-0"
										role="tab"
										aria-controls="simple-tabpanel-0"
										aria-selected="true"
										// style={{border:"none"}}
									>
										Videos
									</a>
								</li>
								<li class="nav-item" role="presentation">
									<a
										class="nav-link"
										id="simple-tab-1"
										data-bs-toggle="tab"
										href="#simple-tabpanel-1"
										role="tab"
										aria-controls="simple-tabpanel-1"
										aria-selected="false">
										Files and Docs
									</a>
								</li>
								<li class="nav-item" role="presentation">
									<a
										class="nav-link"
										id="simple-tab-2"
										data-bs-toggle="tab"
										href="#simple-tabpanel-2"
										role="tab"
										aria-controls="simple-tabpanel-2"
										aria-selected="false">
										eBooks
									</a>
								</li>
							</ul>
						</div>

						<div className="row">
							<div
								className="col-4"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-around",
									paddingTop: "20px",
								}}>
								<div
									style={{
										backgroundColor: "rgb(243 236 236)",
										paddingRight: "10px",
										paddingLeft: "10px",
										borderRadius: "20px",
										// width: "100%",
									}}>
									All
								</div>

								<div
									style={{
										backgroundColor: "rgb(243 236 236)",
										paddingRight: "10px",
										paddingLeft: "10px",
										borderRadius: "20px",
									}}>
									Organic
								</div>

								<div
									style={{
										backgroundColor: "rgb(243 236 236)",
										paddingRight: "10px",
										paddingLeft: "10px",
										borderRadius: "20px",
									}}>
									Horticulture
								</div>
							</div>
						</div>
					</div>
					<div
						className="container"
						style={{ backgroundColor: "#ffffff", borderRadius: "20px" }}>
						<div class="tab-content " id="tab-content">
							<div
								class="tab-pane active"
								id="simple-tabpanel-0"
								role="tabpanel"
								aria-labelledby="simple-tab-0">
								<div className="row">
									<div className="col">
										<ul
											class="nav nav-tabs"
											style={{
												paddingTop: "30px",
												display: "flex",
												alignItems: "center",
												paddingBottom: "10px",
											}}>
											<li class="active" style={{ paddingRight: "30px" }}>
												<a
													href="#"
													style={{
														color: "#000000",
														fontSize: "16px",
														fontWeight: "500",
													}}>
													All
												</a>
											</li>
											<li class="" style={{ paddingRight: "30px" }}>
												<a
													class=""
													href="#"
													style={{
														color: "#000000",
														fontSize: "16px",
														fontWeight: "500",
													}}>
													Soil Testing
												</a>
											</li>
											<li style={{ paddingRight: "500px" }}>
												<a
													href="#"
													style={{
														color: "#000000",
														fontSize: "16px",
														fontWeight: "500",
													}}>
													Drip Irrigation
												</a>
											</li>

											{/* <li

                      >
                        <input
                          type="search"
                          id=""
                          class=""
                          style={{
                            border: "none",
                            backgroundColor: "rgb(243 236 236)",
                            height: "40px",
                            borderRadius: "20px",
                            paddingLeft: "10px",
                          }}
                          placeholder="search videos"
                        ></input>
                      </li> */}

											<input
												type="search"
												id=""
												class=""
												style={{
													border: "none",
													backgroundColor: "rgb(243 236 236)",
													height: "40px",
													borderRadius: "20px",
													paddingLeft: "10px",
												}}
												placeholder="search videos"></input>
										</ul>{" "}
									</div>
								</div>

								<div className="row" style={{ padding: "30px 0px 0px 0px" }}>
									<div className="col-4">
										<div style={{ borderRadius: "10px", border: "solid 1px" }}>
											<img
												style={{
													padding: "12px",
													width: "100%",
													height: "241px",
												}}
												class=""
												src="../images/newcourseimg1.png"
												alt=""
											/>
											<div
												style={{
													fontSize: "20px",
													fontWeight: "600",
													paddingLeft: "12px",
												}}>
												Importance Of Soil Testing
											</div>
											<div
												style={{
													fontSize: "14px",
													fontWeight: "400",
													paddingLeft: "12px",
												}}>
												eFresh Agribusiness Solutions Private Limited
											</div>
											<div
												className="row"
												style={{
													display: "flex",
													alignItems: "center",
													padding: "20px 12px 12px 12px",
												}}>
												<div className="col-5">
													<img class="" src="../images/saveicon.png" alt="" />
												</div>
												<div
													className="col-7"
													style={{
														display: "flex",
														justifyContent: "space-between",
													}}>
													<div
														style={{
															border: "solid 1px #cdc6c6",
															paddingRight: "10px",
															paddingLeft: "10px",
															borderRadius: "20px",
														}}>
														<i class="fa fa-clock"></i> &nbsp;2 Min
													</div>
													<div
														style={{
															backgroundColor: "#cdc6c6",
															paddingRight: "10px",
															paddingLeft: "10px",
															borderRadius: "20px",
														}}>
														<img
															class=""
															src="../images/linkarrow.png"
															alt=""
														/>{" "}
														Link
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-4">
										<div style={{ borderRadius: "10px", border: "solid 1px" }}>
											<img
												style={{
													padding: "12px",
													width: "100%",
													height: "241px",
												}}
												class=""
												src="../images/newcourseimg1.png"
												alt=""
											/>
											<div
												style={{
													fontSize: "20px",
													fontWeight: "600",
													paddingLeft: "12px",
												}}>
												Importance Of Soil Testing
											</div>
											<div
												style={{
													fontSize: "14px",
													fontWeight: "400",
													paddingLeft: "12px",
												}}>
												eFresh Agribusiness Solutions Private Limited
											</div>
											<div
												className="row"
												style={{
													display: "flex",
													alignItems: "center",
													padding: "20px 12px 12px 12px",
												}}>
												<div className="col-5">
													<img class="" src="../images/saveicon.png" alt="" />
												</div>
												<div
													className="col-7"
													style={{
														display: "flex",
														justifyContent: "space-between",
													}}>
													<div
														style={{
															border: "solid 1px #cdc6c6",
															paddingRight: "10px",
															paddingLeft: "10px",
															borderRadius: "20px",
														}}>
														<i class="fa fa-clock"></i> &nbsp;2 Min
													</div>
													<div
														style={{
															backgroundColor: "#cdc6c6",
															paddingRight: "10px",
															paddingLeft: "10px",
															borderRadius: "20px",
														}}>
														<img
															class=""
															src="../images/linkarrow.png"
															alt=""
														/>{" "}
														Link
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-4">
										<div style={{ borderRadius: "10px", border: "solid 1px" }}>
											<img
												style={{
													padding: "12px",
													width: "100%",
													height: "241px",
												}}
												class=""
												src="../images/newcourseimg1.png"
												alt=""
											/>
											<div
												style={{
													fontSize: "20px",
													fontWeight: "600",
													paddingLeft: "12px",
												}}>
												Importance Of Soil Testing
											</div>
											<div
												style={{
													fontSize: "14px",
													fontWeight: "400",
													paddingLeft: "12px",
												}}>
												eFresh Agribusiness Solutions Private Limited
											</div>
											<div
												className="row"
												style={{
													display: "flex",
													alignItems: "center",
													padding: "20px 12px 12px 12px",
												}}>
												<div className="col-5">
													<img class="" src="../images/saveicon.png" alt="" />
												</div>
												<div
													className="col-7"
													style={{
														display: "flex",
														justifyContent: "space-between",
													}}>
													<div
														style={{
															border: "solid 1px #cdc6c6",
															paddingRight: "10px",
															paddingLeft: "10px",
															borderRadius: "20px",
														}}>
														<i class="fa fa-clock"></i> &nbsp;2 Min
													</div>
													<div
														style={{
															backgroundColor: "#cdc6c6",
															paddingRight: "10px",
															paddingLeft: "10px",
															borderRadius: "20px",
														}}>
														<img
															class=""
															src="../images/linkarrow.png"
															alt=""
														/>{" "}
														Link
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="row" style={{ padding: "30px 0px 30px 0px" }}>
									<div className="col-4">
										<div style={{ borderRadius: "10px", border: "solid 1px" }}>
											<img
												style={{
													padding: "12px",
													width: "100%",
													height: "241px",
												}}
												class=""
												src="../images/newcourseimg1.png"
												alt=""
											/>
											<div
												style={{
													fontSize: "20px",
													fontWeight: "600",
													paddingLeft: "12px",
												}}>
												Importance Of Soil Testing
											</div>
											<div
												style={{
													fontSize: "14px",
													fontWeight: "400",
													paddingLeft: "12px",
												}}>
												eFresh Agribusiness Solutions Private Limited
											</div>
											<div
												className="row"
												style={{
													display: "flex",
													alignItems: "center",
													padding: "20px 12px 12px 12px",
												}}>
												<div className="col-5">
													<img class="" src="../images/saveicon.png" alt="" />
												</div>
												<div
													className="col-7"
													style={{
														display: "flex",
														justifyContent: "space-between",
													}}>
													<div
														style={{
															border: "solid 1px #cdc6c6",
															paddingRight: "10px",
															paddingLeft: "10px",
															borderRadius: "20px",
														}}>
														<i class="fa fa-clock"></i> &nbsp;2 Min
													</div>
													<div
														style={{
															backgroundColor: "#cdc6c6",
															paddingRight: "10px",
															paddingLeft: "10px",
															borderRadius: "20px",
														}}>
														<img
															class=""
															src="../images/linkarrow.png"
															alt=""
														/>{" "}
														Link
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-4">
										<div style={{ borderRadius: "10px", border: "solid 1px" }}>
											<img
												style={{
													padding: "12px",
													width: "100%",
													height: "241px",
												}}
												class=""
												src="../images/newcourseimg1.png"
												alt=""
											/>
											<div
												style={{
													fontSize: "20px",
													fontWeight: "600",
													paddingLeft: "12px",
												}}>
												Importance Of Soil Testing
											</div>
											<div
												style={{
													fontSize: "14px",
													fontWeight: "400",
													paddingLeft: "12px",
												}}>
												eFresh Agribusiness Solutions Private Limited
											</div>
											<div
												className="row"
												style={{
													display: "flex",
													alignItems: "center",
													padding: "20px 12px 12px 12px",
												}}>
												<div className="col-5">
													<img class="" src="../images/saveicon.png" alt="" />
												</div>
												<div
													className="col-7"
													style={{
														display: "flex",
														justifyContent: "space-between",
													}}>
													<div
														style={{
															border: "solid 1px #cdc6c6",
															paddingRight: "10px",
															paddingLeft: "10px",
															borderRadius: "20px",
														}}>
														<i class="fa fa-clock"></i> &nbsp;2 Min
													</div>
													<div
														style={{
															backgroundColor: "#cdc6c6",
															paddingRight: "10px",
															paddingLeft: "10px",
															borderRadius: "20px",
														}}>
														<img
															class=""
															src="../images/linkarrow.png"
															alt=""
														/>{" "}
														Link
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-4">
										<div style={{ borderRadius: "10px", border: "solid 1px" }}>
											<img
												style={{
													padding: "12px",
													width: "100%",
													height: "241px",
												}}
												class=""
												src="../images/newcourseimg1.png"
												alt=""
											/>
											<div
												style={{
													fontSize: "20px",
													fontWeight: "600",
													paddingLeft: "12px",
												}}>
												Importance Of Soil Testing
											</div>
											<div
												style={{
													fontSize: "14px",
													fontWeight: "400",
													paddingLeft: "12px",
												}}>
												eFresh Agribusiness Solutions Private Limited
											</div>
											<div
												className="row"
												style={{
													display: "flex",
													alignItems: "center",
													padding: "20px 12px 12px 12px",
												}}>
												<div className="col-5">
													<img class="" src="../images/saveicon.png" alt="" />
												</div>
												<div
													className="col-7"
													style={{
														display: "flex",
														justifyContent: "space-between",
													}}>
													<div
														style={{
															border: "solid 1px #cdc6c6",
															paddingRight: "10px",
															paddingLeft: "10px",
															borderRadius: "20px",
														}}>
														<i class="fa fa-clock"></i> &nbsp;2 Min
													</div>
													<div
														style={{
															backgroundColor: "#cdc6c6",
															paddingRight: "10px",
															paddingLeft: "10px",
															borderRadius: "20px",
														}}>
														<img
															class=""
															src="../images/linkarrow.png"
															alt=""
														/>{" "}
														Link
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								class="tab-pane"
								id="simple-tabpanel-1"
								role="tabpanel"
								aria-labelledby="simple-tab-1">
								Tab 2 selected
							</div>
							<div
								class="tab-pane"
								id="simple-tabpanel-2"
								role="tabpanel"
								aria-labelledby="simple-tab-2">
								Tab 3 selected
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default NewDigitalLibrary;
