import { ChatAiWidget } from "@sendbird/chat-ai-widget";
import "@sendbird/chat-ai-widget/dist/style.css";
import React from "react";

export default function Senbird() {
	return (
		<ChatAiWidget
			applicationId="BE407740-CDC3-4E88-BA5F-A37D37D427BF" // Your Sendbird Application ID
			botId="onboarding_bot" // Your Bot ID
		/>
		// <iframe
		// 	title="AI Chatbot"
		// 	src="https://aichatbot.sendbird.com/playground/index.html?app_id=BE407740-CDC3-4E88-BA5F-A37D37D427BF&bot_id=onboarding_bot&region=ap-8"
		// 	width="100%"
		// 	style={{ height: "100%", minHeight: "700px" }}
		// 	frameborder="0"></iframe>
	);
}
