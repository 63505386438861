import React from "react";
import Footer from "../common/footer";
import Comingsoon from "./comingsoon";
import NewNavbar from "./newNavbar";

export default function Faqs() {
	return (
		<>
			<NewNavbar />
			<Comingsoon />
			<Footer />
		</>
	);
}
