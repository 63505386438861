import moment from "moment";
import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useSelector } from "react-redux";
import Avatar from "../components/Avatar";
import Carousel from "../components/Carousel";
import CardFooter from "../components/home/post_card/CardFooter";
import Addpost from "./addpost";
import "./communitystyles.css";
import ImageCropper from "./imagecroppopup";
import NewNavbar from "./newNavbar";
const Dashboard = () => {
	const { homePosts, auth } = useSelector((state) => state);
	console.log("auth", auth, homePosts);
	const { theme } = useSelector((state) => state);
	const communitiesArray = [1, 2, 3, 4, 1];
	const mostActiveCommunitiesArray = [1, 2, 3, 4, 5];
	const [showShareModal, setShowShareModal] = useState(false);
	const [showNewPostModal, setShowNewPostModal] = useState(false);
	const [showAudienceModal, setShowAudienceModal] = useState(false);
	const [showJoinModal, setShowJoinModal] = useState(false);
	const [showProfilePhotoModal, setShowProfilePhotoModal] = useState(false);
	const [croppedImageProfilePhoto, setCroppedImageProfilePhoto] =
		useState(undefined);
	const [imageToCropProfilePhoto, setImageToCropProfilePhoto] =
		useState(undefined);
	const [communityname, setCommunityName] = useState("");
	const [address, setAddress] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [website, setWebsite] = useState("");
	const [aboutCommunity, setAboutCommunity] = useState("");
	const [communityType, setCommunityType] = useState("");
	const [communityAccess, setCommunityAccess] = useState("");
	const [croppedImage, setCroppedImage] = useState(undefined);
	const [croppedImageCoverPhoto, setCroppedImageCoverPhoto] =
		useState(undefined);
	const [size, setSize] = useState("");
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);
	const [show4, setShow4] = useState(false);
	const [imageToCropCoverPhoto, setImageToCropCoverPhoto] = useState(undefined);
	const [imageToCrop, setImageToCrop] = useState(undefined);
	const [getUpload, setGetUpload] = useState();
	const handleCloseShareModal = () => {
		setShowShareModal(false);
	};
	const handleOpenShareModal = () => {
		setShowShareModal(true);
	};
	const handleOpen = () => {
		setShow(true);
	};
	const inputFileCoverPhoto = useRef(null);
	const onButtonClickCoverPhoto = () => {
		// `current` points to the mounted file input element
		inputFileCoverPhoto.current.click();
	};
	const onUploadFileCoverPhoto = (event) => {
		let sizeInBytes = event.target.files[0].size;
		var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		setSize(sizeInMB + "MB");

		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const image = reader.result;
				// console.log(image);
				// setGetUpload(image);
				setImageToCropCoverPhoto(image);
			});
			reader.readAsDataURL(event.target.files[0]);
		}
	};
	const handleOpen2 = () => {
		setShow2(true);
		setShowJoinModal(false);
	};
	const handleClose2 = () => {
		setShow2(false);
	};
	const handleCloseNewPostModal = () => {
		setShowNewPostModal(false);
	};
	const handleOpenNewPostModal = () => {
		setShowNewPostModal(true);
	};

	const handleCloseAudienceModal = () => {
		setShowAudienceModal(false);
	};
	const inputFile = useRef(null);
	const onButtonClick = () => {
		// `current` points to the mounted file input element
		inputFile.current.click();
	};
	const onUploadFile = (event) => {
		let sizeInBytes = event.target.files[0].size;
		var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		setSize(sizeInMB + "MB");

		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const image = reader.result;
				console.log(image);
				setGetUpload(image);
				setImageToCrop(image);
			});
			reader.readAsDataURL(event.target.files[0]);
		}
	};
	const inputFileProfilePhoto = useRef(null);
	const onButtonClickProfilePhoto = () => {
		// `current` points to the mounted file input element
		inputFileProfilePhoto.current.click();
	};

	const handleOpenAudienceModal = () => {
		setShowAudienceModal(true);
		setShowNewPostModal(false);
	};

	const handleOpenjoinModal = () => {
		setShowJoinModal(true);
	};
	const handleCloseJoinModal = () => {
		setShowJoinModal(false);
	};

	const handleOpenprofilePhotoModal = () => {
		setShowProfilePhotoModal(true);
		setShowJoinModal(false);
	};
	const handleCloseProfilePhotoModal = () => {
		setShowProfilePhotoModal(false);
		setShowJoinModal(true);
	};
	const onUploadFileProfilePhoto = (event) => {
		let sizeInBytes = event.target.files[0].size;
		var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		setSize(sizeInMB + "MB");

		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const image = reader.result;
				// console.log(image);
				// setGetUpload(image);
				setImageToCropProfilePhoto(image);
			});
			reader.readAsDataURL(event.target.files[0]);
		}
	};
	const handleCreateCommunity = () => {
		console.log(
			communityname,
			address,
			email,
			phone,
			website,
			aboutCommunity,
			communityType,
			communityAccess,
			croppedImage,
			croppedImageCoverPhoto
		);

		// const payload = {
		//   coverphoto: croppedImageCoverPhoto,
		//   profilephoto: croppedImage,
		//   communityname: communityname,
		//   address: address,
		//   email: email,
		//   phone: phone,
		//   website: website,
		//   communityType: communityType,
		//   aboutCommunity: aboutCommunity,
		//   communityAccess: communityAccess,
		// };

		// axios({
		//   method: "post",
		//   url: "http://localhost:8080/createCommunity",
		//   data: payload, // you are sending body instead
		//   headers: {
		//     // 'Authorization': `bearer ${token}`,
		//     "Content-Type": "application/json",
		//   },
		// });
	};

	const FirstTab = (props) => {
		return (
			<div>
				<Button
					onClick={buttonClick}
					style={{ backgroundColor: "#3CCE8F", border: "none" }}>
					Create Community
				</Button>
			</div>
		);
	};
	const [show3, setShow3] = useState(false);
	const handleClose3 = () => {
		setShow3(false);
	};
	const handleClose4 = () => {
		setShow4(false);
	};
	const buttonClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// console.log("Button clicked");
		setShow4(true);
	};
	const buttonClick1 = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// console.log("Button clicked");
		setShow3(true);
	};
	const handleClose = () => {
		setShow(false);
	};

	return (
		//   <div className="col-md-4">
		//     <RightSideBar />
		//   </div>
		// </div>
		<>
			{/* <Header /> */}
			<NewNavbar />
			<>
				<Modal
					show={show2}
					onHide={handleClose2}
					dialogClassName="modal-sm-30px"
					size="md"
					backdropClassName="childmodal"
					// backdrop="static"
					centered
					// onEntered={() => props.handleEntered}
				>
					<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
						<div className="row">
							<div className="col-11">
								<h2
									style={{
										fontSize: "18px",
										fontWeight: "500",
										padding: "15px",
									}}>
									Select Image
								</h2>
							</div>
							<div
								className="col-1"
								style={{ padding: "15px", marginLeft: "-20px" }}>
								<img src="./images/closeOne.png"></img>
							</div>
						</div>
						<hr style={{ margin: "0px" }} />
						<ImageCropper
							imageToCrop={imageToCrop}
							onImageCropped={(croppedImage) => {
								setCroppedImage(croppedImage);
							}}
						/>
						{imageToCrop === undefined && (
							<div
								style={{
									//   backgroundColor: "#efefef",
									height: "50vh",
									width: "34vw",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}>
								<img
									src="./images/Communities-icon.svg"
									style={{ height: "42vh" }}></img>
							</div>
						)}

						<div
							className="row"
							style={{
								backgroundColor: "#efefef",
								padding: "10px 10px 10px 10px",
							}}>
							<div className="col-8">
								<input
									type="file"
									id="file"
									ref={inputFile}
									style={{ display: "none" }}
									onChange={onUploadFile}
								/>
								<button
									onClick={onButtonClick}
									style={{
										background: "#fff",
										border: "none",
										padding: "5px",
										borderRadius: "5px",
									}}>
									<img src="./images/camera.svg" alt="" />{" "}
									<span style={{ fontSize: "12px" }}>Select Photo</span>
								</button>
								{/* <button
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button> */}
							</div>
							<div className="col-2"></div>
							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}>
										Upload
									</Button>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal
					show={show}
					onHide={handleClose}
					dialogClassName="modal-sm-30px"
					size="md"
					backdropClassName="childmodal"
					// backdrop="static"
					centered
					// onEntered={() => props.handleEntered}
				>
					<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
						<div className="row">
							<div className="col-11">
								<h2
									style={{
										fontSize: "18px",
										fontWeight: "500",
										padding: "15px",
									}}>
									Select Image
								</h2>
							</div>
							<div className="col-1" style={{ padding: "15px" }}>
								<img src="./images/closeOne.png"></img>
							</div>
						</div>
						<hr />
						{imageToCropCoverPhoto ? (
							<ImageCropper
								imageToCrop={imageToCropCoverPhoto}
								onImageCropped={(croppedImage) => {
									console.log(croppedImage);
									setCroppedImageCoverPhoto(croppedImage);
								}}
								coverphoto="coverphoto"
							/>
						) : (
							<div
								style={{
									backgroundColor: "#efefef",
									height: "50vh",
									width: "34vw",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}>
								<img
									src="./images/cover_image_icon.svg"
									style={{ height: "90px" }}></img>
							</div>
						)}

						<div
							style={{
								fontSize: "13px",
								fontWeight: "300",
								textAlign: "center",
								paddingTop: "10px",
							}}>
							Recommended size - 1920 x 1080px, Max. 2 MB
						</div>
						<div
							className="row"
							style={{ backgroundColor: "#efefef", padding: "10px 10px" }}>
							<div className="col-8">
								<input
									type="file"
									id="file"
									ref={inputFileCoverPhoto}
									style={{ display: "none" }}
									onChange={onUploadFileCoverPhoto}
								/>
								<button
									onClick={onButtonClickCoverPhoto}
									style={{
										background: "#fff",
										border: "none",
										padding: "5px",
										borderRadius: "5px",
									}}>
									<img src="./images/camera.svg" alt="" />{" "}
									<span style={{ fontSize: "12px" }}>Select Photo</span>
								</button>
								{/* <button
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button> */}
							</div>
							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#fff",
											border: "none",
											height: "35px",
											color: "#000",
											border: "1px solid #000",
											borderRadius: "15px",
										}}
										onClick={handleClose}>
										Cancel
									</Button>
								</div>
							</div>
							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}>
										Upload
									</Button>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal
					show={show4}
					onHide={handleClose4}
					dialogClassName="modal-sm-30px"
					size="lg"
					// backdropClassName="childmodal"
					// backdrop="static"
					centered
					style={{ borderRadius: "55px" }}
					// onEntered={() => props.handleEntered}
				>
					<Modal.Body style={{ padding: "20px 20px" }}>
						<div
							style={{ textAlign: "end", cursor: "pointer" }}
							onClick={handleClose4}>
							<img src="./images/closeOne.png"></img>
						</div>
						<div
							className="lightgreen"
							style={{ fontSize: "32px", textAlign: "center" }}>
							Invite your contact to Food safety academy
						</div>
						<div style={{ fontSize: "18px", textAlign: "center" }}>
							Syncing your contacts is the fastest way to grow your network
						</div>
						<div
							className="card"
							style={{
								padding: "30px 10px",
								margin: "20px 50px 20px 50px",
								background: "#fbfcfd",
							}}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									fontSize: "18px",
									fontWeight: "400",
									marginBottom: "15px",
								}}>
								Directly invite your email contacts to join on Food safety
								academy
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									marginBottom: "20px",
								}}>
								<textarea
									placeholder="Enter email address here,separated by comma"
									className="form-control"
									style={{ width: "400px", border: "1px solid green" }}
									rows="1"></textarea>
							</div>

							<div style={{ display: "flex", justifyContent: "center" }}>
								<Button
									class="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
									// onclick="joinForm('MTMy',this)"
									style={{
										background: "#20ce8f",
										border: "none",
										width: "fix-content",
										padding: "7px 15px",
										borderRadius: "4px",
										minWidth: "100px",
										marginBottom: "20px",
									}}>
									Invite
								</Button>
							</div>

							<div
								class="uc-modal-footer share-footer"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									padding: "20px 12px 12px 12px",
								}}>
								<div class="uc-inv-shr-icons" style={{ display: "flex" }}>
									<a
										href="https://wa.me/?text= Join Global Food Safety and Sustainability Standards Platform for Networking, Learning and Development on uLektz to connect with members of our community, build new relationships, and get access to members-only resources for education, skills, and careers.Use this invite from me and earn up to ₹1000 for signing up.https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
										class="popover__wrapper"
										id="whatsappComRef"
										target="_blank"
										className="icons"
										rel="noreferrer">
										<img src="./images/WhatsApp.svg" alt="" />
										{/* <div class="popover__content">
                        <p>WhatsApp</p>
                      </div> */}
									</a>

									<a
										href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
										class="popover__wrapper"
										id="linkedinComRef"
										target="_blank"
										className="icons"
										rel="noreferrer">
										<img src="./images/linkedin.svg" alt="" />
										{/* <div class="popover__content">
                        <p>LinkedIn</p>
                      </div> */}
									</a>

									<a
										href="https://www.facebook.com/dialog/share?app_id=604152004970299&amp;display=popup&amp;href=https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
										class="popover__wrapper"
										id="facebookComRef"
										target="_blank"
										className="icons"
										rel="noreferrer">
										<img src="./images/fb.svg" alt="" />
										{/* <div class="popover__content">
                        <p>Facebook</p>
                      </div> */}
									</a>

									<a
										href="https://twitter.com/intent/tweet?url=https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4&amp;text= Join Global Food Safety and Sustainability Standards Platform for Networking, Learning and Development on uLektz to connect with members of our community, build new relationships, and get access to members-only resources for education, skills, and careers.Use this invite from me and earn up to ₹1000 for signing up."
										class="popover__wrapper"
										id="twitterComRef"
										target="_blank"
										className="icons"
										rel="noreferrer">
										<img src="./images/twitter.svg" alt="" />
										{/* <div class="popover__content">
                        <p>Twitter</p>
                      </div> */}
									</a>
									<input
										type="hidden"
										value="Join Global Food Safety and Sustainability Standards Platform for Networking, Learning and Development on uLektz to connect with members of our community, build new relationships, and get access to members-only resources for education, skills, and careers.Use this invite from me and earn up to ₹1000 for signing up.https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
										id="copyComRefUrl"
									/>
									<a
										href="javascript: void(0);"
										class="popover__wrapper"
										onClick="copyComUrl();"
										className="icons">
										<img src="./images/Copy.svg" alt="" />
										{/* <div class="popover__content">
                        <p>Copy message</p>
                      </div> */}
									</a>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal
					show={show3}
					onHide={handleClose3}
					dialogClassName="modal-sm-30px"
					size="lg"
					// backdropClassName="childmodal"
					// backdrop="static"
					centered
					style={{ borderRadius: "55px" }}
					// onEntered={() => props.handleEntered}
				>
					<Modal.Body style={{ padding: "0px 0px" }}>
						<div class="uc-com-create-in">
							<div className="row">
								<div className="col-11">
									<h2
										style={{
											fontSize: "18px",
											fontWeight: "500",
											padding: "15px",
										}}>
										Create Community
									</h2>
								</div>
								<div
									className="col-1"
									style={{ padding: "15px", cursor: "pointer" }}
									onClick={() => setShow3(false)}>
									<img src="./images/closeOne.png"></img>
								</div>
							</div>

							<div
								style={{
									backgroundImage:
										croppedImageCoverPhoto === undefined
											? "url(./images/common-background.png)"
											: "url('" +
											  URL.createObjectURL(croppedImageCoverPhoto) +
											  "')",

									height: "112px",
									backgroundRepeat: "no-repeat",
									backgroundSize: "cover",
									display: "flex",
									justifyContent: "end",
									alignItems: "end",
									padding: "20px",
								}}>
								<img
									src="./images/Edit_post.svg"
									style={{
										background: "#fff",
										padding: "10px 10px",
										borderRadius: "25%",
										//   width:"50px"
									}}
									onClick={handleOpen}></img>
							</div>
							<div class="uc-pro-left-img" style={{ padding: "20px 40px" }}>
								{croppedImage ? (
									<img
										src={URL.createObjectURL(croppedImage)}
										id="createComprofileimg"
										alt=""
										class="uc-profile-images clsProfileImg"
										style={{
											height: "112px",
											width: "112px",
											borderRadius: "50%",
											marginTop: "-70px",
										}}
									/>
								) : (
									<img
										src="./images/community-def-logo.png"
										id="createComprofileimg"
										alt=""
										class="uc-profile-images clsProfileImg"
										style={{
											height: "112px",
											width: "112px",
											borderRadius: "50%",
											marginTop: "-70px",
										}}
									/>
								)}

								<label class="uc-cam-icon" onClick="openCreateComProf_UpPop()">
									<img
										id="cameraicon"
										src="./images/camera.svg"
										alt=""
										style={{
											backgroundColor: "#E4E3E0",
											marginLeft: "-30px",
											padding: "6px 6px",
											borderRadius: "40%",
										}}
										onClick={handleOpen2}
									/>
								</label>
							</div>
							<div class="uc-form-comm" style={{ padding: "20px 40px" }}>
								<input
									type="text"
									id="communityname"
									name="communityname"
									placeholder="Community Name *"
									class="uc-formControl form-control"
									maxlength="100"
									autocomplete="off"
									onChange={(e) => setCommunityName(e.target.value)}
								/>
								<div class="clsCommunityCharCount" style={{ textAlign: "end" }}>
									0/100
								</div>
								{/* <p class="already-exist-show" style="display:none;">
                {" "}
                Name Already Taken
              </p> */}
							</div>
							<div class="uc-form-comm" style={{ padding: "20px 40px" }}>
								<textarea
									name="communityaddress"
									id="communityaddress"
									placeholder="Address *"
									maxlength="470"
									autocomplete="off"
									className="form-control"
									rows="4"
									onChange={(e) => setAddress(e.target.value)}></textarea>
							</div>
							<div class="uc-form-comm" style={{ padding: "20px 40px" }}>
								<input
									type="text"
									name="communityemail"
									id="communityemail"
									placeholder="Email Address (Primary or Secondary) *"
									class="uc-formControl form-control"
									autocomplete="off"
									onChange={(e) => setEmail(e.target.value)}
								/>
								<span class="errorMsg" id="emailErr"></span>
							</div>
							<div class="uc-form-comm" style={{ padding: "20px 40px" }}>
								<input
									type="tel"
									maxlength="10"
									name="communitycontactno"
									id="communitycontactno"
									placeholder="Phone / Mobile *"
									class="uc-formControl form-control"
									autocomplete="off"
									onChange={(e) => setPhone(e.target.value)}
								/>
								<span class="errorMsg" id="phonenoErr"></span>
							</div>
							<div class="uc-form-comm" style={{ padding: "20px 40px" }}>
								<input
									type="text"
									name="communitywebsiteurl"
									id="communitywebsiteurl"
									placeholder="Website"
									class="uc-formControl form-control"
									autocomplete="off"
									onChange={(e) => setWebsite(e.target.value)}
								/>
								<span class="errorMsg" id="websiteurlErr"></span>
							</div>
							<div class="uc-form-comm" style={{ padding: "20px 40px" }}>
								<textarea
									name="aboutcommunity"
									id="aboutcommunity"
									placeholder="About Community *"
									autocomplete="off"
									className="form-control"
									rows="4"
									onChange={(e) =>
										setAboutCommunity(e.target.value)
									}></textarea>
							</div>
							<h2
								style={{
									padding: "0px 40px 0px 40px",
									fontWeight: "500",
									fontSize: "15px",
								}}>
								Community Type
							</h2>
							<div class="radioboxes">
								<p style={{ paddingLeft: "60px", paddingTop: "0px" }}>
									<label>
										<div className="row">
											<div className="col-1">
												<input
													type="radio"
													name="comType"
													id="academic_community"
													value="academiccommunity"
													onChange={(e) => setCommunityType(e.target.value)}
												/>
											</div>
											<div className="col" style={{ paddingLeft: "10px" }}>
												<span style={{ fontSize: "14px", fontWeight: "500" }}>
													Academic Community
												</span>
												<br />
												<small style={{ fontSize: "12px" }}>
													Schools, College and Universities
												</small>
											</div>
										</div>
									</label>
								</p>
								<p style={{ paddingLeft: "60px", paddingTop: "15px" }}>
									<label>
										<div className="row">
											<div className="col-1">
												<input
													type="radio"
													name="comType"
													id="academic_community"
													value="professional&socialcommunity"
													onChange={(e) => setCommunityType(e.target.value)}
												/>
											</div>
											<div className="col">
												<span style={{ fontSize: "14px", fontWeight: "500" }}>
													Professional & Social Community
												</span>
												<br />
												<small style={{ fontSize: "12px" }}>
													Associations, Societies, NGO's Etc.
												</small>
											</div>
										</div>
									</label>
								</p>
								<p style={{ paddingLeft: "60px", paddingTop: "15px" }}>
									<label>
										<div className="row">
											<div className="col-1">
												<input
													type="radio"
													name="comType"
													id="academic_community"
													value="businesscommunity"
													onChange={(e) => setCommunityType(e.target.value)}
												/>
											</div>
											<div className="col">
												<span style={{ fontSize: "14px", fontWeight: "500" }}>
													Business Community
												</span>
												<br />
												<small style={{ fontSize: "12px" }}>
													Business Organisation & Corporates
												</small>
											</div>
										</div>
									</label>
								</p>
							</div>
							<h2
								class="uc-crt-com-radio-hd"
								style={{
									padding: "0px 40px 0px 40px",
									fontWeight: "500",
									fontSize: "15px",
								}}>
								Community Access
							</h2>
							<div class="radioboxes">
								<p style={{ paddingLeft: "60px" }}>
									<label>
										<div className="row">
											<div className="col-1">
												<input
													type="radio"
													name="comType1"
													id="academic_community1"
													value="publiccommunity"
													onChange={(e) => setCommunityAccess(e.target.value)}
												/>
											</div>
											<div className="col">
												<span style={{ fontSize: "14px", fontWeight: "500" }}>
													Public Community
												</span>
												<br />
												<small style={{ fontSize: "12px" }}>
													Any one can join without admin approval
												</small>
											</div>
										</div>
									</label>
								</p>
								<p style={{ paddingLeft: "60px", paddingTop: "15px" }}>
									<label>
										<div className="row">
											<div className="col-1">
												<input
													type="radio"
													name="comType1"
													id="academic_community1"
													value="privatecommunity"
													onChange={(e) => setCommunityAccess(e.target.value)}
												/>
											</div>
											<div className="col">
												<span style={{ fontSize: "14px", fontWeight: "500" }}>
													Private Community
												</span>
												<br />
												<small style={{ fontSize: "12px" }}>
													People can join only after admin approval
												</small>
											</div>
										</div>
									</label>
								</p>
							</div>
							<div
								style={{
									textAlign: "end",
									backgroundColor: "#efefef",
									padding: "10px 10px",
								}}>
								<Button
									style={{
										background: "#3CCE8F",
										border: "none",
										height: "35px",
										//   width:"40px",
										borderRadius: "15px",
										//   display:"flex",
										//   alignItems:"center",
										//   justifyContent:"end"
									}}
									onClick={handleCreateCommunity}>
									create
								</Button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal
					show={showJoinModal}
					onHide={handleCloseJoinModal}
					dialogClassName="modal-sm-30px"
					size="md"
					backdropClassName="childmodal"
					// backdrop="static"
					centered
					// onEntered={() => props.handleEntered}
				>
					<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
						<div className="row">
							<div className="col-11" style={{ paddingLeft: "0px" }}>
								<h2
									style={{
										fontSize: "18px",
										fontWeight: "500",
										padding: "10px",
										marginBottom: "0px",
										display: "flex",
									}}>
									Join - Nuclear Physics &nbsp;
									<span class="public-text" onClick="communityChangeNew(5421)">
										<span class="uc-star-count">
											<a
												href="javascript:void(0)"
												class="cmn-btn uc-normal-btn">
												<img src="./images/Public_icon.svg" /> Public
											</a>
										</span>
									</span>{" "}
								</h2>
							</div>
							<div
								className="col-1"
								style={{ padding: "10px", cursor: "pointer" }}
								onClick={handleCloseJoinModal}>
								<img src="./images/closeOne.png"></img>
							</div>
						</div>
						<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
						<div
							class="uc-pro-left-img"
							style={{ padding: "20px 40px", textAlign: "center" }}>
							{croppedImageProfilePhoto === undefined ? (
								<img
									src="./images/krishigaplogo.png"
									id="createComprofileimg"
									alt=""
									class="uc-profile-images clsProfileImg"
									style={{
										height: "112px",
										width: "112px",
										borderRadius: "50%",
										border: "1px solid #ddd",
									}}
								/>
							) : (
								<img
									src={URL.createObjectURL(croppedImageProfilePhoto)}
									id="createComprofileimg"
									alt=""
									class="uc-profile-images clsProfileImg"
									style={{
										height: "112px",
										width: "112px",
										borderRadius: "50%",
										border: "1px solid #ddd",
									}}
								/>
							)}

							<label class="uc-cam-icon" onClick="openCreateComProf_UpPop()">
								<img
									id="cameraicon"
									src="./images/camera.svg"
									alt=""
									style={{
										backgroundColor: "#E4E3E0",
										marginLeft: "-30px",
										padding: "6px 6px",
										borderRadius: "40%",
										marginTop: "75px",
									}}
									onClick={handleOpenprofilePhotoModal}
								/>
							</label>
						</div>
						<div style={{ padding: "15px 45px 10px" }}>
							<label for="name" style={{ paddingBottom: "5px" }}>
								Name*
							</label>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Name"></input>
							<label
								for="name"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Profile Headline*
							</label>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Profile Headline"></input>
							<label
								for="name"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Location*
							</label>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Location"></input>
							<label
								for="name"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Email*
							</label>
							<input
								type="email"
								className="form-control"
								placeholder="Enter Email"></input>
							<label
								for="name"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Mobile Number*
							</label>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Mobile Number"></input>
							<label
								for="name"
								style={{ paddingTop: "10px", paddingBottom: "5px" }}>
								Select Role*
							</label>
							<select
								class="form-control select_role select-hidden"
								id="select_role"
								name="formFieldName[select_role]"
								required=""
								profession-selected="0">
								<option value="">Select</option>
								<option value="4">Member</option>
								<option value="17">Alumni/Guest</option>
							</select>
						</div>
						<div
							className="row"
							style={{
								padding: "15px 15px 15px 15px",
								display: "flex",
								alignItems: "center",
								background: "#F1F1F1",
								//   marginLeft: "0px",
								//   marginRight:"0px"
							}}>
							<div className="col-9"></div>
							<div className="col-3">
								<span
									className="col-1"
									style={{
										//   border: "1px solid #ddd",
										borderRadius: "5px",
										padding: "4px 15px 6px 20px",
										//   width: "auto",
										paddingLeft: "20px",
										color: "#fff",
										background: "#E23F1C",
									}}>
									+ Join
								</span>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal
					show={showShareModal}
					onHide={handleCloseShareModal}
					dialogClassName="modal-sm-30px"
					size="md"
					backdropClassName="childmodal"
					// backdrop="static"
					centered
					// onEntered={() => props.handleEntered}
				>
					<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
						<div className="row">
							<div className="col-11">
								<h2
									style={{
										fontSize: "18px",
										fontWeight: "500",
										padding: "10px",
									}}>
									Share Community URL
								</h2>
							</div>
							<div
								className="col-1"
								style={{ padding: "10px", cursor: "pointer" }}
								onClick={handleCloseShareModal}>
								<img src="./images/closeOne.png"></img>
							</div>
						</div>
						<hr style={{ marginTop: "0px" }} />
						<div class="uc-modal-body" style={{ padding: "20px 30px" }}>
							<div class="uc-detail-btn-wrap uc-share-btn-wrap">
								<div className="row">
									<div
										className="col-9"
										style={{ paddingRight: "0px", color: "red" }}>
										<input
											type="text"
											class="uc-formControl input"
											id="copytext"
											defaultValue="ulektz.com/c/krishigap"
											disabled
											// readonly=""
										/>
									</div>
									<div className="col-3" style={{ paddingLeft: "0px" }}>
										<a
											href="javascript:void(0)"
											onClick="copyCommunityUrl()"
											class="copybutton">
											<img src="./images/Copyshare_link.svg" alt="copy" />
											<span style={{ color: "#fff", paddingLeft: "4px" }}>
												Copy
											</span>
											{/* <div
                  class="popover__content cpy-tooltip-head"
                  style="display:none"
                >
                  <p>Link Copied</p>
                </div> */}
										</a>
									</div>
								</div>
							</div>
							<div
								class="uc-modal-footer share-footer"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									padding: "20px 12px 12px 12px",
								}}>
								<a
									href="mailto:?Subject=Community Url&amp;Body=https://ulektz.com/c/krishigap"
									class="email-share"
									target="_blank"
									style={{
										margin: "0px 10px",
										fontSize: "13px",
										minWidth: "65px",
										textAlign: "center",
										fontWeight: "500",
										color: "#000",
									}}
									rel="noreferrer">
									<img
										src="./images/share-email.svg"
										alt="email"
										className="image"
									/>
									<br />
									Email
								</a>
								<a
									href="https://www.linkedin.com/sharing/share-offsite/?url=https://ulektz.com/c/krishigap"
									class="linkedin-share"
									target="_blank"
									style={{
										margin: "0px 10px",
										fontSize: "13px",
										minWidth: "65px",
										textAlign: "center",
										fontWeight: "500",
										color: "#000",
									}}
									rel="noreferrer">
									<img
										src="./images/share-linkedin.svg"
										alt="linkedin"
										className="image"
									/>
									Linkedin
								</a>
								<a
									href="https://www.facebook.com/sharer.php?u=https://ulektz.com/c/krishigap"
									class="facebook-share"
									target="_blank"
									style={{
										margin: "0px 10px",
										fontSize: "13px",
										minWidth: "65px",
										textAlign: "center",
										fontWeight: "500",
										color: "#000",
									}}
									rel="noreferrer">
									<img
										src="./images/share-facebook.svg"
										alt="facebook"
										className="image"
									/>
									Facebook
								</a>
								<a
									href="https://twitter.com/share?text=Community Url&amp;url=https://ulektz.com/c/krishigap"
									class="twitter-share"
									target="_blank"
									style={{
										margin: "0px 10px",
										fontSize: "13px",
										minWidth: "65px",
										textAlign: "center",
										fontWeight: "500",
										color: "#000",
									}}
									rel="noreferrer">
									<img
										src="./images/share-twitter.svg"
										alt="twitter"
										className="image"
									/>
									Twitter
								</a>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal
					show={showProfilePhotoModal}
					onHide={handleCloseProfilePhotoModal}
					dialogClassName="modal-sm-30px"
					size="md"
					backdropClassName="childmodal"
					// backdrop="static"
					centered
					// onEntered={() => props.handleEntered}
				>
					<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
						<div className="row">
							<div className="col-11">
								<h2
									style={{
										fontSize: "18px",
										fontWeight: "500",
										padding: "15px",
									}}>
									Profile Photo
								</h2>
							</div>
							<div className="col-1" style={{ padding: "15px" }}>
								<img src="./images/closeOne.png"></img>
							</div>
						</div>
						<hr />
						{imageToCropProfilePhoto ? (
							<ImageCropper
								imageToCrop={imageToCropProfilePhoto}
								onImageCropped={(croppedImage) => {
									console.log(croppedImage);
									setCroppedImageProfilePhoto(croppedImage);
								}}
								coverphoto="coverphoto"
							/>
						) : (
							<div
								style={{
									backgroundColor: "#efefef",
									height: "50vh",
									width: "34vw",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}>
								<img
									src="./images/profile.svg"
									style={{ height: "90px" }}></img>
							</div>
						)}

						<div
							style={{
								fontSize: "13px",
								fontWeight: "300",
								textAlign: "center",
								paddingTop: "10px",
							}}>
							Recommended size - 1920 x 1080px, Max. 2 MB
						</div>
						<div
							className="row"
							style={{ backgroundColor: "#efefef", padding: "10px 10px" }}>
							<div className="col-8">
								<input
									type="file"
									id="file"
									ref={inputFileProfilePhoto}
									style={{ display: "none" }}
									onChange={onUploadFileProfilePhoto}
								/>
								<button
									onClick={onButtonClickProfilePhoto}
									style={{
										background: "#fff",
										border: "none",
										padding: "5px",
										borderRadius: "5px",
									}}>
									<img src="./images/camera.svg" alt="" />{" "}
									<span style={{ fontSize: "12px" }}>Select Photo</span>
								</button>
								{/* <button
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button> */}
							</div>
							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#fff",
											border: "none",
											height: "35px",
											color: "#000",
											border: "1px solid #000",
											borderRadius: "15px",
										}}
										onClick={handleCloseProfilePhotoModal}>
										Cancel
									</Button>
								</div>
							</div>
							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}>
										Upload
									</Button>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal
					show={showNewPostModal}
					onHide={handleOpenNewPostModal}
					dialogClassName="modal-sm-30px"
					size="md"
					backdropClassName="childmodal"
					// backdrop="static"
					centered
					// onEntered={() => props.handleEntered}
				>
					<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
						<div className="row">
							<div className="col-11">
								<h2
									style={{
										fontSize: "18px",
										fontWeight: "500",
										padding: "10px",
									}}>
									New Post
								</h2>
							</div>
							<div
								className="col-1"
								style={{ padding: "10px", cursor: "pointer" }}
								onClick={handleCloseNewPostModal}>
								<img src="./images/closeOne.png"></img>
							</div>
						</div>
						<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
						<div className="row" style={{ padding: "10px 0px 0px 0px" }}>
							<div
								className="col-2"
								style={{
									width: "auto",
									display: "flex",
									alignItems: "center",
								}}>
								<img src="./images/krishigaplogo.png" width="40px"></img>
							</div>
							<div className="col-4">
								<div>Krishigap</div>
								<div class="uc-com-icon" onClick={handleOpenAudienceModal}>
									<div id="communityicon"></div>
									<a
										href="javascript:void(0)"
										class="uc-prof-in uc-post-audience-modal-toggle"
										className="allmember">
										<img
											id="audience-icon"
											src="./images/Public_icon.svg"
											alt=""
										/>
										<text id="audience-text">All Member</text>
										<span class="uc-right-arrow"></span>
									</a>
								</div>
							</div>
							<div className="row" style={{ padding: "20px 0px 10px 30px" }}>
								<textarea
									type="text"
									placeholder="Share something intresting / informative"
									style={{ border: "none" }}
									rows="10"></textarea>
							</div>
							<div
								className="row"
								style={{
									padding: "15px 15px 15px 15px",
									display: "flex",
									alignItems: "center",
									background: "#F1F1F1",
									marginLeft: "0px",
								}}>
								<div className="col-10">
									<a
										href="javascript:void(0);"
										class="uc-share-opts"
										onClick="addmedia()"
										style={{
											border: "1px solid #ddd",
											padding: "6px 15px",
											borderRadius: "15px",
											background: "#fff",
										}}>
										<img
											src="./images/media_f_icon.svg"
											alt="Media"
											style={{ marginTop: "-3px" }}
											//   class="uc-color"
										/>
										<span style={{ color: "#000", paddingLeft: "5px" }}>
											Add Media
										</span>
									</a>
								</div>

								<div
									className="col-1"
									style={{
										border: "1px solid #ddd",
										borderRadius: "15px",
										padding: "2px 15px",
										width: "auto",
										paddingLeft: "20px",
										color: "#fff",
										background: "#36BB7D",
									}}>
									Post
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal
					show={showAudienceModal}
					onHide={handleOpenAudienceModal}
					dialogClassName="modal-sm-30px"
					size="md"
					backdropClassName="childmodal"
					// backdrop="static"
					centered
					// onEntered={() => props.handleEntered}
				>
					<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
						<div className="row">
							<div className="col-11" style={{ paddingLeft: "0px" }}>
								<h2
									style={{
										fontSize: "18px",
										fontWeight: "500",
										padding: "10px",
										marginBottom: "0px",
									}}>
									Select Audience
								</h2>
							</div>
							{/* <div
              className="col-1"
              style={{ padding: "10px", cursor: "pointer" }}
              onClick={handleCloseNewPostModal}
            >
              <img src="./images/closeOne.png"></img>
            </div> */}
						</div>
						<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
						<div style={{ paddingTop: "10px" }}>
							<div style={{ fontSize: "16px", fontWeight: "400" }}>
								Who can see your post?
							</div>
							<div style={{ fontSize: "12px", marginBottom: "25px" }}>
								Your post will be visible on feed, on your profile and see in
								search results
							</div>
							<div
								className="row"
								style={{
									padding: "10px 12px",
									background: "#f1f1f1",
									borderRadius: "5px",
									marginBottom: "15px",
									border: "1px solid #ddd",
									marginLeft: "0px",
									marginRight: "0px",
								}}>
								<div className="col-11">
									<span>
										<img src="./images/Public_icon.svg" alt="" /> All Member
									</span>
								</div>
								<div className="col-1">
									<label>
										<input
											type="radio"
											name="audience"
											id="Public"
											value="Community Public"
											checked=""
										/>
									</label>
								</div>
							</div>
							<div
								className="row"
								style={{
									padding: "10px 12px",
									borderRadius: "5px",
									marginBottom: "15px",
									border: "1px solid #ddd",
									marginLeft: "0px",
									marginRight: "0px",
								}}>
								<div className="col-11">
									<span>
										<img src="./images/Announcement.svg" alt="" /> Announcement
									</span>
								</div>
								<div className="col-1">
									<label>
										<input
											type="radio"
											name="audience"
											id="announcement"
											value="Announcement"
										/>
									</label>
								</div>
							</div>
						</div>

						<div
							className="row"
							style={{
								padding: "15px 15px 15px 15px",
								display: "flex",
								alignItems: "center",
								background: "#F1F1F1",
								//   marginLeft: "0px",
								//   marginRight:"0px"
							}}>
							<div className="col-8"></div>
							<div className="col-4">
								<a
									href="javascript:void(0)"
									class="uc-share-opts"
									style={{
										border: "1px solid #ddd",
										padding: "6px 15px",
										borderRadius: "15px",
										background: "#fff",
										marginRight: "10px",
									}}
									onClick={(e) => {
										setShowAudienceModal(false);
										setShowNewPostModal(true);
									}}>
									<span style={{ color: "#000", paddingLeft: "5px" }}>
										Back
									</span>
								</a>
								<span
									className="col-1"
									style={{
										//   border: "1px solid #ddd",
										borderRadius: "15px",
										padding: "4px 15px 6px 20px",
										//   width: "auto",
										paddingLeft: "20px",
										color: "#fff",
										background: "#36BB7D",
									}}>
									Save
								</span>
							</div>
						</div>
					</Modal.Body>
				</Modal>

				<div className="row" style={{ marginTop: "20px" }}>
					<div className="col-1"></div>
					<div className="col-2">
						{" "}
						<div
							className="card"
							style={{
								paddingLeft: "0px",
								paddingRight: "0px",
								width: "280px",
								border: "none",
								marginRight: "15px",
							}}>
							<div>
								<img
									src="./images/common-background.png"
									style={{ width: "100%" }}></img>
							</div>
							<div style={{ textAlign: "center", marginTop: "-40px" }}>
								<img
									src="./images/krishigaplogo.png"
									style={{ width: "25%" }}></img>
							</div>
							<div style={{ textAlign: "center" }}>
								<div>{auth.user.fullname}</div>
								<div>{auth.user.email}</div>
								<div>Hyderabad</div>
							</div>
							<div style={{ padding: "15px" }}>
								<Button
									style={{
										textAlign: "center",
										width: "-webkit-fill-available",
										background: "#20ce8f",
										border: "none",
									}}
									onClick={buttonClick}>
									<img src="./images/refer_icon.svg"></img>&nbsp;&nbsp;Invite
									and earn
								</Button>
							</div>
							<div style={{ padding: "25px" }}>
								<div
									className="row"
									style={{ display: "flex", justifyContent: "space-between" }}>
									<div
										className="col-4 card"
										style={{
											border: "1px solid #20ce8f",
											background: "#DCFFF3",
											height: "90px",
											width: "80px",
											textAlign: "center",
										}}>
										<div>
											<img src="./images/Profile-Views.svg"></img>
										</div>
										<div style={{ fontSize: "13px" }}>views</div>
										<div>0</div>
									</div>
									<div
										className="col-4 card"
										style={{
											border: "1px solid #0F68B2",
											background: "#F0F8FF",
											height: "90px",
											width: "80px",
											textAlign: "center",
										}}>
										<img src="./images/Profile-Likes.svg"></img>
										<div style={{ fontSize: "13px" }}>Likes</div>
										<div>0</div>
									</div>
									<div
										className="col-4 card"
										style={{
											border: "1px solid #E98E05",
											background: "#FFF5E6",
											height: "90px",
											width: "80px",
											textAlign: "center",
											paddingLeft: "5px",
										}}>
										<img src="./images/Profile-Connections.svg"></img>
										<div style={{ fontSize: "11px", fontWeight: "400" }}>
											Connections
										</div>
										<div>0</div>
									</div>
								</div>
							</div>
							<hr />
							<div style={{ padding: "0px 25px 25px 15px" }}>
								<div>Connections</div>
								<div
									style={{
										paddingTop: "10px",
										paddingLeft: "20px",
										display: "flex",
									}}>
									<img
										src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/7c86d5127c53dae5.jpeg"
										style={{ borderRadius: "50%", width: "20%" }}></img>
									<img
										src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/7c86d5127c53dae5.jpeg"
										style={{
											borderRadius: "50%",
											width: "20%",
											marginLeft: "-10px",
										}}></img>
									<img
										src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/7c86d5127c53dae5.jpeg"
										style={{
											borderRadius: "50%",
											width: "20%",
											marginLeft: "-10px",
										}}></img>
									<img
										src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/7c86d5127c53dae5.jpeg"
										style={{
											borderRadius: "50%",
											width: "20%",
											marginLeft: "-10px",
										}}></img>
									<img
										src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/7c86d5127c53dae5.jpeg"
										style={{
											borderRadius: "50%",
											width: "20%",
											marginLeft: "-10px",
										}}></img>
									<span
										style={{
											background: "#269BA3",
											color: "#fff",
											padding: "10px",
											borderRadius: "50%",
											marginLeft: "-10px",
										}}>
										+9
									</span>
								</div>
							</div>
						</div>
					</div>

					<div
						className="col-5"
						style={{ marginRight: "10px", marginLeft: "80px" }}>
						<Addpost />
						<br />
						<div className="home row mx-0" style={{ padding: "0 0 0 56px" }}>
							<div className="col-md-8">
								{/* <Status />
								{homePosts.loading ? (
									<img
										src={LoadIcon}
										alt="loading"
										className="d-block mx-auto"
									/>
								) : homePosts.result === 0 ? (
									<h2 className="text-center">No Post</h2>
								) : ( */}
								{/* <Posts /> */}
								{/* )} */}
							</div>
						</div>
						{homePosts.posts.map((post) => (
							<div
								key={post.id}
								className="card row mb-3"
								style={{ borderRadius: "15px", border: "none" }}>
								<div
									className="row"
									style={{
										padding: "15px",
									}}>
									<div className="col-12">
										<div className="row">
											<div
												className="col-2"
												style={{
													width: "auto",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													paddingRight: "0px",
												}}>
												{/* <img
													alt="avatar"
													src={post.user.avatar}

													width="40px"
													height="40px"></img> */}
												<Avatar src={post.users.avatar} size="big-avatar" />
											</div>
											<div className="col-8">
												<div style={{ fontSize: "15px", fontWeight: "500" }}>
													{/* Dr. Sadiq Sait M.S */}
													{post.users.fullname}
												</div>
												<div style={{ fontSize: "12px", fontWeight: "500" }}>
													{/* 13 Feb 2024 at 10:32 am */}
													{moment(post.createdAt).fromNow()}
												</div>
											</div>
											<div
												className="col-2"
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "right",
												}}>
												<OverlayTrigger
													trigger="click"
													key="bottom"
													placement="bottom"
													rootClose
													overlay={
														<Popover id={`popover-positioned-bottom`}>
															<Popover.Body>
																<div>
																	<span>
																		<img
																			alt="hidepost"
																			src="../images/Hide_post.svg"></img>
																	</span>
																	&nbsp; Hide Post
																</div>
																<div>
																	<span>
																		<img
																			alt="reportpost"
																			src="../images/Report_post.svg"></img>
																	</span>
																	&nbsp; Report Post
																</div>
															</Popover.Body>
														</Popover>
													}>
													<img
														src="../images/options.svg"
														alt=""
														style={{ cursor: "pointer" }}
													/>
												</OverlayTrigger>
											</div>
										</div>
									</div>
								</div>
								<div
									className="row"
									style={{
										padding: "0px 15px 5px 30px",
										fontSize: "14px",
										fontWeight: "400",
									}}>
									{/* Horticulture of Dasheri Mango */}
									{post.content}
								</div>
								<div className="row">
									{/* <img
										src="https://ulektzmumbai.sgp1.cdn.digitaloceanspaces.com/ulektzConnect/feeds/170961/images/54c3c4fe3401dbb2.jpeg"
										alt=""
									/> */}
									<Carousel images={post.images} id={post.id} />
								</div>
								<div
									className="row"
									style={{
										display: "flex",
										justifyContent: "space-between",
										padding: "10px 10px",
										//   borderBottom: "1px solid #ddd",
									}}>
									<div className="col-9" style={{ fontSize: "13px" }}>
										<img
											src="./images/Like-fill-icon.svg"
											alt=""
											width="20px"
											height="20px"></img>
										<span style={{ paddingLeft: "10px", fontSize: "13px" }}>
											{post.likes.length}
										</span>
									</div>
									<div
										className="col-3"
										style={{
											display: "flex",
											alignItems: "end",
											justifyContent: "end",
											fontSize: "13px",
											fontWeight: "400",
										}}>
										{post.comments.length} comments
									</div>
								</div>
								<hr style={{ marginBottom: "0px" }} />

								<CardFooter post={post} />

								{/* <div className="row" style={{ padding: "15px 15px" }}>
									<div
										className="col-6"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
										}}>
										<img alt="like" src="./images/Liked.svg" width="26px"></img>
										&nbsp;Like
									</div>
									<div
										className="col-6"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
										}}>
										<img src="./images/Comments.svg" width="26px"></img>&nbsp;
										Comments
									</div>
								</div> */}
							</div>
						))}
					</div>

					<div className="col-3">
						<div
							className="card"
							style={{
								border: "none",
								borderRadius: "15px",
								padding: "20px",
							}}>
							<div className="row">
								<div
									className="col-3"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<img
										src="./images/krishigaplogo.png"
										style={{ borderRadius: "50%", width: "100%" }}></img>
								</div>
								<div className="col-9">
									<div>Derlin Rebekah Rani S</div>
									<div>Fresher</div>
									<div>
										<Button
											style={{
												background: "#fff",
												border: "1px solid #ddd",
												color: "#000",
											}}>
											Ignore
										</Button>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<Button
											style={{
												background: "#269BA3",
												border: "none",
											}}>
											Accept
										</Button>
									</div>
								</div>
							</div>
						</div>
						<br />
						<div
							className="card"
							style={{
								// maxHeight: "450px",
								border: "none",
								borderRadius: "15px",
							}}>
							<div
								style={{
									backgroundColor: "#20ce8f",
									padding: "15px 15px",

									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
								}}>
								<img src="./images/People_connect-img.svg"></img>
								<div style={{ textAlign: "center", color: "#fff" }}>
									People you may like to connect
								</div>
							</div>
							<div>
								{" "}
								{mostActiveCommunitiesArray.map((e) => {
									return (
										<>
											<div className="row" style={{ padding: "5px 5px" }}>
												<div
													className="col-3"
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}>
													{" "}
													<span onClick="communityChangeNew(132)">
														<img
															src="./images/7c86d5127c53dae5.jpeg"
															alt=""
															width="45px"
															heght="45px"
															onerror="this.onerror=null;this.src='./images/community-def-logo.png'"
															style={{ borderRadius: "50%" }}
														/>
													</span>
												</div>
												<div className="col-9" style={{ paddingLeft: "0px" }}>
													<span
														class="uc-community-cnt"
														onClick="communityChangeNew(132)">
														<div className="row">
															<div className="col-9">
																<p class="uc-communityLike-in">
																	<h5>Dr. Sadiq Sait M.S</h5>
																	<span>
																		Founder and CEO - uLektz.com [AI-powered
																		Ecosystem of Higher Education]
																		{/* <br />
                                        41866 members */}
																	</span>
																</p>
															</div>
															<div
																className="col-3"
																style={{ paddingLeft: "0px" }}>
																<img
																	src="./images/Add-connection.svg"
																	style={{
																		paddingLeft: "0px",
																		background: "#EFEFEF",
																		padding: "6px",
																		borderRadius: "2px",
																	}}></img>
															</div>
														</div>
													</span>
												</div>
												<a
													data-id="132"
													href="javascript:void(0);"
													class="uc-com-join-prof-toggle"></a>
											</div>
											<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
										</>
									);
								})}
								<div
									className="lightgreen"
									style={{
										textAlign: "center",
										textDecoration: "underline",
									}}>
									See All
								</div>
							</div>
						</div>
						<br />
						<div
							className="card"
							style={{
								border: "none",
								borderRadius: "15px",
							}}>
							<div
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
								}}>
								Communities you may like to join
							</div>
							<div
								style={{
									height: "600px",
								}}>
								{" "}
								{mostActiveCommunitiesArray.map((e) => {
									return (
										<>
											<div className="row" style={{ padding: "5px 5px" }}>
												<div
													className="col-3"
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}>
													{" "}
													<span onClick="communityChangeNew(132)">
														<img
															src="./images/642847167.png"
															alt=""
															width="45px"
															heght="45px"
															onerror="this.onerror=null;this.src='./images/community-def-logo.png'"
														/>
													</span>
												</div>
												<div className="col-6" style={{ paddingLeft: "0px" }}>
													<span
														class="uc-community-cnt"
														onClick="communityChangeNew(132)">
														<h5>Nuclear Physics</h5>
														<div className="row">
															<div className="col-12">
																<p>
																	<span>
																		Public Group
																		<br />
																		41866 members
																	</span>
																</p>
															</div>
														</div>
													</span>
												</div>
												<div
													className="col-3"
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}>
													<Button
														onClick={handleOpenjoinModal}
														style={{
															background: "#E23F1C",
															border: "none",
															color: "#fff",
															padding: "3px",
														}}>
														+ Join
													</Button>
												</div>
												<a
													data-id="132"
													href="javascript:void(0);"
													class="uc-com-join-prof-toggle"></a>
											</div>
											<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
										</>
									);
								})}
							</div>
						</div>
						<br />
						<div
							class="uc-cmn-box uc-create-com-dashboard card"
							style={{ border: "none", padding: "20px" }}>
							<div
								class="uc-comm-cre-ele-img-div"
								style={{ textAlign: "center" }}>
								<img src="./images/create_community-dashborad.svg" />
							</div>
							<br />
							<div class="uc-comm-cre-ele-cnt-div">
								<p style={{ textAlign: "center" }}>Create Your Community</p>
								<br />
								<div style={{ textAlign: "center" }}>
									<Button
										style={{ background: "#20ce8f", border: "none" }}
										onClick={buttonClick1}>
										Create Community
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div
            style={{ display: "flex", justifyContent: "end" }}
            onClick={handleOpenNewPostModal}
          >
            <img
              src="https://www.ulektz.com//assets/img/community/new-post-float-icon.svg"
              alt=""
              class="newpost"
            />

          </div> */}

				{/* <div className="bottom">
          <div className="row" style={{ padding: "10px 40px" }}>
            <div className="col-6">
              <img src="./images/Play-Store.svg"></img>&nbsp;&nbsp;&nbsp;
              <img src="./images/App-Store.svg"></img>
            </div>
            <div
              className="col-6"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "12px",
                color: "#9e9e9e",
                alignItems: "center",
              }}
            >
              © uLektz Learning Solution Private Limited - All Rights Reserved
            </div>
          </div>
        </div> */}
			</>
		</>
	);
};

export default Dashboard;
