import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Mr_devendera_prasad = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						// class="img-fluid"
						src="../images/devender-prasad.jpg"
						alt=""
					/>

					<h4 className="">Mr. Devendra Prasad</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Senior Consultant-APEDA, New Delhi.
							</li>

							<br />
						</ul>
						<b>Past</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								DGM in Agricultural and Processed Food Products Export
								Development Authority (APEDA), Ministry of Commerce and
								Industry, Government of India involved in export promotion, food
								safety, quality and development of laboratory network for export
								certification of food products from India.
							</li>{" "}
						</ul>
						<br />
						<b> SIGNIFICANT ACHIEVEMENTS:</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Development and implementation of export procedures ensuring
								food safety, quality and traceability requirements of importing
								countries.
							</li>
							<li>
								Upgradation of food testing laboratories, food safety management
								system and inspection agencies.
							</li>
							<li>
								Carried out investigation for establishing corrective action for
								export rejections and rapid alerts of EU, USA, GCC countries,
								CIS, Indonesia, Japan and other importing countries.
							</li>{" "}
							<li>
								Handled sanitary and phytosanitary and technical barrier to
								trade matters issued by WTO member countries to protect India’s
								interest to ensure smooth trade of food products.
							</li>
							<li>
								Chaired Electronic Working Group on setting up of Maximum Levels
								of Aflatoxins in Ready-to-Eat Peanuts in Codex Committee on
								Contaminant; Okra, Brinjal, Ware Potato, Onion and Shallots
								standards in Codex Committee on Fresh Fruits and Vegetables as
								well as represented India as Regional Coordinator for Asian
								countries in Codex Committee on Fresh Fruits and Vegetables for
								harmonisation of India's standards with the global standards.
							</li>
						</ul>

						<br />
						<b>Education</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Post-Graduation in Public Administration.
							</li>{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Mr_devendera_prasad;
