import React from "react";
import Button from "react-bootstrap/Button";

const Communityinviations = () => {
	const mostActiveCommunitiesArray = [1, 2, 3, 4, 5, 6, 7, 8];
	return (
		<>
			{/* <Header /> */}
			<NewNavbar />
			<div style={{ padding: "20px 60px 20px 120px", marginTop: "28px" }}>
				<div className="row" style={{ marginLeft: "5px", display: "contents" }}>
					<span
						style={{ cursor: "pointer" }}
						onClick={() => (window.location.href = "./foodsafetyacademy")}>
						Dashboard
					</span>{" "}
					&nbsp;&nbsp;&nbsp;/&nbsp;
					<span style={{ fontWeight: "500" }}>Invites</span>
				</div>
				<div className="row pt-4">
					<div
						className="col-3 card "
						style={{
							// maxHeight: "calc(100vh - 235px)",
							border: "none",
							borderRadius: "15px",
						}}>
						<div
							style={{
								backgroundColor: "#F4F7F9",
								padding: "15px 15px",
								marginLeft: "-12px",
								marginRight: "-12px",
								fontWeight: "600",
								fontSize: "16px",
								borderTopLeftRadius: "15px",
								borderTopRightRadius: "15px",
							}}>
							Invite my connections
						</div>
						<div className="pt-2">
							<input
								type="text"
								placeholder="Serach connections"
								className="form-control"></input>
						</div>
						<div
							style={{
								overflowY: "auto",
								maxHeight: "calc(100vh - 235px)",
								overflowX: "hidden",
							}}>
							{" "}
							{mostActiveCommunitiesArray.map((e) => {
								return (
									<>
										<div className="row" style={{ padding: "10px 15px" }}>
											<div
												className="col-3"
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}>
												{" "}
												<span onClick="communityChangeNew(132)">
													<img
														src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/642847167.png"
														alt=""
														width="45px"
														heght="45px"
														// onError="this.onerror=null;this.src='https://www.ulektz.com/assets/img/community/community-def-logo.png'"
													/>
												</span>
											</div>
											<div className="col-9" style={{ paddingLeft: "0px" }}>
												<span
													className="uc-community-cnt"
													onClick="communityChangeNew(132)"
													style={{ display: "contents" }}>
													<div className="row">
														<div className="col-7">
															<p className="uc-communityLike-in">
																<h5>Yogesh G</h5>
																<span>Krishigap</span>
															</p>
														</div>
														<div
															className="col-5"
															style={{ paddingLeft: "0px" }}>
															<Button
																className="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
																// onClick="joinForm('MTMy',this)"
																style={{
																	background: "#38AB71",
																	border: "none",
																}}>
																Invite
															</Button>
														</div>
													</div>
												</span>
											</div>
											<a
												data-id="132"
												href="javascript:void(0);"
												className="uc-com-join-prof-toggle"></a>
										</div>
										<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
									</>
								);
							})}
						</div>
					</div>
					<div className="col-8 card offset-1">
						<div style={{ padding: "60px 20px" }}>
							<div
								className="row"
								style={{ display: "flex", justifyContent: "center" }}>
								<img
									src="./images/krishigaplogo.png"
									style={{
										width: "80px",
										height: "80px",
										boxShadow: "0 0px 15px 0 rgb(0 0 0 / 20%)",
										borderRadius: "50%",
									}}></img>
							</div>
							<div
								style={{
									fontSize: "22px",
									fontWeight: "400",
									marginTop: "20px",
									textAlign: "center",
								}}>
								Global Food Safety and Sustainability Standards Platform for
								Networking, Learning and Development
							</div>
							<div
								className="card"
								style={{
									padding: "30px 10px",
									margin: "20px 100px 0px 100px",
									background: "#fbfcfd",
								}}>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										fontSize: "18px",
										fontWeight: "400",
										marginBottom: "15px",
									}}>
									Invite your contacts
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										marginBottom: "20px",
									}}>
									<textarea
										placeholder="Enter email address here,separated by comma"
										className="form-control"
										style={{ width: "400px", border: "1px solid green" }}
										rows="1"></textarea>
								</div>

								<div style={{ display: "flex", justifyContent: "center" }}>
									<Button
										className="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
										// onClick="joinForm('MTMy',this)"
										style={{
											background: "#38AB71",
											border: "none",
											width: "fix-content",
											padding: "7px 15px",
											borderRadius: "4px",
											minWidth: "100px",
											marginBottom: "20px",
										}}>
										Invite
									</Button>
								</div>

								<div
									className="uc-modal-footer share-footer"
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										padding: "20px 12px 12px 12px",
									}}>
									<div className="uc-inv-shr-icons" style={{ display: "flex" }}>
										<a
											href="https://wa.me/?text= Join Global Food Safety and Sustainability Standards Platform for Networking, Learning and Development on uLektz to connect with members of our community, build new relationships, and get access to members-only resources for education, skills, and careers.Use this invite from me and earn up to ₹1000 for signing up.https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
											className="popover__wrapper icons"
											id="whatsappComRef"
											target="_blank"
											// className="icons"
											rel="noreferrer">
											<img
												src="https://www.ulektz.com/assets/img/home/WhatsApp.svg"
												alt=""
											/>
											{/* <div className="popover__content">
                        <p>WhatsApp</p>
                      </div> */}
										</a>

										<a
											href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
											className="popover__wrapper icons"
											id="linkedinComRef"
											target="_blank"
											// className="icons"
											rel="noreferrer">
											<img src="./images/linkedin.svg" alt="" />
											{/* <div className="popover__content">
                        <p>LinkedIn</p>
                      </div> */}
										</a>

										<a
											href="https://www.facebook.com/dialog/share?app_id=604152004970299&amp;display=popup&amp;href=https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
											className="popover__wrapper icons"
											id="facebookComRef"
											target="_blank"
											// className="icons"
											rel="noreferrer">
											<img src="./images/fb.svg" alt="" />
											{/* <div className="popover__content">
                        <p>Facebook</p>
                      </div> */}
										</a>

										<a
											href="https://twitter.com/intent/tweet?url=https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4&amp;text= Join Global Food Safety and Sustainability Standards Platform for Networking, Learning and Development on uLektz to connect with members of our community, build new relationships, and get access to members-only resources for education, skills, and careers.Use this invite from me and earn up to ₹1000 for signing up."
											className="popover__wrapper icons"
											id="twitterComRef"
											target="_blank"
											// className="icons"
											rel="noreferrer">
											<img src="./images/twitter.svg" alt="" />
											{/* <div className="popover__content">
                        <p>Twitter</p>
                      </div> */}
										</a>
										<input
											type="hidden"
											value="Join Global Food Safety and Sustainability Standards Platform for Networking, Learning and Development on uLektz to connect with members of our community, build new relationships, and get access to members-only resources for education, skills, and careers.Use this invite from me and earn up to ₹1000 for signing up.https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
											id="copyComRefUrl"
										/>
										<a
											href="javascript: void(0);"
											className="popover__wrapper icons"
											onClick="copyComUrl();"
											// className="icons"
										>
											<img src="./images/Copy.svg" alt="" />
											{/* <div className="popover__content">
                        <p>Copy message</p>
                      </div> */}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Communityinviations;
