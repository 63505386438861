import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";
const Shashi_sareen = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/venkat-pindipolu.jpg"
						alt=""
					/>

					<h4 className="">Venkat Pindipolu</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<p className="fs">
							Presently working as a co-founder for CarbonMint. CarbonMint is a
							web3 system that rewards the natural farming ecosystem, and helps
							people transition to it with incentives, better market access and
							evidence-based certification of natural farming operations
						</p>

						<b>Past</b>
						<br />

						<p className="fs">
							SAP Technical Architect/Consultant (IBM, CSC, Lemongrass, Agility
							Works, SAP Hybris), UK
						</p>
						<br />
						<b>TECHNOLOGY BACKGROUND:</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Over 20 years of experience in technical project management,
								architecture, infrastructure, managing & leading SAP BASIS team,
								client service delivery & engagement in a multi-client hosting
								environment, SAP Basis/Netweaver administration with migration &
								upgrades and software design.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Domain and business knowledge of the following sectors – Public
								Sector, Utilities & Energy, Logistics, Manufacturing,
								Automotive, Hosting, Transport and Telecommunications.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Several full life-cycle SAP implementations and OS/DB
								migrations.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Database Administration - DB2, HANA, Oracle, & SQL Server.
							</li>{" "}
						</ul>
						<br />
						<b>INTERNATIONAL EXPOSURE:</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Worked in UK for almost 16 years at different levels in
								technology domain.
							</li>{" "}
						</ul>
						<br />

						<b>Educaation</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								M.Tech, Production Engineering (Indian Institute of Technology,
								Delhi, India).
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								B.Tech, Mechanical Engineering (Kakatiya University, Warangal,
								India).
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Diploma, Mechanical Engineering (Government Polytechnic,
								Mahaboob Nagar, India)
							</li>{" "}
						</ul>
						<br />
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Shashi_sareen;
