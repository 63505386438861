import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
const Privacypolicy = () => {
	return (
		<>
			<NewNavbar />
			{/* <div>
				<img
					src="../images/homebanner.jpg"
					alt="krishi-gap-logo"
					style={{ height: "500px", width: "auto" }}
				/>
			</div> */}
			<div
				className="container-xxl py-5"
				style={{
					paddingTop: "0rem !important",
					paddingBottom: "0rem !important",
				}}>
				<div className="container">
					<div className="row g-5">
						<h5>Global Academy on Food Safety and Sustainability Standards</h5>
						<div
							className="col-lg-12 wow fadeInUp"
							data-wow-delay="0.3s"
							style={{
								visibility: "visible",
								animationDelay: "0.1s",
								animationName: "fadeInUp",
							}}>
							<h5 className="mb-4">1. Copyright policy</h5>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								We do not publish or sell standards owned by their respective
								organizations. Participants in our online courses can purchase
								official copies of the standards by visiting the following
								websites: <br />
								<br />
								<div className="mx-3">
									1. International Organization for Standardization (ISO):
									&nbsp;
									<a href="https://www.iso.org" target="blank">
										www.iso.org
									</a>
									<br />
									2. British Retail Consortium (BRC):{" "}
									<a href="https://www.brc.org.uk" target="blank">
										www.brc.org.uk
									</a>
								</div>
							</p>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								If any standards are available for public download, we provide
								copies with appropriate attribution to the original source.
								Examples include: <br />
								<br />
								<div className="mx-3">
									1. GlobalG.A.P: &nbsp;
									<a href="https://www.globalgap.org" target="blank">
										www.globalgap.org
									</a>
									<br />
									2. United States Department of Agriculture:{" "}
									<a href="https://www.usda.gov" target="blank">
										www.usda.gov
									</a>
									<br />
									3. United States Food and Drug Administration:{" "}
									<a href="https://www.fda.gov" target="blank">
										www.fda.gov
									</a>
									<br />
									4. Food Safety and Standards Authority of India:{" "}
									<a href="https://www.fssai.gov.in" target="blank">
										www.fssai.gov.in
									</a>
									<br />
									5. APEDA:{" "}
									<a href="https://www.apeda.gov.in" target="blank">
										www.apeda.gov.in
									</a>
									<br />
									6. Rainforest Alliance:{" "}
									<a href="https://www.rainforest-alliance.org" target="blank">
										www.rainforest-alliance.org
									</a>
								</div>
							</p>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								The content on <b>www.foodsafetyacademy.in</b> cannot be
								reproduced without permission. To seek permission, please
								contact us via email. If permission is granted, proper
								acknowledgment must be provided when reproducing the content.
								Note that permission does not extend to third-party material,
								which requires authorization from the original copyright
								holders. <br />
								<br />
							</p>
							<h5 className="mb-4">2. Hyper linking Policy</h5>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								We are not responsible for the content or reliability of
								external websites linked on our platform and do not necessarily
								endorse their views. The presence of a link should not be
								considered as an endorsement. We do not guarantee the
								functionality of these links or the availability of the linked
								pages.
							</p>
							<h5 className="mb-4">3. Privacy Policy </h5>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								We collect personal information solely to respond to user
								inquiries. If you choose to provide personal details, such as
								through the Contact Us form, we use that information only to
								address your query or for future communication or to understand
								your feedback. . Please avoid sharing sensitive information
								beyond your email address or phone number for communication.
							</p>
							<h5 className="mb-4">4.Linking to Our Website</h5>
							<p className="mb-4" style={{ textAlign: "justify" }}>
								We allow external websites to link to our pages without prior
								permission, but we request notification of such links. However,
								our pages must not be embedded in frames on other sites; they
								should load in a new browser window.
							</p>
							<h5 className="mb-4">5. Information We May Collect</h5>
							{/* <div className="mx-3 mb-4">
								• Name and job title
								<br />
								• Contact information (email, phone number) <br />
								• Demographic details (postal code, preferences, interests)
								<br />• Information relevant to customer surveys or offers
							</div> */}
							<div className="mx-5 mb-4">
								<ul className="unorder-list">
									<li className="list-item">
										<p className="content-text list-text">Name and job title</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											Contact information (email, phone number)
										</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											Demographic details (postal code, preferences, interests)
										</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											Information relevant to customer surveys or offers
										</p>
									</li>
								</ul>
							</div>
							<h5 className="mb-4">6. How We Use the Information</h5>
							<p className="content-text list-text">
								We collect this information to better understand your needs and
								improve our services. Specific uses include:
							</p>
							<div className="mx-5 mb-4">
								{/* • Internal record-keeping <br />• Improving our products and
								services <br />• Sending promotional emails about new products,
								offers, or other relevant information <br />• Market research
								through email, phone, or mail
								<br /> • Customizing the website to suit your preferences */}

								<ul className="unorder-list">
									<li className="list-item">
										<p className="content-text list-text">
											Internal record-keeping
										</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											Improving our products and services
										</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											Sending promotional emails about new products, offers, or
											other relevant information
										</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											Market research through email, phone, or mail
										</p>
									</li>
									<li className="list-item">
										<p className="content-text list-text">
											Customizing the website to suit your preferences
										</p>
									</li>
								</ul>
							</div>
							<p className="content-text list-text">
								We are committed to ensuring your information is secure and have
								implemented measures to prevent unauthorized access.
							</p>
							<h5 className="mb-4">7. How We Use Cookies</h5>
							<p className="content-text list-text">
								A cookie is a small file placed on your device that helps
								analyze web traffic or lets you know when you visit a site.
								Cookies allow web applications to respond to you individually by
								remembering your preferences.
							</p>
							<p className="content-text list-text">
								We use cookies to analyze webpage traffic and improve our
								website. This information is used for statistical analysis only,
								and the data is deleted after use. Cookies help us enhance the
								website experience but do not provide access to your device or
								personal data beyond what you choose to share.
							</p>
							<p className="content-text list-text">
								You can accept or decline cookies. Most browsers accept cookies
								automatically, but you can modify your settings to decline them.
								Note that disabling cookies may limit the website's
								functionality.
							</p>
							<h5 className="mb-4">8. Controlling Your Personal Information</h5>
							<p className="content-text list-text">
								If you believe any information we hold is incorrect or
								incomplete, please contact us, and we will correct any errors
								promptly.
							</p>
							{/* <ul className="unorder-list">
								<li className="list-item">
									<p className="content-text list-text">
										whenever you are asked to fill in a form on the website,
										look for the box that you can click to indicate that you do
										not want the information to be used by anybody for direct
										marketing purposes
									</p>
								</li>
								<li className="list-item">
									<p className="content-text list-text">
										if you have previously agreed to us using your personal
										information for direct marketing purposes, you may change
										your mind at any time by writing to or emailing us at
										harsh@aretecon.com
									</p>
								</li>
							</ul>
							<p className="content-text list-text">
								We will not sell, distribute or lease your personal information
								to third parties unless we have your permission or are required
								by law to do so. We may use your personal information to send
								you promotional information about third parties which we think
								you may find interesting if you tell us that you wish this to
								happen.
							</p>
							<p className="content-text list-text">
								If you believe that any information we are holding on you is
								incorrect or incomplete, please write to or email us as soon as
								possible, at the above address. We will promptly correct any
								information found to be incorrect.
							</p> */}
						</div>
					</div>
				</div>
			</div>
			<div>
				<Footer className="jnav"></Footer>
			</div>
		</>
	);
};

export default Privacypolicy;
