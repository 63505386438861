import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";
const Dr_Gopi_Krishna_Santhanam = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						// class="img-fluid"
						src="../images/gopikrishna.jpg"
						alt="dr_gopi_krishna_santhanam"
					/>

					<h4 className="">Dr Gopi Krishna Santhanam</h4>
					<br />
					<br />
					<h4 className="">
						Information Technology and Smart Farming Specialist
					</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Current Role:</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Co-founder and CEO, Ascentya R&D Solutions Pvt Ltd.
							</li>

							<br />
						</ul>

						<b>Previous Roles:</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Vice President, Microsoft (2016-2019).
							</li>
							<li>
								Head of Technology and Business Services, EAPT Solutions, USA{" "}
							</li>
							<li>Head of Business Solutions, Lloyds N.V., Europe </li>

							<br />
						</ul>

						<b>Education:</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Doctor of Philosophy (PhD) in Management Information Systems.
							</li>
							<li> Master of Business Administration (MBA).</li>
							<li>• Bachelor of Science (BSc) in Computer Science.</li>
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Dr_Gopi_Krishna_Santhanam;
