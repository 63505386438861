import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Dr_j_kumar = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/J-Kumar.jpg"
						alt=""
					/>

					<h4 className="">Dr J. Kumar</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />

						<b>Past</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Served as scientist of wheat pathology at Indian Institute of
								Wheat & Barley Research, Karnal for 24 years.
							</li>
							<li>
								<span style={{ fontWeight: 500 }}>Joint Director, </span>School
								of Crop Health Biology Research along with holding additional
								charge of Director at ICAR - National Institute of Biotic Stress
								Management (NIBSM), Raipur, Chhattisgarh.
							</li>{" "}
						</ul>
						<br />
						<b>Recognitions</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Holder of UNDP & HRDP fellowships, Humboldt fellowship
								(Germany).
							</li>
							<li>
								Chaudhary Devi Lal Outstanding All India Coordinated Research
								Project award.
							</li>
							<li>Sh. V.P. Gokhale Award of ARI, Pune.</li>{" "}
							<li>
								Best research team award of ICAR - IIW&BR, Karnal,
								Councillorship - 2 terms (north zone), ISOMPP, Udaipur.
							</li>
							<li>
								Honored as Member board of management, ICAR - IARI, New Delhi.
							</li>{" "}
							<li>
								Member institute management committees of ICAR - Sugarcane
								Breeding Institute, Coimbatore and ICAR - Central Potato
								Research Institute, Shimla.
							</li>
							<li>
								Member, Research Advisory Group, Himalayan Forest Research
								Institute, Shimla, Ph.D. and M.Sc. thesis examiner of Dr.
								YSPUH&F, Nauni, Solan, H.P, IGKV, Raipur, Chhattisgarh.
							</li>
							<li>Member, Academic Council, IGKV, Raipur.</li>
						</ul>
						<br />
						<b>Education</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								M.Sc.
							</li>{" "}
							<li>Ph.D. in Mycology and Plant Pathology.</li>{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Dr_j_kumar;
