import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";
const Dr_mrs_jyotsana_sharma = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/jyotsana_sharma.jpg"
						alt=""
					/>

					<h4 className="">Dr. (Mrs.) Jyotsana Sharma</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Principal Scientist - ICAR- National Research Centre on
								Pomegranate at Solapur, Maharashtra State, India. Joined this
								Centre in April 2006 and served in different capacities. Also
								Served as Acting Director from January 1, 2018 to April 27, 2021
							</li>{" "}
							<br />
						</ul>
						<b>Past</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Joined ICAR as Scientist in June 1989 at Central Potato Research
								Station Jalandhar (ICAR-CPRI, Shimla). Did research on soil and
								tuber borne diseases of potato.
							</li>{" "}
						</ul>
						<br />
						<b>Awards</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								<span style={{ fontWeight: 500 }}>
									‘Dalimb Ratna Award-2016’{" "}
								</span>
								Conferred by Akhil Maharashtra Pomegranate Growers Research
								Association for valuable contributions in development of model
								for management of bacterial blight disease (Telya) plant health
								management systems in pomegranate crops in all pomegranate
								growing states of India.
							</li>
							<li>
								Conferred
								<span style={{ fontWeight: 500 }}> Fellow of CHAI-2019. </span>
								By Confederation of Horticulture Association of India, New Delhi
								for contribution and commitment to the furtherance of
								Horticulture.
							</li>
							<li>
								Conferred{" "}
								<span style={{ fontWeight: 500 }}>
									{" "}
									Fellow of Society for Promotion of Horticulture for the year
									2021-22,{" "}
								</span>{" "}
								by Society for Promotion of Horticulture, IIHR, Bengaluru,
								Karnataka India, in recognition of contributions in the field of
								Horticulture- Plant Pathology.
							</li>{" "}
							<li>
								<span style={{ fontWeight: 500 }}>
									{" "}
									Narinder Nath Mohan Memorial Gold Medal
								</span>{" "}
								for outstanding performance in MSc.
							</li>
							<li>
								<span style={{ fontWeight: 500 }}>
									{" "}
									Dr. S Ramanujam Memorial Certificate of Appreciation
								</span>{" "}
								2003-04, by ICAR-CPRI, Shimla, for significant contributions in
								bringing potato revolution in India.
							</li>{" "}
						</ul>
						<br />
						<b>Patents Obtained</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								One of the Inventors on the project for the patent granted to
								ICAR for an invention entitled Bio-formulation for Potassium
								fertilizer supplement in Pomegranate and process of preparation
								thereof. Patent No 403302
							</li>{" "}
							<li>
								A Process for Preparing a bio fertilizer cum bio fungicidal
								composition with Aspergillus niger strain AN27’ Notified in ‘The
								Gazette of India’, November 29, 2003
							</li>{" "}
						</ul>
						<br />
						<b>SIGNIFICANT ACHIEVEMENTS</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Management of bacterial blight and other economically important
								diseases of pomegranate.
							</li>{" "}
							<li>
								Most recently developed, successfully demonstrated, ‘Stem
								solarisation: an eco-friendly, economical and effective
								technology to control bacterial blight’ in 4 farmers’ orchards
							</li>{" "}
							<li>
								Launched{" "}
								<span style={{ fontWeight: 500 }}>
									Social Media Platform ‘Dalimb Mitra’{" "}
								</span>{" "}
								collaborative project of ICAR- NRCP with Taral Infotech in March
								2021, which was inaugurated by Hon’ble DG (Hort.) Dr AK Singh.
								Farmers can ask their queries in their local language and
								advisories are given every fifteen days
							</li>
						</ul>
						<br />
						<b>PUBLICATIONS</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Several research publications and technical /Extension
								bulletins, brochures and videos for the benefit of farmers in
								English, Marathi, and Hindi.
							</li>{" "}
						</ul>
						<br />
						<b>EDUCATION</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								<span style={{ fontWeight: 500 }}> PhD. </span>in Plant
								Pathology from IARI, N. Delhi in 1989 (Grade point 3.91/4).
							</li>{" "}
							<li>
								<span style={{ fontWeight: 500 }}> M.Sc.</span> (with honours)
								in Plant Pathology(Grade point 3.93/4)
							</li>{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Dr_mrs_jyotsana_sharma;
