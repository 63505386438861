import React from "react";
import Footer from "../common/footer";
import NewNavbar from "../pages/newNavbar";

const Speaking = () => {
	return (
		<>
			<NewNavbar />
			<div class="container-fluid bg-primary py-5 mb-5 page-header">
				<div class="container py-5">
					<div class="row justify-content-center">
						<div class="col-lg-10 text-center">
							<h1 class="display-3 text-white animated slideInDown">Events</h1>
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb justify-content-center">
									<li class="breadcrumb-item">
										<a class="text-white" href="index.php">
											Home
										</a>
									</li>
									<li
										class="breadcrumb-item text-white active"
										aria-current="page">
										Events
									</li>
								</ol>
								<a href="/home" class="btn btn-success btn-sm">
									Go Back
								</a>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div
				class="container-xxl py-5"
				style={{
					paddingTop: "0rem !important",
					paddingBottom: "0rem !important",
				}}>
				<div class="container">
					<div class="page-detail-line">
						<p style={{ textAlign: "justify", fontSize: "17px" }}>
							Kotela Srihari speaking at US India SME Council and Montgomery
							County government delegation,Maryland State at the business event
							in Hyderabad on 30th October 2023 MOU signed between Montgomery
							County, Maryland State, USA hand Krishigap Digital Solutions Pvt
							Ltd at the US India SME Council event in Hyderabad on 30th October
							2023 . KrishiGAP, a one stop solution for scaling up the
							implementation of Global Food Safety and Sustainable
							Standards,plans to utilise Montgomery County international soft
							landing and economic development program to support its market
							expansion in USA.{" "}
							<a href="https://krishigap.com/" target="_blank">
								www.Krishigap.com
							</a>
						</p>
					</div>
					<div class="row">
						{/* <!--  <div class="col-md-4">
               <div class="card img">
                                       <img style="height: 155.9px;" class="card-img-top" src="EventsImages/krishi_gap.PNG"alt="krishi_gap.PNG">
                                          <div class="card-body">
                     <a href="event-description.php?id=1"><h5 style="height: 75px;overflow: auto;" class="card-title">
                     Lorem ipsum dolor sit amet consectetur adipisicing elit.
                     </h5></a>
                     <p style="overflow: auto;text-align: justify;" class="card-text listing-1-text">The input was closely monitored and carefully analysed after which a Bolivian lady was identified as traveling from Brazil to Goa with layovers at ...</p>
                     <a href="event-description.php?id=1" class="btn btn-danger pull-right" style="box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%);">Read More</a>
                  </div>
               </div>
            </div> --> */}

						<div class="col-md-4 mt-3 mb-3">
							<img
								src="./images/Image-5.jpg"
								alt=""
								style={{ width: "275px" }}
							/>
						</div>
						<div class="col-md-4 mt-3 mb-3">
							<img
								src="./images/Image-6.jpg"
								alt=""
								style={{ width: "275px" }}
							/>
						</div>
						<div class="col-md-4 mt-3 mb-3">
							<img
								src="./images/Image-7.jpg"
								alt=""
								style={{ width: "275px" }}
							/>
						</div>
						<div class="col-md-4 mt-3 mb-3">
							<img
								src="./images/Image-8.jpg"
								alt=""
								style={{ width: "275px" }}
							/>
						</div>
						<div class="col-md-4 mt-3 mb-3">
							<img
								src="./images/Image-9.jpg"
								alt=""
								style={{ width: "275px" }}
							/>
						</div>
						<div class="col-md-4 mt-3 mb-3">
							<img
								src="./images/Image-10.jpg"
								alt=""
								style={{ width: "275px" }}
							/>
						</div>
					</div>
				</div>
			</div>
			<div>
				<Footer className="jnav"></Footer>
			</div>
		</>
	);
};

export default Speaking;
