import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";
const Ourteam = () => {
	return (
		<div style={{}}>
			<NewNavbar />
			<div
				style={{
					backgroundImage: "url('../images/globalacademybg.jpg')",
					backgroundRepeat: "no-repeat",
				}}>
				<div
					className="container"
					style={{ paddingTop: "30px", paddingBottom: "30px" }}>
					<h1 className="" style={{ textAlign: "center" }}>
						Experts
					</h1>

					{/* <h3 className="">Company formation</h3> */}
					<div className="row">
						<div class="col" style={{}}>
							<p class="mb-4"></p>
						</div>
					</div>

					<div id="exTab2">
						<ul class="nav nav-tabs">
							<li class="active">
								<a href="#2" data-toggle="tab">
									Standard and Food Safety Experts
								</a>
							</li>
							<li>
								<a href="#3" data-toggle="tab">
									Products Value Chain Experts
								</a>
							</li>
							<li>
								<a href="#8" data-toggle="tab">
									Organic Value Chain
								</a>
							</li>
							<li>
								<a href="#6" data-toggle="tab">
									Dairy Value Chain
								</a>
							</li>
							<li>
								<a href="#7" data-toggle="tab">
									Fisheries &amp; Aquaculture Value Chain
								</a>
							</li>
							<li>
								<a href="#4" data-toggle="tab">
									Technology Experts
								</a>
							</li>
							<li>
								<a href="#5" data-toggle="tab">
									Compliance Experts
								</a>
							</li>
						</ul>
					</div>

					<div class="tab-content ">
						<div class="tab-pane active" id="2">
							<h3 class="pt-3 ">Standard and Food Safety Experts</h3>
							<br />
							<div class="row">
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/srihari_kotela">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/shri-hari.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/srihari_kotela">
												<h5 class="mb-0">Srihari Kotela</h5>
											</a>
											<small>
												Expert in Agricultural, Food Safety and Sustainability
												Standards
											</small>
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_seema_shukla">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Dr-Seema-Shukla.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_seema_shukla">
												<h5 class="mb-0">Dr Seema Shukla</h5>
											</a>
											<small>Expert on Indian and International</small>
											<br />
											<small>
												(Codex, EU, USFDA, CFIA, ASEAN) standards and
												regulations within food supply chain
											</small>
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/mr_mohan_m_kulkarni">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Mohan_M_Kulkarni.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/mr_mohan_m_kulkarni">
												<h5 class="mb-0">Mr. Mohan M. Kulkarni</h5>
											</a>
											<small>
												Standard Expert (EMS, Carbon Footprint, Water Foot
												Print)
											</small>
											{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/mr_devendra_prasad">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/devender-prasad.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/mr_devendra_prasad">
												<h5 class="mb-0">Mr. Devendra Prasad</h5>
											</a>
											<small>
												Laboratory and Food Safety Compliances Specialist
											</small>
											<br />
											<small>Former-DGM, APEDA</small>
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/mr_sumant_d_parkhi">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Sumant_D_Parkhi.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/mr_sumant_d_parkhi">
												<h5 class="mb-0">Mr. Sumant D. Parkhi</h5>
											</a>
											<small>Standard Expert (QMS, EMS and EnMS)</small>
											{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/mrs_dipti_saudagar">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Dipti_Saudagar.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/mrs_dipti_saudagar">
												<h5 class="mb-0">Mrs Dipti Saudagar</h5>
											</a>
											<small>Food Safety and Compliances Expert</small>
											{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/ms_chetna_ipar">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Chetna_Ipar.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/ms_chetna_ipar">
												<h5 class="mb-0">Ms. Chetna Ipar</h5>
											</a>
											<small>
												Food Safety, Quality and Regulatory Compliances.
											</small>
											{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/mr_onkar_choche">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Onkar_Choche.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/mr_onkar_choche">
												<h5 class="mb-0">Mr. Onkar Choche</h5>
											</a>
											<small>
												Food Safety, Quality and Regulatory Compliances.
											</small>
											{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="tab-pane" id="3">
							<h3 class="pt-3 pb-3">Product Value Chain Experts</h3>
							<div class="row">
								<div class="col-lg-3 col-md-6">
									<div class=" team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_santosh_j_eapen">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/DrSanthoshJEapen.png"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_santosh_j_eapen">
												<h5 class="mb-0">Dr. Santhosh J. Eapen</h5>
											</a>
											<small>Spices Specialist</small>
											<br />
											<small>Ex Head ICAR Spices Research</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_abraham_verghese">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/DrAbrahamVerghese.png"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_abraham_verghese">
												<h5 class="mb-0">Dr. Abraham Verghese</h5>
											</a>
											<small>IPM Specialist</small>
											<br />
											<small>Ex Director ,ICAR NBAIR</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_ms_rao">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Dr-MS-Rao.png"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_ms_rao">
												<h5 class="mb-0">Dr. MS Rao</h5>
											</a>
											<small>Bio Pesticides Specialist</small>
											<br />
											<small>Ex Principal Scientist ,ICAR</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_vilas_a_tonapi">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/vilas-a-tonapi.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_vilas_a_tonapi">
												<h5 class="mb-0">Dr. Vilas A Tonapi</h5>
											</a>
											<small>Millet Value Chain Specialist </small>
											<br />
											<small>Former Director, ICAR-IIMR</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_pandurang_gundappa_adsule">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/pandurang-gundappaad-sule.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_pandurang_gundappa_adsule">
												<h5 class="mb-0">Dr. Pandurang Gundappa Adsule</h5>
											</a>
											<small>Grape Value Chain Specialist</small>
											<br />
											<small>
												Former Director, National Research Centre for Grapes
											</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_kapse_bhagwan">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/kapse_bhagwan.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_kapse_bhagwan">
												<h5 class="mb-0">Dr. Kapse Bhagwan</h5>
											</a>
											<small>
												Mango, Sweet Orange &amp; Banana Supply Chain Expert
											</small>
											<small>
												Former Director, National Institute of Post-Harvest
												Technology
											</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_mrs_jyotsana_sharma">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/jyotsana_sharma.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_mrs_jyotsana_sharma">
												<h5 class="mb-0">Dr. (Mrs.) Jyotsana Sharma</h5>
											</a>
											<small>Pomegranate Value Chain </small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/joginder_singh_minhas">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Joginder-Singh-Minhas.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/joginder_singh_minhas">
												<h5 class="mb-0">Joginder Singh Minhas</h5>
											</a>
											<small>Potato Value Chain</small>
											<br />
											<small>
												Project Manager, International Potato Center
											</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_dharmesh_verma">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/DharmeshVerma.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_dharmesh_verma">
												<h5 class="mb-0">Dr Dharmesh Verma</h5>
											</a>
											<small>Basmati Rice Value Chain Expert</small>
											{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_vijay_singh_thakur">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Vijay-Singh-Thakur.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_vijay_singh_thakur">
												<h5 class="mb-0">Dr Vijay Singh Thakur</h5>
											</a>
											<small>Apple and Horticulture Value Chain</small>
											{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_amar_nath_sharma">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/amar-nath-sharma.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_amar_nath_sharma">
												<h5 class="mb-0">Dr Amar Nath Sharma</h5>
											</a>
											<small>Soybean Value Chain</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_r_jagadeeshwar">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Jagadeeshwar.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_r_jagadeeshwar">
												<h5 class="mb-0">Dr R. Jagadeeshwar</h5>
											</a>
											<small>Rice Value Chain</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./dr_s_n_singh">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/S-N-Singh.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./dr_s_n_singh">
												<h5 class="mb-0">Dr S.N. Singh</h5>
											</a>
											<small>Sugarcane Value Chain</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_j_kumar">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/J-Kumar.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_j_kumar">
												<h5 class="mb-0">Dr J. Kumar</h5>
											</a>
											<small>Wheat Value Chain Expert</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="/dr_vemuri_ravindra_babu">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Vemuri-Ravindra-Babu.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/dr_vemuri_ravindra_babu">
												<h5 class="mb-0">Dr. Vemuri Ravindra Babu</h5>
											</a>
											<small>Field Crops Value Chain Expert</small>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="tab-pane" id="4">
							<h3 class="pt-3 pb-3">Technology Experts</h3>
							<div class="row">
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./mr_chacko_jacob">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/chacko_jacob.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./mr_chacko_jacob">
												<h5 class="mb-0">Mr Chacko Jacob</h5>
											</a>
											<small>
												Co-founder and Chief Business Officer Mist EO
											</small>
											<small>Precision and Remote Sensing Expert</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./venkat_pindipolu">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/venkat-pindipolu.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./venkat_pindipolu">
												<h5 class="mb-0">Venkat Pindipolu</h5>
											</a>
											<small>Technology Expert </small>
											<br />
											<small>Co-founder, Carbon Mint</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./anju_nayyar">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/anju_nayyar.png"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./anju_nayyar">
												<h5 class="mb-0">Anju Nayyar</h5>
											</a>
											<small>Digital Solutions Expert </small>
											<br />
											<small>
												Senior Advisor – Business Development &amp; Alliances,
												YARA International
											</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./rama_reddy_kovvuri">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/rama_reddy_kovvuri.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./rama_reddy_kovvuri">
												<h5 class="mb-0">Rama Reddy Kovvuri</h5>
											</a>
											<small>Technology Expert </small>
											<br />
											<small>Vice President Tyisha Technologies</small>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="tab-pane" id="5">
							<h3 class="pt-3 pb-3">Compliance Experts</h3>
							<div class="row">
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./ca_ramachandra_rao_tummala">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Ca-ramchandra.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./ca_ramachandra_rao_tummala">
												<h5 class="mb-0">CA Ramachandra Rao Tummala</h5>
											</a>
											<small>Finance, Systems &amp; Compliance Expert </small>
											<small>Founder :T R R &amp; Associates</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./akhil_mittal">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/akhil_mittal.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./akhil_mittal">
												<h5 class="mb-0">Akhil Mittal</h5>
											</a>
											<small>Corporate Compliances Expert</small>
											{/* <!--<small>Precision and Remote Sensing Expert</small>--> */}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="tab-pane" id="6">
							<h3 class="pt-3 pb-3">Dairy Value Chain Expert</h3>
							<div class="row">
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./t_appaji_rao">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/T_Appaji_Rao.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./t_appaji_rao">
												<h5 class="mb-0">T. Appaji Rao</h5>
											</a>
											<small>Dairy Value Chain Expert </small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./dr_mohinder_kumar_salooja">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/MohinderKumarSalooja.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./dr_mohinder_kumar_salooja">
												<h5 class="mb-0">Dr. Mohinder Kumar Salooja</h5>
											</a>
											<small>Dairy Value Chain Expert </small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./dr_omveersingh">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Omveer-Singh.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./dr_omveersingh">
												<h5 class="mb-0">Dr. Omveer Singh</h5>
											</a>
											<small>Dairy and Horticulture Value Chain </small>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="tab-pane" id="7">
							<h3 class="pt-3 pb-3">Fisheries &amp; Aquaculture Value Chain</h3>
							<div class="row">
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./prathap_chandra_shetty">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Prathap_Chandra_Shetty.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./prathap_chandra_shetty">
												<h5 class="mb-0">Prathap Chandra Shetty</h5>
											</a>
											<small>
												Fisheries and Aquaculture Value Chain Expert{" "}
											</small>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="tab-pane" id="8">
							<h3 class="pt-3 pb-3">Organic Value Chain</h3>
							<div class="row">
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./dr_pvsm_gouri">
													<img
														style={{ width: "231px", height: "231px" }}
														class="img-fluid"
														src="../images/Dr.-P-V-S-M-Gouri.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-white"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./dr_pvsm_gouri">
												<h5 class="mb-0">Dr. P V S M Gouri</h5>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br />
				</div>
			</div>

			<Footer />
		</div>
	);
};
export default Ourteam;
