import { React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import "../Menu.css";
import { logout } from "../redux/actions/authAction";

function NewNavbar() {
	const { auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	return (
		<>
			<div
				className="row"
				style={{
					backgroundColor: "#1F6644",
					marginTop: "",
					padding: "5px 0 5px 0",
				}}>
				<marquee
					style={{ fontWeight: "400", fontSize: "14px", color: "#ffffff" }}>
					{" "}
					*GLOBAL ACADEMY ON FOOD SAFETY AND SUSTAINABILITY STANDARDS*
				</marquee>
			</div>
			<nav
				className="container-fluid"
				style={{
					background: "#fff",
					height: "85px",
					display: "flex",
					alignItems: "center",
					//   padding: "0 115px",
					zIndex: "12",
					justifyContent: "center",
					// marginLeft:"-100px"
				}}>
				<div onClick={() => (window.location.href = "/")}>
					<img
						alt="logo"
						className=""
						style={{ width: "70px" }}
						src="../images/LOGO.PNG"
					/>
				</div>

				<div
					className="d-flex align-items-center justify-content-between"
					style={{ width: "350px" }}>
					{/* <Popupnav /> */}

					<a
						class="navbar-brand"
						href="/"
						style={{
							color: "green",
							fontSize: "26px",
							fontWeight: "700",
							display: "flex",
							flexDirection: "column",
						}}>
						GLOBAL ACADEMY
						<span
							style={{ color: "#000000", fontSize: "14px", fontWeight: "700" }}>
							On Food Safety And Sustainability Standards
						</span>
					</a>
				</div>
				{/* <Bars className="bars1" /> */}
				<div style={{ display: "flex" }}>
					<div className="navmenu1">
						{/* <div className="burger">
            <i class="fa-solid fa-bars"></i>
          </div> */}
						{/* <NavLink to="/home" className="navlink1">
            HOME
          </NavLink> */}
						<NavLink
							id="color"
							to="/"
							className="navlink1"
							activeStyle
							style={{ fontWeight: "700" }}>
							HOME
						</NavLink>
						{/* <NavLink
							to="/about"
							className="navlink1"
							activeStyle
							style={{ fontWeight: "700" }}>
							ABOUT US
						</NavLink> */}
						<div className="navlink1" activeStyle style={{ padding: "0" }}>
							<div class="dropdown">
								<button class="dropbtn" style={{ fontWeight: "700" }}>
									ABOUT US
									<i
										style={{ paddingLeft: "5px" }}
										class="fa fa-caret-down"></i>
								</button>
								<div class="dropdown-content">
									<a href="/aboutus" style={{ fontWeight: "500" }}>
										About&nbsp;Us
									</a>
									<a href="/companytraction" style={{ fontWeight: "500" }}>
										Company&nbsp;Traction
									</a>
									<a href="/ourteam" style={{ fontWeight: "500" }}>
										Our&nbsp;Team
									</a>
									<a href="/experts" style={{ fontWeight: "500" }}>
										Experts
									</a>
									<a href="/modelfarm" style={{ fontWeight: "500" }}>
										Model Farm
									</a>
								</div>
							</div>
						</div>
						{/* <NavLink
							to="/whatwedo"
							className="navlink1"
							activeStyle
							style={{ fontWeight: "700" }}>
							WHAT WE DO
						</NavLink> */}
						<div className="navlink1" activeStyle style={{ padding: "0" }}>
							<div class="dropdown">
								<button class="dropbtn" style={{ fontWeight: "700" }}>
									WHAT WE DO
									<i
										style={{ paddingLeft: "5px" }}
										class="fa fa-caret-down"></i>
								</button>
								<div class="dropdown-content">
									<a href="/problemstatement" style={{ fontWeight: "500" }}>
										Problem&nbsp;Statement
									</a>
									<a href="/approach" style={{ fontWeight: "500" }}>
										Our&nbsp;Approach
									</a>
									<a href="/platformfeatures" style={{ fontWeight: "500" }}>
										Platform &nbsp;Features
									</a>
									<a href="/standardscoverage" style={{ fontWeight: "500" }}>
										Standards&nbsp;Coverage{" "}
									</a>
									<a href="/targetaudience" style={{ fontWeight: "500" }}>
										Target&nbsp;Audience
									</a>
									<a href="/impactcreation" style={{ fontWeight: "500" }}>
										Impact&nbsp;Creation
									</a>
								</div>
							</div>
						</div>
						<NavLink
							to="/faqs"
							className="navlink1"
							activeStyle
							style={{ fontWeight: "700" }}>
							FAQS
						</NavLink>

						<div class="dropdown " style={{ marginLeft: "350px" }}>
							<button class="dropbtn">
								<i style={{ paddingLeft: "" }} class="fa fa-user"></i>
								<i style={{ paddingLeft: "10px" }} class="fa fa-caret-down"></i>
							</button>
							<div class="dropdown-content">
								{auth.user === undefined ? (
									<a href="/login">Login</a>
								) : (
									<>
										<Link
											// style={{ color: "white" }}
											className="dropdown-item"
											to="/login"
											onClick={() => dispatch(logout())}>
											Logout
										</Link>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</nav>
			<div className="row">
				<div className="col"></div>
			</div>
		</>
	);
}

export default NewNavbar;
