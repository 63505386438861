import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";
const Shashi_sareen = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/DrLeslieDBourquin.png"
						alt=""
					/>

					<h4 className="">Dr. Leslie D. Bourquin</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Dept. Chairperson, Food Science and Human Nutrition ,Michigan
								State University ,USA.
							</li>{" "}
						</ul>

						<b>Past</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Conducted trainings on food safety management systems including
								Hazard Analysis and Critical Control Points (HACCP), good
								manufacturing practices, hygiene and sanitation, good
								agricultural practices (pre-harvest food safety), and food
								safety programs for restaurants and retail food operations
								throughout the United States and internationally.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								He collaborates extensively with the private sector, academia,
								governments and International Organizations (e.g. WTO, APEC,
								World Bank) in implementing food safety capacity development
								projects globally.
							</li>{" "}
						</ul>
						<br />
						<b>Professional Credentials</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								1997-Present International HACCP Alliance, Certified Lead
								Instructor
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								2013-Present Institute of Food Technologists, Certified Food
								Scientist
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								2013-Present National Registry of Food Safety Professionals,
								Food Safety HACCP
							</li>{" "}
						</ul>
						<br />

						<b>Education</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Ph.D., Nutritional Sciences, University of Illinois at
								Urbana-Champaign
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								M.S., Animal Sciences, University of Illinois at
								Urbana-Champaign
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								B.S., Agriculture (Animal Science), Oklahoma State University,
								Stillwater
							</li>{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Shashi_sareen;
