import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";
const Mr_sumant_d_parkhi = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						// class="img-fluid"
						src="../images/Sumant_D_Parkhi.jpg"
						alt=""
					/>

					<h4 className="">Mr. Sumant D. Parkhi</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Consulting and training on Quality and Environment and Energy
								Management Systems.
							</li>

							<br />
						</ul>
						<b>Past</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								More than 25years of consulting, training and auditing
								experience in various fields like management/environment system
								standards. ( QMS,EMS, EnMS, OHSAS,SA 8000).
							</li>
							<li>Completed total 1500+consultancy projects.</li>{" "}
							<li>
								Trained more than 1,25,000 participants from various
								organizations.
							</li>{" "}
						</ul>

						<br />
						<b>Professional Qualifications:</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Principal Auditor–Quality Management System.
							</li>{" "}
							<li> Principal Auditor – Environmental Management Systems. </li>
							<li> Lead Assessor- HACCP.</li>
							<li> Lead Auditor –Energy Management System. </li>
							<li> SA8000 Introduction & Basic Auditor Course.</li>
						</ul>

						<br />
						<b>Education</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Diploma in Business Management (DBM).
							</li>{" "}
							<li>Master in Management Science (MMS).</li>
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Mr_sumant_d_parkhi;
