import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Dr_vemuri_ravindra_babu = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/Vemuri-Ravindra-Babu.jpg"
						alt=""
					/>

					<h4 className="">Dr. Vemuri Ravindra Babu</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />

						<b>Past</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								National Director (Field crops) at Patanjali Bio-Research
								Institute, Haridwar and trained more than 40000 farmers on
								Organic Farming in 20 states.
							</li>
							<li>
								Director – Indian institute of Rice Research, ICAR, Hyderabad
								and served in different capacities as Principal Scientist, Head
								,Plant Breeding and Project coordinator.
							</li>{" "}
							<li>
								Instrumental in developing more than 10 rice varieties, which
								gained popularity among the farming community.
							</li>{" "}
							<li>
								Under rice bio-fortification program, developed DRR dhan – 45,
								the first high yielding & high zinc variety notified by Central
								Variety Release Committee.
							</li>
							<li>
								Served in different capacities for 13 years at ICAR–Central Soil
								Salinity Research Institute, Regional Research Station, Anand
								and during this tenure developed saline tolerant varieties in
								paddy, wheat, mustard and Bengal gram.
							</li>{" "}
							<li>
								He also identified salt resistant Salvador sp. and other salt
								tolerant grass sps for high salt areas of Bhal region of
								Gujarat.
							</li>{" "}
						</ul>
						<br />
						<b>Recognitions</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								As the Director, IIRR, he has monitored the progress of more
								than 50 externally funded projects and 20 contractual research
								projects.
							</li>
							<li>
								Received more than 15 individual and team awards including the
								outstanding best ICAR Institute Award and CSIR award for S & T
								innovations for Rural Development.
							</li>
							<li>
								Published more than 120 research papers in peer reviewed
								national and International journals.
							</li>{" "}
							<li>
								Presently elected as President, Retired ICAR Employees
								Association, Hyderabad.
							</li>
							<li>
								He has also lead the national bio-fortification programmed on
								different crops and identified many high nutritional varieties.
							</li>{" "}
						</ul>
						<br />
						<b>Education</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								M.Sc (Ag)
							</li>
							Ph.D. (Genetics & Plant Breeding).{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Dr_vemuri_ravindra_babu;
