import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Shashi_sareen = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/MohinderKumarSalooja.jpg"
						alt=""
					/>

					<h4 className="">Dr. Mohinder Kumar Salooja</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Member Scientific Panel of FSSAI: Labelling and Claim/
								Advertisement- from 1.3.2023.
							</li>{" "}
						</ul>

						<b>Past</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Retired Professor (Dairy Technology) & Former Director, School
								of Agriculture, IGNOU, New Delhi.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Capacity Building in the areas of: Dairy Technology, Food Safety
								& Entrepreneurial programs.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Design, Development and Delivery of Online and Open and Distance
								Learning Programs.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Senior research officer on deputation at Planning Commission
								Govt. of India as Educational planning and research.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Program Coordinator for Four Programs (I ) Diploma in Dairy
								Technology; (ii) Diploma in Value Added Products from Fruits &
								Vegetables; (iii) Post Graduate Diploma in Food Safety & Quality
								Management; and (iv) Ph.D. in Dairy Science & Technology.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Chairman Horticulture Cell, IGNOU in 2009 and 2018 to June,
								2019.
							</li>{" "}
						</ul>
						<br />
						<b>Professional Experience</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								An expert on temperate fruits, diseases and pest management,
								completed research work on 32 research projects funded
								internationally and nationally.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Specialized in research on epidemiology, weather monitoring &
								disease forecasting methods, pesticides mode of action and
								application.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Significant achievements in production of quality planting
								material of commercially important temperate fruits, medicinal
								plants, and floriculture have made.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Established bud wood & Gene bank for fruits, integrated
								diseases, pests and nutrient management, use of bio-fertilizers
								& natural/organic farming, crop regulation and energy efficient
								canopy management.
							</li>{" "}
						</ul>
						<br />

						<b>Acadamic Acheivments</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Programs Designed/Developed/Coordinated/Associated under Open
								and Distance Learning (ODL) in Agriculture and Food Processing -
								11 programs.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Developed online programs: PG Diplomas: Food safety and Quality
								Management and Food Science and Technology. Creation of Online
								“Safe and Assured Food E-learning” (SAFE) Platform for running
								PG Diploma in Food Safety and Quality Management and PG Diploma
								in Food Science and Technology.
							</li>{" "}
						</ul>
						<br />

						<b>Awards and Recognitions</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Jawaharlal Nehru Award (ICAR Young Scientist Award for Ph.D).
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Commonwealth Scholarship, Australia Plan, during Post-Doctorate.
							</li>{" "}
						</ul>
						<br />

						<b>Education</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Post Doctorate from Dairy Research Laboratory, CSIRO, Australia.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Ph.D. Dairy Technology in 1983 from National Dairy Research
								Institute (NDRI).
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								M.Sc. Dairy Technology.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								B.Sc. Dairy Technology.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								ISO 22000:2018 Lead Auditor course in 2020 from CQI-IRCA
								certified course.
							</li>{" "}
						</ul>
						<br />
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Shashi_sareen;
