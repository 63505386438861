import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Mr_mohan_m_kulkarni = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						// class="img-fluid"
						src="../images/Mohan_M_Kulkarni.jpg"
						alt=""
					/>

					<h4 className="">Mr. Mohan M. Kulkarni</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Consulting and training on Quality and Environment and Energy
								Management Systems.
							</li>

							<br />
						</ul>
						<b>Past</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Audit and Consultancy in ISO-14001 and 45001, EnMs 50001, PAS99,
								ISO14064 (Carbon Footprint), ISO14046 (Water Footprint).
							</li>
							<li>
								Leading the AIM ENVIRO group, having foot hold in Energy,
								Environment, Occupational Health & Safety.
							</li>{" "}
							<li>
								Bio-Medical Waste Management and “Green Audit” A novel program
								for waste reduction. Ozone depletion and Climate Change (Global
								warming, CDM & Carbon Trading).
							</li>{" "}
							<li>
								EHS Legal compliance audit and training, Environmental liability
								assessment, Environmental Due diligence, ‘Environment Knowledge
								Centre’– Acknowledge based environmental social initiative.
							</li>
							<li>
								Over 30 years of industrial and consulting experience including
								reputed industrial organizations in various responsible
								capacities, viz. Kirloskar, Birla, Bosch.
							</li>{" "}
							<li>Working as Consultant in various fields for over 30years.</li>{" "}
						</ul>
						<br />
						<b> Areas OF Special Interest:</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Environment Impact Assessment studies & Environmental Risk
								Assessment Mathematical modeling & Computer simulation for
								Environmental Assessment.
							</li>
							<li>Design of Water, Air & Noise Pollution Control Systems.</li>
							<li>
								Reduction in Green House Gas emissions (including Energy &
								Natural resources conservation), Carbon Footprint, Water
								footprint, Carbon credits & Carbon trading.
							</li>{" "}
							<li>
								Reduction in Green House Gas emissions (including Energy &
								Natural resources conservation), Carbon Footprint, Water
								footprint, Carbon credits & Carbon trading.
							</li>
							<li>
								Contribution on White paper on Endocrine descriptors for EU
								legislations.{" "}
							</li>{" "}
							<li>
								Waste minimization (including Hazardous Waste Audit),Bio-medical
								waste & Municipal solid waste management and Application of
								Biotechnology.
							</li>
						</ul>
						<br />
						<b>Professional Qualifications</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Lead Auditor: ISO14001, OHSAS18001, EnMS, HACCP.
							</li>{" "}
						</ul>
						<br />
						<b>Professional Memberships</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Member, The Institution of Engineers (India).
							</li>{" "}
							<li> Fellow, Institution of Valuers. </li>
							<li>
								{" "}
								Founder Member, Practicing Valuers Association (India) Member,
								Indian Institute of Metals: M.I.I.M.{" "}
							</li>
							<li>
								{" "}
								Member, Indian Council of Arbitration :M.I.C.A. ;Panel
								Arbitrator.{" "}
							</li>
							<li>
								{" "}
								Member, Action Committee, Upper Godavari Global Water
								Partnership{" "}
							</li>
						</ul>
						<br />
						<b>Educational Qualifications</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								B.E., M.M.S., C. Eng. (I), M.I.E., F.I.V., M.I..I.M., M.I.C.A.,
								S.L.A.
							</li>{" "}
						</ul>

						<b>ISO & Other Management Systems</b>
						<br />
						<br />
						<b>Visiting Faculty/ Lectures</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Visiting faculty to many educational, professional and training
								institutions viz;
							</li>{" "}
							<li>
								{" "}
								Various institutes of Engineering & Technology and Management,
								Symbiosis–SIOM and SCMHRD, Mahindra Institute of Quality (MIQ &
								MMDC), Maharashtra Jeevan Pradhikaran, Upper Godavari Water
								Partnership (Global Water Partnership),India Security Press.{" "}
							</li>
							<li>
								{" "}
								The Institution of Engineers, Industries & Manufacturers
								’Association (NIMA), The Indian Institute of Architects,
								Practicing Valuers Association (India), Institution of Valuers
							</li>
						</ul>

						<br />
						<b>Propagation OF Innovative Concepts</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								ELV, WEEE & RoHS, REACH, Carbon foot print, Carbon neutrality,
								Water Footprint & Inventory of Green House Gases (GWP),
								Environmental Product Declaration (EPD).
							</li>{" "}
							<li>
								{" "}
								Green building: IGBC-LEED & GRIHA, Green concepts for
								developmental projects, Environmental Site Assessment, Due
								Diligence, Environmental liability assessment.
							</li>
							<li>
								{" "}
								‘HRTS’– High Rate Transpiration System for disposal of treated
								effluent ‘CTW’ Constructed Wet land and ‘Bio Filter’ for
								treatment of canteen/ domestic waste water.
							</li>
							<li>
								{" "}
								Water audit, Energy audit & Waste Audit, Zero waste to landfill,
								Industrial Ecology & Circular economy, Extended Producer
								responsibility (EPR), GRS, Ocean Bound Plastic (OBP).
							</li>
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Mr_mohan_m_kulkarni;
