import "emoji-mart/css/emoji-mart.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "../components/Carousel";
import CardFooter from "../components/home/post_card/CardFooter";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI } from "../utils/fetchData";
import Addpost from "./addpost";
import "./communitystyles.css";
import NewNavbar from "./newNavbar";
const Foodsafetyacademy = () => {
	// console.log(props.data, window.location.pathname.split("/"));
	const dispatch = useDispatch();
	const { profile, auth, homePosts } = useSelector((state) => state);
	// console.log("auth", auth);

	const [showShareModal, setShowShareModal] = useState(false);
	const [showNewPostModal, setShowNewPostModal] = useState(false);
	const [showAudienceModal, setShowAudienceModal] = useState(false);
	const [community, setCommunity] = useState({});
	const [userRole, setUserRole] = useState("");
	const [displaySubjects, setDisplaySubjects] = useState([]);
	const [displayPosts, setDisplayPosts] = useState(homePosts.posts);
	const [selectedDisplaySubject, setSelectedDisplaySubject] = useState("All");

	const handleCloseShareModal = () => {
		setShowShareModal(false);
	};
	const handleOpenShareModal = () => {
		setShowShareModal(true);
	};

	const handleOpenAudienceModal = () => {
		setShowAudienceModal(true);
		setShowNewPostModal(false);
	};

	useEffect(() => {
		console.log("selectedDisplaySubject", selectedDisplaySubject);
		if (selectedDisplaySubject === "All") {
			setDisplayPosts(homePosts.posts);
			return;
		}
		const filterBySearch = homePosts.posts.filter((item) => {
			console.log("item", item);
			if (
				item.subject
					.toLowerCase()
					.includes(selectedDisplaySubject.toLowerCase())
			) {
				return item;
			}
		});
		setDisplayPosts(filterBySearch);
	}, [selectedDisplaySubject, homePosts.posts]);

	useEffect(() => {
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		if (auth.user !== undefined) {
			getDataAPI(
				`community/${auth.user.id}/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af`,
				auth.token
			).then((res) => {
				console.log("res single community", res.data);
				setCommunity(res.data.community);
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			});

			getDataAPI(
				`getcommunityuser/${auth.user.id}/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af`,
				auth.token
			).then((res) => {
				console.log("res community user", res.data);
				setUserRole(res.data.communityuser.role);
			});

			getDataAPI(`getpostsubjects`, auth.token).then((res) => {
				console.log("res post subjects", res.data);
				setDisplaySubjects([{ name: "All" }, ...res.data.postsubjects]);
			});
		} else {
			getDataAPI(
				`communitybyid/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af`,
				auth.token
			).then((res) => {
				console.log("res single community", res.data);
				setCommunity(res.data.community);
				dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
			});

			getDataAPI(`getpostsubjects`, auth.token).then((res) => {
				console.log("res post subjects", res.data);
				setDisplaySubjects([{ name: "All" }, ...res.data.postsubjects]);
			});

			setUserRole("user");
		}
		setTimeout(() => {
			dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
		}, 500);
	}, [auth]);

	const FirstTab = (props) => {
		return (
			<div>
				<Button
					onClick={buttonClick}
					style={{ backgroundColor: "#3CCE8F", border: "none" }}>
					Create Community
				</Button>
			</div>
		);
	};
	const buttonClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// console.log("Button clicked");
		// setShow3(true);
	};
	// useEffect(() => {
	// 	console.log("text", text);
	// }, [text]);
	return (
		<>
			<Modal
				show={showShareModal}
				onHide={handleCloseShareModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								Share Community URL
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={handleCloseShareModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px" }} />
					<div className="uc-modal-body" style={{ padding: "20px 30px" }}>
						<div className="uc-detail-btn-wrap uc-share-btn-wrap">
							<div className="row">
								<div
									className="col-9"
									style={{ paddingRight: "0px", color: "red" }}>
									<input
										type="text"
										className="uc-formControl input"
										id="copytext"
										defaultValue={
											window.location.host + window.location.pathname
										}
										disabled
										// readonly=""
									/>
								</div>
								<div className="col-3" style={{ paddingLeft: "0px" }}>
									<a
										href="javascript:void(0)"
										// onClick="copyCommunityUrl()"
										className="copybutton">
										<img src="../images/Copyshare_link.svg" alt="copy" />
										<span style={{ color: "#fff", paddingLeft: "4px" }}>
											Copy
										</span>
										{/* <div
                  className="popover__content cpy-tooltip-head"
                  style="display:none"
                >
                  <p>Link Copied</p>
                </div> */}
									</a>
								</div>
							</div>
						</div>
						<div
							className="uc-modal-footer share-footer"
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								padding: "20px 12px 12px 12px",
							}}>
							<a
								href="mailto:?Subject=Community Url&amp;Body=https://ulektz.com/c/krishigap"
								className="email-share"
								target="_blank"
								style={{
									margin: "0px 10px",
									fontSize: "13px",
									minWidth: "65px",
									textAlign: "center",
									fontWeight: "500",
									color: "#000",
								}}
								rel="noreferrer">
								<img
									src="../images/share-email.svg"
									alt="email"
									className="image"
								/>
								<br />
								Email
							</a>
							<a
								href="https://www.linkedin.com/sharing/share-offsite/?url=https://ulektz.com/c/krishigap"
								className="linkedin-share"
								target="_blank"
								style={{
									margin: "0px 10px",
									fontSize: "13px",
									minWidth: "65px",
									textAlign: "center",
									fontWeight: "500",
									color: "#000",
								}}
								rel="noreferrer">
								<img
									src="../images/share-linkedin.svg"
									alt="linkedin"
									className="image"
								/>
								Linkedin
							</a>
							<a
								href="https://www.facebook.com/sharer.php?u=https://ulektz.com/c/krishigap"
								className="facebook-share"
								target="_blank"
								style={{
									margin: "0px 10px",
									fontSize: "13px",
									minWidth: "65px",
									textAlign: "center",
									fontWeight: "500",
									color: "#000",
								}}
								rel="noreferrer">
								<img
									src="../images/share-facebook.svg"
									alt="facebook"
									className="image"
								/>
								Facebook
							</a>
							<a
								href="https://twitter.com/share?text=Community Url&amp;url=https://ulektz.com/c/krishigap"
								className="twitter-share"
								target="_blank"
								style={{
									margin: "0px 10px",
									fontSize: "13px",
									minWidth: "65px",
									textAlign: "center",
									fontWeight: "500",
									color: "#000",
								}}
								rel="noreferrer">
								<img
									src="../images/share-twitter.svg"
									alt="twitter"
									className="image"
								/>
								Twitter
							</a>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showAudienceModal}
				onHide={handleOpenAudienceModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11" style={{ paddingLeft: "0px" }}>
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
									marginBottom: "0px",
								}}>
								Select Audience
							</h2>
						</div>
						{/* <div
              className="col-1"
              style={{ padding: "10px", cursor: "pointer" }}
              onClick={handleCloseNewPostModal}
            >
              <img src="./images/closeOne.png"></img>
            </div> */}
					</div>
					<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
					<div style={{ paddingTop: "10px" }}>
						<div style={{ fontSize: "16px", fontWeight: "400" }}>
							Who can see your post?
						</div>
						<div style={{ fontSize: "12px", marginBottom: "25px" }}>
							Your post will be visible on feed, on your profile and see in
							search results
						</div>
						<div
							className="row"
							style={{
								padding: "10px 12px",
								background: "#f1f1f1",
								borderRadius: "5px",
								marginBottom: "15px",
								border: "1px solid #ddd",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div className="col-11">
								<span>
									<img src="../images/Public_icon.svg" alt="" /> All Member
								</span>
							</div>
							<div className="col-1">
								<label>
									<input
										type="radio"
										name="audience"
										id="Public"
										value="Community Public"
										checked=""
									/>
								</label>
							</div>
						</div>
						<div
							className="row"
							style={{
								padding: "10px 12px",
								borderRadius: "5px",
								marginBottom: "15px",
								border: "1px solid #ddd",
								marginLeft: "0px",
								marginRight: "0px",
							}}>
							<div className="col-11">
								<span>
									<img src="../images/Announcement.svg" alt="" /> Announcement
								</span>
							</div>
							<div className="col-1">
								<label>
									<input
										type="radio"
										name="audience"
										id="announcement"
										value="Announcement"
									/>
								</label>
							</div>
						</div>
					</div>

					<div
						className="row"
						style={{
							padding: "15px 15px 15px 15px",
							display: "flex",
							alignItems: "center",
							background: "#F1F1F1",
							//   marginLeft: "0px",
							//   marginRight:"0px"
						}}>
						<div className="col-8"></div>
						<div className="col-4">
							<a
								href="javascript:void(0);"
								className="uc-share-opts"
								// onClick="addmedia()"
								style={{
									border: "1px solid #ddd",
									padding: "6px 15px",
									borderRadius: "15px",
									background: "#fff",
									marginRight: "10px",
								}}
								onClick={(e) => {
									setShowAudienceModal(false);
									setShowNewPostModal(true);
								}}>
								<span style={{ color: "#000", paddingLeft: "5px" }}>Back</span>
							</a>
							<span
								className="col-1"
								style={{
									//   border: "1px solid #ddd",
									borderRadius: "15px",
									padding: "4px 15px 6px 20px",
									//   width: "auto",
									paddingLeft: "20px",
									color: "#fff",
									background: "#36BB7D",
								}}>
								Save
							</span>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{/* <Header /> */}
			<NewNavbar />
			<div>
				<div className="row" style={{ marginTop: "20px" }}>
					<div className="col-1"></div>
					<div className="col-2">
						<div class="sidenav">
							<a href="/courses/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af">
								Courses
							</a>
							<a href="/foodsafetyacademy">Discussion Board</a>
							<a href="/digitallibrary/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af">
								Digital Library
							</a>
							<a href="/jobs/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af">
								Placements
							</a>
						</div>
					</div>
					<div class="col-1"></div>

					<div className="col-6" style={{ marginRight: "10px" }}>
						<div
							style={
								{
									// backgroundImage: "url('../images/globalacademybg.jpg')",
									// backgroundRepeat: "no-repeat",
								}
							}>
							<div
								className="container"
								style={{ paddingTop: "30px", paddingBottom: "30px" }}>
								<h5 className="">
									Discussion board serve as a vital tool for enriching the
									online learning experience and promoting a collaborative
									educational environment for the stakeholders in the food
									supply chain. Key goals include:
								</h5>
								<br />
								<div className="row">
									<div class="col" style={{}}>
										<ul className="mx-5" style={{ paddingLeft: "50px" }}>
											<li
												style={{
													listStyle: "numeric",
													fontSize: "20px",
													fontWeight: "500",
												}}>
												<b>Facilitating Communication</b>: Provide a space to
												ask questions, share ideas, and discuss course material.
											</li>

											<li
												style={{
													listStyle: "numeric",
													fontSize: "20px",
													fontWeight: "500",
												}}>
												{" "}
												<b> Encouraging Collaboration</b>: Promote teamwork
												through group discussions and peer feedback, enhancing
												the learning experience.
											</li>

											<li
												style={{
													listStyle: "numeric",
													fontSize: "20px",
													fontWeight: "500",
												}}>
												<b> Building Community</b>: Create a sense of belonging
												and connection among participants, which can improve
												motivation and retention.
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<Addpost userRole={userRole} />

						<div className="mt-3">
							{displayPosts.length > 0 &&
								displaySubjects.map((sub) => {
									return selectedDisplaySubject === sub.name ? (
										<button
											// onClick={() => setSelectedDisplaySubject(sub.name)}
											className="btn btn-warning m-2">
											{sub.name}
										</button>
									) : (
										<button
											onClick={() => setSelectedDisplaySubject(sub.name)}
											className="btn btn-success m-2">
											{sub.name}
										</button>
									);
								})}
						</div>
						{displayPosts.map((post) => (
							<div
								key={post.id}
								className="card row mb-3 mt-3"
								style={{ borderRadius: "15px", border: "none" }}>
								<div
									className="row"
									style={{
										padding: "15px",
									}}>
									<div className="col-12">
										<div className="row">
											{/* <div
												className="col-2"
												style={{
													width: "auto",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													paddingRight: "0px",
												}}>
												{/* <img
													alt="avatar"
													src={post.user.avatar}

													width="40px"
													height="40px"></img> *
												<Avatar src={post.users?.avatar} size="big-avatar" />
											</div> */}
											<div className="col-10">
												<div style={{ fontSize: "15px", fontWeight: "500" }}>
													{/* Dr. Sadiq Sait M.S */}
													{post.users?.fullname}
												</div>
												<div style={{ fontSize: "12px", fontWeight: "500" }}>
													{/* 13 Feb 2024 at 10:32 am */}
													{moment(post.createdAt).fromNow()}
												</div>
											</div>
											<div
												className="col-2"
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "right",
												}}>
												<OverlayTrigger
													trigger="click"
													key="bottom"
													placement="bottom"
													rootClose
													overlay={
														<Popover id={`popover-positioned-bottom`}>
															<Popover.Body>
																<div>
																	<span>
																		<img
																			alt="hidepost"
																			src="../images/Hide_post.svg"></img>
																	</span>
																	&nbsp; Hide Post
																</div>
																<div>
																	<span>
																		<img
																			alt="reportpost"
																			src="../images/Report_post.svg"></img>
																	</span>
																	&nbsp; Report Post
																</div>
															</Popover.Body>
														</Popover>
													}>
													<img
														src="../images/options.svg"
														alt=""
														style={{ cursor: "pointer" }}
													/>
												</OverlayTrigger>
											</div>
										</div>
									</div>
								</div>
								<div
									className="row"
									style={{
										padding: "0px 15px 5px 30px",
										fontSize: "14px",
										fontWeight: "400",
									}}>
									{/* Horticulture of Dasheri Mango */}
									{post.content}
								</div>
								<div className="row">
									{/* <img
										src="https://ulektzmumbai.sgp1.cdn.digitaloceanspaces.com/ulektzConnect/feeds/170961/images/54c3c4fe3401dbb2.jpeg"
										alt=""
									/> */}
									<Carousel images={post.images} id={post.id} />
								</div>
								<div
									className="row"
									style={{
										display: "flex",
										justifyContent: "space-between",
										padding: "10px 10px",
										//   borderBottom: "1px solid #ddd",
									}}>
									<div className="col-9" style={{ fontSize: "13px" }}>
										<img
											src="./images/Like-fill-icon.svg"
											alt=""
											width="20px"
											height="20px"></img>
										<span style={{ paddingLeft: "10px", fontSize: "13px" }}>
											{post.likes.length}
										</span>
									</div>
									<div
										className="col-3"
										style={{
											display: "flex",
											alignItems: "end",
											justifyContent: "end",
											fontSize: "13px",
											fontWeight: "400",
										}}>
										{post.comments.length} comments
									</div>
								</div>
								<hr style={{ marginBottom: "0px" }} />

								<CardFooter post={post} />

								{/* <div className="row" style={{ padding: "15px 15px" }}>
									<div
										className="col-6"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
										}}>
										<img alt="like" src="./images/Liked.svg" width="26px"></img>
										&nbsp;Like
									</div>
									<div
										className="col-6"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "pointer",
										}}>
										<img src="./images/Comments.svg" width="26px"></img>&nbsp;
										Comments
									</div>
								</div> */}
							</div>
						))}
					</div>

					{/* <div
						className="col-2 card"
						style={{
							maxHeight: "calc(100vh - 235px)",
							border: "none",
							borderRadius: "15px",
						}}>
						<div
							style={{
								backgroundColor: "#F4F7F9",
								padding: "15px 15px",
								marginLeft: "-12px",
								marginRight: "-12px",
								fontWeight: "600",
								fontSize: "16px",
								borderTopLeftRadius: "15px",
								borderTopRightRadius: "15px",
							}}>
							Invite my connections
						</div>
						<div className="pt-2">
							<input type="text" placeholder="Serach connections"></input>
						</div>
						<div
							style={{
								overflowY: "auto",
								maxHeight: "calc(100vh - 235px)",
								overflowX: "hidden",
							}}>
							{" "}
							{mostActiveCommunitiesArray.map((e) => {
								return (
									<>
										<div className="row" style={{ padding: "10px 15px" }}>
											<div
												className="col-3"
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}>
												{" "}
												<span>
													<img
														src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/642847167.png"
														alt=""
														width="45px"
														heght="45px"
														// onError="this.onerror=null;this.src='https://www.ulektz.com/assets/img/community/community-def-logo.png'"
													/>
												</span>
											</div>
											<div className="col-9" style={{ paddingLeft: "0px" }}>
												<span
													className="uc-community-cnt"
													// onClick="communityChangeNew(132)"
													style={{ display: "contents" }}>
													<div className="row">
														<div className="col-7">
															<p className="uc-communityLike-in">
																<h5>Yogesh G</h5>
																<span>Krishigap</span>
															</p>
														</div>
														<div
															className="col-5"
															style={{ paddingLeft: "0px" }}>
															<Button
																className="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
																// onClick="joinForm('MTMy',this)"
																style={{
																	background: "#38AB71",
																	border: "none",
																}}>
																Invite
															</Button>
														</div>
													</div>
												</span>
											</div>
											<a
												data-id="132"
												href="javascript:void(0);"
												className="uc-com-join-prof-toggle"></a>
										</div>
										<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
									</>
								);
							})}
						</div>
					</div> */}
				</div>
			</div>

			<div className="bottom">
				<div className="row" style={{ padding: "10px 40px" }}>
					<div className="col-6">
						{/* <img src="../images/Play-Store.svg"></img>&nbsp;&nbsp;&nbsp;
						<img src="../images/App-Store.svg"></img> */}
					</div>
					<div
						className="col-6"
						style={{
							display: "flex",
							justifyContent: "flex-end",
							fontSize: "12px",
							color: "#9e9e9e",
							alignItems: "center",
						}}>
						© Global Academy On Food afety And Sustainability Standards. - All
						Rights Reserved
					</div>
				</div>
			</div>
		</>
	);
};

export default Foodsafetyacademy;
