import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";
const Dr_kapse_bhagwan = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/kapse_bhagwan.jpg"
						alt=""
					/>
					<h4 className="">Dr. Kapse Bhagwan</h4>
					Mango, Sweet Orange & Banana Supply Chain Expert
					<br />
					Former Director, National Institute of Post-Harvest Technology
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Technical Director, Maharashtra Mango Bagayatdar Sangh for
								promotion of quality, production of mango as well as processing
								and expert of Mango from Maharashtra state.
							</li>

							<br />
						</ul>
						<b>Past</b>
						<br />
						<br />
						<p className="fs">
							Former Director, National Institute of Post-Harvest Technology
							(NIPHT) MSAMB, Pune.
						</p>
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Promoted Sweet orange and lime on farmers fields under high tech
								cultivation in Maharashtra State after study visit to South
								Africa. Resulted higher yield by 2 to 3 times.
							</li>
							<li>
								The post harvest technology for Mango, Banana for export through
								CA container by sea to EU, USA and standardized for the first
								time with the help of APEDA.
							</li>{" "}
							<li>
								Established various export oriented Mango orchards at many
								farmers fields with South Africa & Israel Technology.
							</li>{" "}
							<li>Established NIPHT for export of Fruits & Vegetables.</li>
							<li>Developed technology for export of Mango by sea.</li>{" "}
							<li>Developed technology for export of Mango by sea.</li>{" "}
							<li>Hon’ble Advisor to Paithan Mega Food Park, Aurangabad.</li>
							<li>Member of Mango & Cashew Board Study group – Maharashtra.</li>
							<li>
								Attended and presented research papers in International Mango
								symposium held t in Israel, South Africa.
							</li>
							<li>
								Visited various countries for Marketing of Mangos - Hong Kong,
								Singapore, Malaysia, Thailand, Dubai and Canada.
							</li>
							<li> Standardized the scientific method of Mango Ripening. </li>
						</ul>
						<br />
						<b>Awards</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								International watsave award. 2015 in France.
							</li>
							<li>Marathwada Ratna Award</li>
							<li>Pinnaclers of Maharashtra.</li>{" "}
							<li>“INSID" Award for irrigation Management.</li>
						</ul>
						<br />
						<b>Education</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Ph.D. (Horticulture).
							</li>{" "}
							<li>MSC. (Agriculture).</li>{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Dr_kapse_bhagwan;
