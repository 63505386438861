import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { useDispatch, useSelector } from "react-redux";
import { getDataAPI } from "../utils/fetchData";
import LoginRequest from "./loginRequest";
import NewNavbar from "./newNavbar";
const Cart = () => {
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const [itemsArray, setItemsArray] = useState([1, 2, 3, 4, 5]);
	useEffect(() => {
		if (auth.user) {
			getDataAPI(`getcartbyuserid/${auth.user.id}`, auth.token).then((res) => {
				console.log("res cart", res.data);

				setItemsArray(res.data.carts);

				// setMycommunitiesArray(res.data.community);
			});
		}
	}, [auth]);
	return (
		<>
			{/* <Header /> */}
			<NewNavbar />
			{auth.user ? (
				<>
					{" "}
					<div style={{ padding: "20px 60px 20px 120px" }}>
						<div className="row">
							<div
								className="col-8 card"
								style={{
									marginRight: "75px",
									border: "none",
									borderRadiusTop: "15px",
									marginLeft: "10px",
									borderRadius: "15px",
								}}>
								{" "}
								<div
									className="row"
									style={{
										backgroundColor: "#F4F7F9",
										padding: "15px 15px",
										marginLeft: "-12px",
										marginRight: "-12px",
										fontWeight: "600",
										fontSize: "16px",
										borderTopLeftRadius: "15px",
										borderTopRightRadius: "15px",
									}}>
									<div
										className="col-8"
										style={{ display: "flex", alignItems: "center" }}>
										Shopping Cart
									</div>
								</div>
								{itemsArray.map((e) => {
									return (
										<>
											<div className="card" style={{ marginTop: "10px" }}>
												<div className="row" style={{ padding: "10px" }}>
													<div
														className="col-3"
														style={{ paddingRight: "0px" }}>
														<img
															onError={(e) => {
																e.target.src =
																	"../images/common-couse-image.png";
															}}
															alt="img"
															src={e.courseimage}
															style={{ height: "98px", width: "100%" }}></img>
													</div>
													<div className="col-7">
														<div
															style={{ fontSize: "16px", fontWeight: "500" }}>
															{/* Digital Marketing */}
															{e.coursetitle}
														</div>
														<div style={{ fontSize: "13px" }}>
															Publisher: Foodsafety Academy
														</div>
														<br />
														{/* <br /> */}
														<div>
															{" "}
															<Button className="btn-danger">Remove</Button>
														</div>
													</div>
													<div className="col-2">
														<div
															style={{ fontSize: "18px", fontWeight: "500" }}>
															{/* ₹1499.00 */}₹{e.cost}
														</div>
														<div
															style={{ fontSize: "14px", fontWeight: "400" }}>
															(Including Taxes)
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
							</div>
							<div
								className="col-3 card"
								style={{
									//   marginRight: "75px",
									border: "none",
									borderRadiusTop: "15px",
									marginLeft: "10px",
									borderRadius: "15px",
									maxHeight: "300px",
									position: "sticky",
								}}>
								{" "}
								<div
									className="row"
									style={{
										backgroundColor: "#F4F7F9",
										padding: "15px 15px",
										marginLeft: "-12px",
										marginRight: "-12px",
										fontWeight: "600",
										fontSize: "16px",
										borderTopLeftRadius: "15px",
										borderTopRightRadius: "15px",
									}}>
									<div
										className="col-8"
										style={{ display: "flex", alignItems: "center" }}>
										Price Details
									</div>
								</div>
								<div>
									<div>
										<div className="row" style={{ padding: "15px 0px" }}>
											<div className="col-6" style={{ fontSize: "15px" }}>
												Price (18 items)
											</div>
											<div
												className="col-6"
												style={{
													display: "flex",
													justifyContent: "flex-end",
													fontSize: "15px",
												}}>
												₹ 78,162.00
											</div>
										</div>
										<div className="row" style={{ padding: "15px 0px" }}>
											<div className="col-6" style={{ fontSize: "15px" }}>
												GST
											</div>
											<div
												className="col-6"
												style={{
													display: "flex",
													justifyContent: "flex-end",
													fontSize: "15px",
												}}>
												₹ 14,079.00
											</div>
										</div>
										<hr style={{ borderBottom: "1px dotted #ddd" }} />
										<div className="row">
											<div className="col-7" style={{ fontSize: "13px" }}>
												Total Amount (18 items):
											</div>
											<div
												className="col-5"
												style={{
													display: "flex",
													justifyContent: "flex-end",
													fontSize: "16px",
													fontWeight: "500",
												}}>
												₹ 92,241.00
											</div>
										</div>
										<hr style={{ borderBottom: "1px dotted #ddd" }} />
									</div>
									<div style={{ display: "flex", justifyContent: "center" }}>
										<Button
											style={{
												color: "#fff",
												background: "#36BB7D",
												borderRadius: "3px",
											}}>
											Checkout
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<LoginRequest />
			)}
		</>
	);
};

export default Cart;
