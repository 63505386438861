import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const companytraction = () => {
	return (
		<>
			<NewNavbar />
			<div
				style={{
					backgroundImage: "url('../images/globalacademybg.jpg')",
					backgroundRepeat: "no-repeat",
				}}>
				<div
					className="container"
					style={{ paddingTop: "30px", paddingBottom: "30px" }}>
					<h1 className="" style={{ textAlign: "center" }}>
						Company Traction
					</h1>

					<h2 className="">Company formation</h2>
					<div className="row">
						<div class="col" style={{}}>
							<p class="mb-4 fs">
								Global Academy for Food Safety & Sustainability Standards, an
								initiative from Krishigap Digital Solutions Pvt Ltd that was
								established in May 2023. Krishigap has already digitally
								empowered over 200 government agricultural extension officials
								and more than 50 women Agripreneurs. It has also supported
								Farmer's Producer Organizations in the successful implementation
								of India Good Agricultural Practices, launched by the Quality
								Council of India and aligned with the international Standard ISO
								17065.
							</p>
						</div>
					</div>

					<h3 className="">BITS Pilani and Microsoft Startups Founders Hub</h3>
					<div className="row">
						<div class="col" style={{}}>
							<p class="mb-4 fs">
								The Company is proud to announce that it has been selected as an
								evaluated startup under the prestigious{" "}
								<b>Conquest Initiative 2024,</b> a partnership between{" "}
								<b>BITS Pilani</b> and <b>Microsoft Startups Founders Hub.</b>{" "}
								This recognition opens transformative opportunities for our
								platform, the Global Academy on Food Safety and Sustainability
								Standards, by integrating cutting-edge AI solutions. The
								Microsoft Startups Founders Hub accelerates innovation by
								providing startups with:
							</p>
							<b>
								{/* <h5>Program Benefits at No Cost:</h5> */}
								<ul style={{ paddingLeft: "50px" }} className="mx-5 fs">
									<li style={{ listStyle: "disc" }}>
										Up to US$150,000 in Azure Credits.
									</li>
									<li style={{ listStyle: "disc" }}>AI-driven innovation.</li>
									<li style={{ listStyle: "disc" }}>
										Technical advisory and consulting.
									</li>
									<li style={{ listStyle: "disc" }}>
										Free access to essential development tools
									</li>
									<li style={{ listStyle: "disc" }}>
										Go-to-market support with Microsoft.
									</li>
									<li style={{ listStyle: "disc" }}>
										Strengthened tech teams and customer acquisition.
									</li>
									{/* <li style={{ listStyle: "disc" }}>
                Go-to-market support with Microsoft
              </li>
              <li style={{ listStyle: "disc" }}>
                Strengthen and grow our tech team
              </li> */}
								</ul>
							</b>
						</div>
					</div>

					{/* <h3 className="">MOU with Montgomery County</h3>
					<div className="row">
						<div class="col" style={{}}>
							<p class="mb-4 fs">
								KrishiGAP has signed a Memorandum of Understanding (MOU) with
								<b> Montgomery County, Maryland, USA,</b> to leverage the
								Montgomery County International Soft Landing and Economic
								Development Program. This collaboration aims to facilitate
								KrishiGAP’ s expansion efforts in the United States, offering
								strategic support and resources for successful market entry and
								growth
							</p>
						</div>
					</div> */}

					<h3 className="">
						Collaboration with CABI
						{/* <a
            href="https://www.cabi.org"
            target="blank"
            style={{ textDecoration: "none" }}
          >
            {" "}
            (www.cabi.org)
          </a>{" "}
          a not-for-profit international organization */}
					</h3>
					<div className="row">
						<div class="col" style={{}}>
							<p class="mb-4 fs">
								We are thrilled to partner with CABI, a not-for-profit
								organization, to offer their digital courses, including Crop
								Pest Diagnosis and Crop Pest Management Tools, for free on the
								Krishigap platform, enhancing agricultural practices.  
							</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default companytraction;
