import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";
const Dr_r_jagadeeshwar = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/Jagadeeshwar.jpg"
						alt=""
					/>

					<h4 className="">Dr R. Jagadeeshwar</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Past</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Director of Research, Professor Jay Shankar Telangana State
								Agricultural University.
							</li>
							<li>
								He is credited with identification and release of twelve (12)
								blast resistant rice varieties for Telangana State. Worked on
								rice research over 12 years.
							</li>{" "}
							<li>
								Developed eco-friendly disease management package of organic
								rice cultivation and innovative strategies like silver based
								nano-fungicides for management of rice sheath blight.
							</li>{" "}
							<li>
								Rice Research Centre, ARI, Rajendra nagar was adjudged Best
								AICRIP Plant Pathology Centre and Best Overall AICRIP Centre
								during 2012-13 and 2013-14,respectively owing to innovative
								research and coordination by the team effort under his able
								guidance.
							</li>
							<li>
								He worked on important diseases of rice, soybean, groundnut,
								sesame, turmeric and chilly with specialization in plant
								virology, characterizing chili viral diseases of Northern
								Telangana Zone.
							</li>{" "}
							<li>
								He also identified several novel fungicide molecules to combat
								various rice diseases and compatible pesticide combinations
								which also enabled farmers to reduce plant protection costs.
							</li>{" "}
							<li>
								Developed eco-friendly disease management package of organic
								rice cultivation and innovative strategies like silver based
								nano-fungicides for management of rice sheath blight.
							</li>{" "}
						</ul>
						<br />
						<b>Recognitions</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Best Extension Scientistaward.
							</li>
							<li>
								Best Research Scientist award, Fellow of Indian Society of Plant
								Pathologists, Fellow of Plant Protection Association of India
							</li>
							<li>Padmasri I. V. Subba Rao Rythunestham award.</li>{" "}
							<li>Best seed scientist award.</li>
							<li>
								Rice Research Centre, ARI, Rajendranagar was adjudged Best
								AICRIP Plant Pathology Centre and Best Overall AICRIP Centre
								during 2012-13 and 2013-14, respectively owing to innovative
								research and coordination by the team effort under his able
								guidance.
							</li>{" "}
							<li>
								Under his leadership as Director of Research several AICRIP
								schemes received national level awards and labs received
								National Accreditations.
							</li>
						</ul>
						<br />
						<b>Education</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Ph. D. in Plant Pathology.
							</li>{" "}
							<li>M.Sc., Ag.</li>{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Dr_r_jagadeeshwar;
