import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./communitystyles.css";
import NewNavbar from "./newNavbar";
const Members = () => {
	const invitationsArray = [1, 2];
	const addedItemsArray = [1, 2, 3, 4, 6, 7];
	const mostActiveCommunitiesArray = [1, 2, 3, 4, 5, 6, 7, 8];
	const linksArray = [1, 2, 3, 4];
	const [colour, setColour] = useState("#fff");
	const [showUploadModal, setShowUploadModal] = useState(false);
	const handleCloseUploadModal = () => {
		setShowUploadModal(false);
	};
	const handleOpenUploadModal = () => {
		setShowUploadModal(true);
	};

	const buttonClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// console.log("Button clicked");
		setShowUploadModal(true);
	};
	const FirstTab = (props) => {
		return (
			<div>
				<Button
					onClick={buttonClick}
					style={{
						backgroundColor: colour,
						border: "1px solid #ddd",
						borderRadius: "4px",
						marginTop: "-6px",
						color: "#000",
					}}
					onMouseOver={() => {
						setColour("#F4F7F9");
					}}
					onMouseOut={() => {
						setColour("#fff");
					}}>
					Add Member
				</Button>
			</div>
		);
	};
	return (
		<>
			{/* <Header /> */}
			<NewNavbar />
			<Modal
				show={showUploadModal}
				onHide={handleCloseUploadModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								Add Link
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={handleCloseUploadModal}>
							<img src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px" }} />
					<div className="uc-modal-body">
						<div className="uc-detail-btn-wrap uc-share-btn-wrap">
							<div className="row">
								<div className="col-12">
									<input
										type="text"
										className="uc-formControl input"
										id="copytext"
										placeholder="Name/ Title *"
										style={{ backgroundColor: "#fff" }}
									/>
								</div>
								{/* <div className="col-12" style={{ marginTop: "15px" }}>
                  <input
                    type="text"
                    className="uc-formControl input"
                    id="copytext"
                    placeholder="Enter link *"
                    style={{ backgroundColor: "#fff" }}
                  />
                </div> */}
								<div className="uc-modal-body">
									<div className="uc-detail-btn-wrap uc-share-btn-wrap">
										<div className="row">
											<div
												className="col-10"
												style={{ marginTop: "15px", paddingRight: "0px" }}>
												<input
													type="text"
													className="uc-formControl input"
													id="copytext"
													//   defaultValue="ulektz.com/c/krishigap"
													style={{ backgroundColor: "#fff" }}
													placeholder="Enter link *"
												/>
											</div>
											<div
												className="col-2"
												style={{
													paddingLeft: "0px",
													marginTop: "15px",
												}}>
												<a
													href="javascript:void(0)"
													// onClick="copyCommunityUrl()"
													className="copybuttonaddlink">
													<img src="../images/Copyshare_link.svg" alt="copy" />
													<span style={{ color: "#fff", paddingLeft: "4px" }}>
														Copy
													</span>
													{/* <div
                  className="popover__content cpy-tooltip-head"
                  style="display:none"
                >
                  <p>Link Copied</p>
                </div> */}
												</a>
											</div>
										</div>
									</div>
								</div>
								{/* <div
                  className="col-3"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <a
                    href="javascript:void(0)"
                    onClick="copyCommunityUrl()"
                    className="copybutton"
                  >
                    <img src="../images/Copyshare_link.svg" alt="copy" />
                    <span style={{ color: "#fff", paddingLeft: "4px" }}>
                      Copy
                    </span>
                    <div
                  className="popover__content cpy-tooltip-head"
                  style="display:none"
                >
                  <p>Link Copied</p>
                </div>
                  </a>
                </div> */}
							</div>
						</div>
						<div
							className="row"
							style={{
								backgroundColor: "#efefef",
								padding: "10px 10px",
								marginTop: "15px",
							}}>
							<div
								className="col-10"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
								}}>
								<input type="checkbox" id="file" />
								&nbsp;&nbsp;&nbsp; Make announcement post
							</div>

							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}>
										Save
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<div style={{ padding: "20px 60px 20px 120px" }}>
				<div className="row" style={{ marginLeft: "5px", display: "contents" }}>
					<span
						style={{ cursor: "pointer" }}
						onClick={() => (window.location.href = "../foodsafetyacademy")}>
						Dashboard
					</span>{" "}
					&nbsp;&nbsp;&nbsp;/&nbsp;
					<span style={{ fontWeight: "500" }}>Members</span>
				</div>
				<Tabs
					defaultActiveKey="home"
					id="uncontrolled-tab-example"
					// className="mb-3"
					style={{
						backgroundColor: "#fff",
						borderRadius: "15px",
						height: "55px",
						marginTop: "20px",
					}}>
					<Tab eventKey="home" title="Members">
						<div className="">
							<div className="row pt-3">
								<div
									className="col-8 card"
									style={{
										marginRight: "75px",
										border: "none",
										borderRadiusTop: "15px",
										marginLeft: "10px",
										borderRadius: "15px",
									}}>
									<div
										className="row"
										style={{
											backgroundColor: "#F4F7F9",
											padding: "15px 15px",
											marginLeft: "-12px",
											marginRight: "-12px",
											fontWeight: "600",
											fontSize: "16px",
											borderTopLeftRadius: "15px",
											borderTopRightRadius: "15px",
										}}>
										<div
											className="col-8"
											style={{ display: "flex", alignItems: "center" }}>
											Members 4
										</div>
										<div
											className="col-1"
											style={{
												width: "auto",
												paddingRight: "0px",
												paddingLeft: "0px",
											}}>
											{/* <button
												className="ripple-button uc-extra-options-button"
												id="uc-add-clsJRS"
												style={{
													background: "#fff",
													width: "35px",
													height: "35px",
													borderRadius: "4px",
													border: "1px solid #ddd",
												}}>
												<img
													className="uc-extra-options-img"
													src="../images/sort_icon.svg"
													alt=""
												/>
											</button> */}
										</div>
										<div className="col-3">
											<input
												type="text"
												placeholder="Search"
												style={{
													padding: "4px 4px 4px 20px",
													border: "1px solid #ddd",
													borderRadius: "4px",
													backgroundImage: "url(../images/search.svg)",
													backgroundRepeat: "no-repeat",
													backgroundSize: "18px",
													backgroundPosition: "left",
												}}></input>
										</div>
									</div>
									{linksArray.map(() => {
										return (
											<>
												<div style={{ padding: "10px" }}>
													<div
														className="card"
														style={{
															padding: "15px 15px",
														}}>
														<div className="row">
															<div className="col-1">
																{" "}
																<img
																	src="../images/murthy.png"
																	alt=""
																	width="68px"
																	style={{ borderRadius: "50%" }}
																/>
															</div>
															<div
																className="col-9"
																//   style={{
																//     display: "flex",
																//     alignItems: "center",
																//     justifyContent: "flex-start",
																//   }}
															>
																<h3
																	style={{
																		fontSize: "16px",
																		fontWeight: "500",
																		paddingLeft: "20px",
																	}}>
																	Dr Suriya Narayana Moorty&nbsp;&nbsp;
																	<span
																		style={{
																			backgroundColor: "#0076E1",
																			color: "#fff",
																			padding: "1px",
																			borderRadius: "2px",
																			fontSize: "12px",
																		}}>
																		Member
																	</span>
																</h3>
																<div
																	style={{
																		fontSize: "14px",
																		color: "#999",
																		paddingLeft: "20px",
																	}}>
																	Chief Operationg Officer
																</div>
																<div
																	style={{
																		paddingLeft: "20px",
																		fontSize: "14px",
																		display: "flex",
																		alignItems: "center",
																	}}>
																	<img src="../images/location_on.svg"></img>
																	&nbsp; Chennai
																</div>
															</div>

															<div
																className="col-2"
																//   style={{
																//     display: "flex",
																//     alignItems: "center",
																//     justifyContent: "center",
																//   }}
															>
																<img src="../images/options.svg"></img>
																<br />
																<Button
																	style={{
																		background: "#20ce8f",
																		fontSize: "13px",
																		borderRadius: "4px",
																		color: "#fff",
																		border: "1px solid #ddd",
																	}}>
																	Connect
																</Button>
															</div>
														</div>
													</div>
												</div>
											</>
										);
									})}
								</div>
								<div
									className="col-3 card"
									style={{
										// maxHeight: "calc(100vh - 235px)",
										border: "none",
										borderRadius: "15px",
									}}>
									<div
										style={{
											backgroundColor: "#F4F7F9",
											padding: "15px 15px",
											marginLeft: "-12px",
											marginRight: "-12px",
											fontWeight: "600",
											fontSize: "16px",
											borderTopLeftRadius: "15px",
											borderTopRightRadius: "15px",
										}}>
										Invite my connections
									</div>
									<div className="pt-2">
										<input
											type="text"
											placeholder="Serach connections"
											className="form-control"></input>
									</div>
									<div
										style={{
											overflowY: "auto",
											maxHeight: "calc(100vh - 235px)",
											overflowX: "hidden",
										}}>
										{" "}
										{mostActiveCommunitiesArray.map((e) => {
											return (
												<>
													<div className="row" style={{ padding: "10px 15px" }}>
														<div
															className="col-3"
															style={{
																display: "flex",
																alignItems: "center",
																justifyContent: "center",
															}}>
															{" "}
															<span>
																<img
																	src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/642847167.png"
																	alt=""
																	width="45px"
																	heght="45px"
																	// onError="this.onerror=null;this.src='https://www.ulektz.com/assets/img/community/community-def-logo.png'"
																/>
															</span>
														</div>
														<div
															className="col-9"
															style={{ paddingLeft: "0px" }}>
															<span
																className="uc-community-cnt"
																// onClick="communityChangeNew(132)"
																style={{ display: "contents" }}>
																<div className="row">
																	<div className="col-7">
																		<p className="uc-communityLike-in">
																			<h5>Yogesh G</h5>
																			<span>Krishigap</span>
																		</p>
																	</div>
																	<div
																		className="col-5"
																		style={{ paddingLeft: "0px" }}>
																		<Button
																			className="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
																			// onClick="joinForm('MTMy',this)"
																			style={{
																				background: "#20ce8f",
																				border: "none",
																			}}>
																			Invite
																		</Button>
																	</div>
																</div>
															</span>
														</div>
														<a
															data-id="132"
															href="javascript:void(0);"
															className="uc-com-join-prof-toggle"></a>
													</div>
													<hr
														style={{ marginTop: "0px", marginBottom: "0px" }}
													/>
												</>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</Tab>
					<Tab eventKey="Files & Docs" title="Admin & Moderator">
						<div className="">
							<div className="row pt-3">
								<div
									className="col-9 card"
									style={{
										marginRight: "75px",
										border: "none",
										borderRadiusTop: "15px",
										marginLeft: "10px",
										borderRadius: "15px",
									}}>
									<div
										className="row"
										style={{
											backgroundColor: "#F4F7F9",
											padding: "15px 15px",
											marginLeft: "-12px",
											marginRight: "-12px",
											fontWeight: "600",
											fontSize: "16px",
											borderTopLeftRadius: "15px",
											borderTopRightRadius: "15px",
										}}>
										<div
											className="col-8"
											style={{ display: "flex", alignItems: "center" }}>
											Admin & Moderator 4
										</div>
										<div
											className="col-1"
											style={{
												width: "auto",
												paddingRight: "0px",
												paddingLeft: "0px",
											}}>
											{/* <button
												className="ripple-button uc-extra-options-button"
												id="uc-add-clsJRS"
												style={{
													background: "#fff",
													width: "35px",
													height: "35px",
													borderRadius: "4px",
													border: "1px solid #ddd",
												}}>
												<img
													className="uc-extra-options-img"
													src="../images/sort_icon.svg"
													alt=""
												/>
											</button> */}
										</div>
										<div className="col-3">
											<input
												type="text"
												placeholder="Search"
												style={{
													padding: "4px 4px 4px 20px",
													border: "1px solid #ddd",
													borderRadius: "4px",
													backgroundImage: "url(../images/search.svg)",
													backgroundRepeat: "no-repeat",
													backgroundSize: "18px",
													backgroundPosition: "left",
												}}></input>
										</div>
									</div>
									{linksArray.map(() => {
										return (
											<>
												<div style={{ padding: "10px" }}>
													<div
														className="card"
														style={{
															padding: "15px 15px",
														}}>
														<div className="row">
															<div className="col-1">
																{" "}
																<img
																	src="../images/murthy.png"
																	alt=""
																	width="68px"
																	style={{ borderRadius: "50%" }}
																/>
															</div>
															<div
																className="col-9"
																//   style={{
																//     display: "flex",
																//     alignItems: "center",
																//     justifyContent: "flex-start",
																//   }}
															>
																<h3
																	style={{
																		fontSize: "16px",
																		fontWeight: "500",
																		paddingLeft: "20px",
																	}}>
																	Dr Suriya Narayana Moorty&nbsp;&nbsp;
																	<span
																		style={{
																			backgroundColor: "#0076E1",
																			color: "#fff",
																			padding: "1px",
																			borderRadius: "2px",
																			fontSize: "12px",
																		}}>
																		Member
																	</span>
																</h3>
																<div
																	style={{
																		fontSize: "14px",
																		color: "#999",
																		paddingLeft: "20px",
																	}}>
																	Chief Operationg Officer
																</div>
																<div
																	style={{
																		paddingLeft: "20px",
																		fontSize: "14px",
																		display: "flex",
																		alignItems: "center",
																	}}>
																	<img src="../images/location_on.svg"></img>
																	&nbsp; Chennai
																</div>
															</div>

															<div
																className="col-2"
																//   style={{
																//     display: "flex",
																//     alignItems: "center",
																//     justifyContent: "center",
																//   }}
															>
																<img src="../images/options.svg"></img>
																<br />
																<Button
																	style={{
																		background: "#fff",

																		fontSize: "13px",
																		borderRadius: "4px",
																		color: "#000",
																		border: "1px solid #ddd",
																	}}>
																	Message
																</Button>
															</div>
														</div>
													</div>
												</div>
											</>
										);
									})}
								</div>
							</div>
						</div>
					</Tab>
					<Tab eventKey="Videos" title="Alumini/Guests">
						<div className="">
							<div className="row pt-3">
								<div
									className="col-9 card"
									style={{
										marginRight: "75px",
										border: "none",
										borderRadiusTop: "15px",
										marginLeft: "10px",
										borderRadius: "15px",
									}}>
									<div
										className="row"
										style={{
											backgroundColor: "#F4F7F9",
											padding: "15px 15px",
											marginLeft: "-12px",
											marginRight: "-12px",
											fontWeight: "600",
											fontSize: "16px",
											borderTopLeftRadius: "15px",
											borderTopRightRadius: "15px",
										}}>
										<div
											className="col-8"
											style={{ display: "flex", alignItems: "center" }}>
											Alumini/Guests 4
										</div>
										<div
											className="col-1"
											style={{
												width: "auto",
												paddingRight: "0px",
												paddingLeft: "0px",
											}}>
											{/* <button
												className="ripple-button uc-extra-options-button"
												id="uc-add-clsJRS"
												style={{
													background: "#fff",
													width: "35px",
													height: "35px",
													borderRadius: "4px",
													border: "1px solid #ddd",
												}}>
												<img
													className="uc-extra-options-img"
													src="../images/sort_icon.svg"
													alt=""
												/>
											</button> */}
										</div>
										<div className="col-3">
											<input
												type="text"
												placeholder="Search"
												style={{
													padding: "4px 4px 4px 20px",
													border: "1px solid #ddd",
													borderRadius: "4px",
													backgroundImage: "url(../images/search.svg)",
													backgroundRepeat: "no-repeat",
													backgroundSize: "18px",
													backgroundPosition: "left",
												}}></input>
										</div>
									</div>
									{linksArray.map(() => {
										return (
											<>
												<div style={{ padding: "10px" }}>
													<div
														className="card"
														style={{
															padding: "15px 15px",
														}}>
														<div className="row">
															<div className="col-1">
																{" "}
																<img
																	src="../images/murthy.png"
																	alt=""
																	width="68px"
																	style={{ borderRadius: "50%" }}
																/>
															</div>
															<div
																className="col-9"
																//   style={{
																//     display: "flex",
																//     alignItems: "center",
																//     justifyContent: "flex-start",
																//   }}
															>
																<h3
																	style={{
																		fontSize: "16px",
																		fontWeight: "500",
																		paddingLeft: "20px",
																	}}>
																	Dr Suriya Narayana Moorty&nbsp;&nbsp;
																	<span
																		style={{
																			backgroundColor: "#0076E1",
																			color: "#fff",
																			padding: "1px",
																			borderRadius: "2px",
																			fontSize: "12px",
																		}}>
																		Member
																	</span>
																</h3>
																<div
																	style={{
																		fontSize: "14px",
																		color: "#999",
																		paddingLeft: "20px",
																	}}>
																	Chief Operationg Officer
																</div>
																<div
																	style={{
																		paddingLeft: "20px",
																		fontSize: "14px",
																		display: "flex",
																		alignItems: "center",
																	}}>
																	<img src="../images/location_on.svg"></img>
																	&nbsp; Chennai
																</div>
															</div>

															<div
																className="col-2"
																//   style={{
																//     display: "flex",
																//     alignItems: "center",
																//     justifyContent: "center",
																//   }}
															>
																<Button
																	style={{
																		background: "#36BB7D",

																		fontSize: "13px",
																		borderRadius: "4px",
																		color: "#fff",
																		border: "1px solid #ddd",
																	}}>
																	Connect
																</Button>
															</div>
														</div>
													</div>
												</div>
											</>
										);
									})}
								</div>
								{/* <div
                  className="col-3 card"
                  style={{
                    // maxHeight: "calc(100vh - 235px)",
                    border: "none",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F4F7F9",
                      padding: "15px 15px",
                      marginLeft: "-12px",
                      marginRight: "-12px",
                      fontWeight: "600",
                      fontSize: "16px",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                    }}
                  >
                    Invite my connections
                  </div>
                  <div className="pt-2">
                    <input
                      type="text"
                      placeholder="Serach connections"
                      className="form-control"
                    ></input>
                  </div>
                  <div
                    style={{
                      overflowY: "auto",
                      maxHeight: "calc(100vh - 235px)",
                      overflowX: "hidden",
                    }}
                  >
                    {" "}
                    {mostActiveCommunitiesArray.map((e) => {
                      return (
                        <>
                          <div className="row" style={{ padding: "10px 15px" }}>
                            <div
                              className="col-3"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <span onClick="communityChangeNew(132)">
                                <img
                                  src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/642847167.png"
                                  alt=""
                                  width="45px"
                                  heght="45px"
                                  // onerror="this.onerror=null;this.src='https://www.ulektz.com/assets/img/community/community-def-logo.png'"
                                />
                              </span>
                            </div>
                            <div
                              className="col-9"
                              style={{ paddingLeft: "0px" }}
                            >
                              <span
                                className="uc-community-cnt"
                                onClick="communityChangeNew(132)"
                                style={{ display: "contents" }}
                              >
                                <div className="row">
                                  <div className="col-7">
                                    <p className="uc-communityLike-in">
                                      <h5>Yogesh G</h5>
                                      <span>Krishigap</span>
                                    </p>
                                  </div>
                                  <div
                                    className="col-5"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <Button
                                      className="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
                                      // onClick="joinForm('MTMy',this)"
                                      style={{
                                        background: "#38AB71",
                                        border: "none",
                                      }}
                                    >
                                      Invite
                                    </Button>
                                  </div>
                                </div>
                              </span>
                            </div>
                            <a
                              data-id="132"
                              href="javascript:void(0);"
                              className="uc-com-join-prof-toggle"
                            ></a>
                          </div>
                          <hr
                            style={{ marginTop: "0px", marginBottom: "0px" }}
                          />
                        </>
                      );
                    })}
                  </div>
                </div> */}
							</div>
						</div>
					</Tab>
					<Tab eventKey="contact" title="Invitations">
						<Tabs
							defaultActiveKey="Invitations sent to Connections"
							id="uncontrolled-tab-example"
							style={{
								backgroundColor: "#F4F7F9",
								borderBottomLeftRadius: "15px",
								borderBottomRightRadius: "15px",
							}}
							className="newtabs">
							<Tab
								eventKey="Invitations sent to Connections"
								title="Invitations sent to Connections">
								{" "}
								<div className="pt-3">
									<div className="row" style={{}}>
										<div
											className="col-8 card"
											style={{
												marginRight: "75px",
												marginLeft: "10px",
												border: "none",
												borderRadius: "15px",
											}}>
											<div
												className="row"
												style={{
													backgroundColor: "#F4F7F9",
													padding: "15px 15px",
													marginLeft: "-12px",
													marginRight: "-12px",
													fontWeight: "600",
													fontSize: "16px",
													borderTopLeftRadius: "15px",
													borderTopRightRadius: "15px",
												}}>
												<div
													className="col-8"
													style={{ display: "flex", alignItems: "center" }}>
													Invitations sent to Connections
												</div>

												<div
													className="col-1"
													style={{
														width: "auto",
														paddingRight: "0px",
														paddingLeft: "0px",
													}}>
													{/* <button
														className="ripple-button uc-extra-options-button"
														id="uc-add-clsJRS"
														style={{
															background: "#fff",
															width: "35px",
															height: "35px",
															borderRadius: "4px",
															border: "1px solid #ddd",
														}}>
														<img
															className="uc-extra-options-img"
															src="../images/sort_icon.svg"
															alt=""
														/>
													</button> */}
												</div>

												<div className="col-3" style={{ textAlign: "end" }}>
													<input
														type="text"
														placeholder="Search"
														style={{
															padding: "4px 4px 4px 20px",
															border: "1px solid #ddd",
															borderRadius: "4px",
															backgroundImage: "url(../images/search.svg)",
															backgroundRepeat: "no-repeat",
															backgroundSize: "18px",
															backgroundPosition: "left",
														}}></input>
												</div>
											</div>
											{invitationsArray.length === 0 && (
												<div>
													<div className="uc-discover-ele-div">
														<div
															className=""
															style={{
																display: "flex",
																alignItems: "center",
																justifyContent: "center",
															}}>
															<img src="../images/nrf.svg" height="200px" />
														</div>
														<div className="uc-discover-ele-cnt-div">
															<h3
																style={{
																	fontSize: "28px",
																	paddingTop: "15px",
																	fontWeight: "300",
																}}>
																No invitation found
															</h3>
														</div>
													</div>
												</div>
											)}
											{invitationsArray.map(() => {
												return (
													<>
														<div style={{ padding: "10px" }}>
															<div
																className="card"
																style={{
																	padding: "15px 15px",
																}}>
																<div className="row">
																	<div className="col-1">
																		{" "}
																		<img
																			src="../images/murthy.png"
																			alt=""
																			width="68px"
																			style={{ borderRadius: "50%" }}
																		/>
																	</div>
																	<div
																		className="col-9"
																		//   style={{
																		//     display: "flex",
																		//     alignItems: "center",
																		//     justifyContent: "flex-start",
																		//   }}
																	>
																		<h3
																			style={{
																				fontSize: "16px",
																				fontWeight: "500",
																				paddingLeft: "20px",
																			}}>
																			Dr Suriya Narayana Moorty&nbsp;&nbsp;
																			{/* <span
                                        style={{
                                          backgroundColor: "#0076E1",
                                          color: "#fff",
                                          padding: "1px",
                                          borderRadius: "2px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Member
                                      </span> */}
																		</h3>
																		<div
																			style={{
																				fontSize: "14px",
																				color: "#999",
																				paddingLeft: "20px",
																			}}>
																			Chief Operationg Officer
																		</div>
																		<div
																			style={{
																				paddingLeft: "20px",
																				fontSize: "14px",
																				display: "flex",
																				alignItems: "center",
																			}}>
																			<img src="../images/location_on.svg"></img>
																			&nbsp; Chennai
																		</div>
																	</div>

																	<div
																		className="col-2"
																		//   style={{
																		//     display: "flex",
																		//     alignItems: "center",
																		//     justifyContent: "center",
																		//   }}
																	>
																		<img src="../images/options.svg"></img>
																		<br />
																		<Button
																			style={{
																				background: "#b4b5b5",
																				fontSize: "13px",
																				borderRadius: "4px",
																				color: "#fff",
																				border: "1px solid #ddd",
																			}}>
																			Pending
																		</Button>
																	</div>
																</div>
															</div>
														</div>
													</>
												);
											})}
										</div>

										<div
											className="col-3 card"
											style={{
												// maxHeight: "calc(100vh - 235px)",
												border: "none",
												borderRadius: "15px",
											}}>
											<div
												style={{
													backgroundColor: "#F4F7F9",
													padding: "15px 15px",
													marginLeft: "-12px",
													marginRight: "-12px",
													fontWeight: "600",
													fontSize: "16px",
													borderTopLeftRadius: "15px",
													borderTopRightRadius: "15px",
												}}>
												Invite my connections
											</div>
											<div className="pt-2">
												<input
													type="text"
													placeholder="Serach connections"
													className="form-control"></input>
											</div>
											<div
												style={{
													overflowY: "auto",
													maxHeight: "calc(100vh - 235px)",
													overflowX: "hidden",
												}}>
												{" "}
												{mostActiveCommunitiesArray.map((e) => {
													return (
														<>
															<div
																className="row"
																style={{ padding: "10px 15px" }}>
																<div
																	className="col-3"
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																	}}>
																	{" "}
																	<span>
																		<img
																			src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/642847167.png"
																			alt=""
																			width="45px"
																			heght="45px"
																			// onError="this.onerror=null;this.src='https://www.ulektz.com/assets/img/community/community-def-logo.png'"
																		/>
																	</span>
																</div>
																<div
																	className="col-9"
																	style={{ paddingLeft: "0px" }}>
																	<span
																		className="uc-community-cnt"
																		// onClick="communityChangeNew(132)"
																		style={{ display: "contents" }}>
																		<div className="row">
																			<div className="col-7">
																				<p className="uc-communityLike-in">
																					<h5>Yogesh G</h5>
																					<span>Krishigap</span>
																				</p>
																			</div>
																			<div
																				className="col-5"
																				style={{ paddingLeft: "0px" }}>
																				<Button
																					className="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
																					// onClick="joinForm('MTMy',this)"
																					style={{
																						background: "#38AB71",
																						border: "none",
																					}}>
																					Invite
																				</Button>
																			</div>
																		</div>
																	</span>
																</div>
																<a
																	data-id="132"
																	href="javascript:void(0);"
																	className="uc-com-join-prof-toggle"></a>
															</div>
															<hr
																style={{
																	marginTop: "0px",
																	marginBottom: "0px",
																}}
															/>
														</>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							</Tab>
							<Tab
								eventKey="Join Request Sent"
								title="Invitations sent to Contacts">
								{" "}
								<div className="">
									<div className="row" style={{ paddingTop: "15px" }}>
										<div
											className="col-8 card"
											style={{
												marginRight: "75px",
												marginLeft: "10px",
												border: "none",
												borderRadius: "15px",
											}}>
											<div
												className="row"
												style={{
													backgroundColor: "#F4F7F9",
													padding: "15px 15px",
													marginLeft: "-12px",
													marginRight: "-12px",
													fontWeight: "600",
													fontSize: "16px",
													borderTopLeftRadius: "15px",
													borderTopRightRadius: "15px",
												}}>
												<div
													className="col-8"
													style={{ display: "flex", alignItems: "center" }}>
													Invitations sent to Contacts
												</div>
											</div>

											<div>
												<div className="uc-discover-ele-div">
													<div
														className=""
														style={{
															display: "flex",
															alignItems: "center",
															justifyContent: "center",
														}}>
														<img src="../images/nrf.svg" height="200px" />
													</div>
													<div className="uc-discover-ele-cnt-div">
														<h3
															style={{
																fontSize: "28px",
																paddingTop: "15px",
																fontWeight: "300",
															}}>
															No invitation found
														</h3>
													</div>
												</div>
											</div>
										</div>

										<div
											className="col-3 card"
											style={{ padding: "35px 15px", borderRadius: "10px" }}>
											<div
												style={{
													fontSize: "18px",
													fontWeight: "400",
													marginBottom: "15px",
												}}>
												Directly invite your email contacts to connect on
												Community
											</div>
											<div style={{ marginBottom: "18px" }}>
												{" "}
												<textarea
													className="form-control"
													placeholder="Enter email addresses here,separated by comma"></textarea>
											</div>

											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}>
												<Button
													style={{
														backgroundColor: "#36BB7D",
														borderRadius: "4px",
														width: "fix-content",
													}}>
													Invite
												</Button>
											</div>
										</div>
									</div>
								</div>
							</Tab>
						</Tabs>
					</Tab>
					<Tab title={<FirstTab />}></Tab>
				</Tabs>
			</div>
		</>
	);
};

export default Members;
