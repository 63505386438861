import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";
import "./communitystyles.css";
import LoginRequest from "./loginRequest";
import ProfileBanner from "./profileBanner";
import NewNavbar from "./newNavbar";
const Mycources = () => {
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	// const communitiesArray = [1, 2];
	const [value, setValue] = useState("");
	const [communitiesArray, setCommunitiesArray] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		if (auth.user) {
			getDataAPI(`getSavedCourses/${auth.user.id}`, auth.token).then((res) => {
				console.log("res my courses", res.data);

				setCommunitiesArray(res.data.courses);
			});
		}
	}, [auth]);

	const handleClick = async (
		file,
		image,
		title,
		question,
		id,
		admin,
		details
	) => {
		// console.log(file, image, title);
		// localStorage.setItem("data", "");
		// console.log(localStorage.getItem("data"));
		if (details === 0) {
			try {
				const res = await postDataAPI(
					"createCourseDetails",
					{
						userid: auth.user.id,
						courseid: id,
						adminid: admin,
					},
					auth.token
				);
				console.log("res", res);
				if (res.status === 200) {
					// window.location.reload();
				}
			} catch (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { error: err.response.data.msg },
				});
			}
		}

		// if (localStorage.getItem("data") === "") {
		// 	let dataToSend = {
		// 		id: id,
		// 		files: file,
		// 		courseimage: image,
		// 		coursetitle: title,
		// 		questionslist: question,
		// 	};
		// 	let temp = { state: dataToSend };
		// 	console.log(dataToSend);
		// 	localStorage.setItem("data", JSON.stringify(dataToSend));
		window.open("/pdfreader/" + id, "_blank");
		// }
	};

	return (
		<>
			{/* <Header /> */}
			<NewNavbar />
			{auth.user ? (
				<>
					<ProfileBanner />

					<div className="row pt-3 justify-content-around">
						{/* <div className="col-1"></div> */}
						<div
							className="col-8 card "
							style={{
								marginRight: "5px",
								border: "none",
								borderRadiusTop: "15px",
								//   marginLeft: "10px",
								borderRadius: "15px",
							}}>
							<div
								className="row"
								style={{
									backgroundColor: "#F4F7F9",
									padding: "15px 15px",
									marginLeft: "-12px",
									marginRight: "-12px",
									fontWeight: "600",
									fontSize: "16px",
									borderTopLeftRadius: "15px",
									borderTopRightRadius: "15px",
								}}>
								<div
									className="col-8"
									style={{ display: "flex", alignItems: "center" }}>
									My Courses
								</div>
								<div
									className="col-1"
									style={{
										width: "auto",
										paddingRight: "0px",
										paddingLeft: "0px",
									}}>
									{/* <button
										className="ripple-button uc-extra-options-button"
										id="uc-add-clsJRS"
										style={{
											background: "#fff",
											width: "35px",
											height: "35px",
											borderRadius: "4px",
											border: "1px solid #ddd",
										}}>
										<img
											className="uc-extra-options-img"
											src="./images/sort_icon.svg"
											alt=""
										/>
									</button> */}
								</div>
								<div className="col-3 uc-searchBar">
									<input
										type="text"
										placeholder="Search"
										onChange={(e) => setSearchValue(e.target.value)}
										className="uc-formControl1"></input>
								</div>
							</div>
							<div>
								{communitiesArray.length === 0 ? (
									<div
										className=""
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											padding: "30px 30px",
										}}>
										<ul className="uc-res-li-1 view-as uc-my-book-list uc-grid">
											<div className="uc-NRF">
												<img
													src="../images/Search-no-results-found.svg"
													alt=""
												/>
												<h5
													style={{
														fontSize: "28px",
														fontWeight: "300",
														color: "#62DDB2",
													}}>
													No Courses are added to my courses
												</h5>
												{/* <p
														style={{
															fontSize: "15px",
															fontWeight: "400",
															textAlign: "center",
														}}>
														Contact your Community Admin / Moderator for more
														details
													</p> */}
											</div>
										</ul>
									</div>
								) : (
									<div className="row">
										{communitiesArray.map((e, index) => {
											return (
												<div
													key={e.id}
													className="col-12"
													style={{
														padding: "10px 10px 10px 10px",
													}}>
													<div
														className="card"
														style={{
															padding: "5px 5px 5px 5px",
															// visibility: showCard ? "visible" : "hidden",
														}}>
														<div className="row">
															<div className="col-3">
																<img
																	alt=""
																	onError={(e) => {
																		e.target.src =
																			"../images/common-couse-image.png";
																	}}
																	src={e.courseimage}
																	style={{ width: "100%" }}></img>
															</div>
															<div className="col-6">
																{" "}
																<div
																	style={{
																		fontSize: "15px",
																		fontWeight: "500",
																		minHeight: "36px",
																	}}>
																	{e.coursetitle}
																</div>
																{/* <div style={{ fontSize: "12px" }}>
																	Instructor&nbsp;&nbsp;
																	<span
																		style={{
																			textDecoration: "underline",
																		}}>
																		{e.instructorinformation}
																	</span>{" "}
																</div> */}
																{e.language.name !== "English" && (
																	<div style={{ fontSize: "12px" }}>
																		Language&nbsp;&nbsp;
																		<span
																			style={{
																				textDecoration: "underline",
																			}}>
																			{e.language.name}
																		</span>{" "}
																	</div>
																)}
																{/* <div style={{ fontSize: "12px" }}>
																					Certification By&nbsp;&nbsp;
																					<span
																						style={{
																							textDecoration: "underline",
																						}}>
																						krishigap
																					</span>{" "}
																				</div> */}
																{/* <div style={{ fontSize: "12px" }}>
																					Target Audience&nbsp;&nbsp;
																					<span
																						style={{
																							textDecoration: "underline",
																						}}>
																						{e.targetaudience}
																					</span>{" "}
																				</div> */}
																<div style={{ fontSize: "12px" }}>
																	Duration&nbsp;&nbsp;
																	<span>{e.duration} Hrs</span>
																</div>
																<div
																	style={{
																		fontSize: "15px",
																		fontWeight: "500",
																		paddingTop: "13px",
																		paddingBottom: "13px",
																	}}>
																	{e.cost === "" ? "Free" : "₹" + e.cost}
																</div>
															</div>
															<div className="col-3">
																<br />
																<br />
																<br />
																{e.courses_details > 0 && (
																	<div
																		className="lightgreen"
																		style={{
																			textAlign: "center",
																		}}>
																		In Progress
																	</div>
																)}

																<div
																	style={{
																		paddingTop: "10px",
																		textAlign: "center",
																	}}>
																	{/* <Link
                                            to={{
                                              pathname: "/pdfreader",
                                              state: {
                                                files: e.files,
                                                courseimage: e.courseimage,
                                                coursetitle: e.coursetitle,
                                              }, // your data array of objects
                                            }}
                                            // target="_blank"
                                          > */}
																	<Button
																		style={{
																			background: "#F96342",
																			border: "none",
																			width: "150px",
																		}}
																		onClick={() => {
																			handleClick(
																				e.files,
																				e.courseimage,
																				e.coursetitle,
																				e.questionlist,
																				e.id,
																				e.admin,
																				e.courses_details
																			);
																		}}>
																		{e.courses_details > 0 ? "Resume" : "Start"}
																	</Button>
																	{/* </Link> */}
																</div>
															</div>
														</div>

														{/* <Button
                                  style={{
                                    background: "#36BB7D",
                                    fontSize: "13px",
                                    borderRadius: "4px",
                                    border: "none",
                                  }}
                                  onClick={(item) => handleViewChange(e)}
                                >
                                  <img src="../images/Go-to-My-Courses-icon.svg"></img>{" "}
                                  Go to My Cources
                                </Button> */}
													</div>
												</div>
											);
										})}
									</div>
								)}
							</div>
						</div>
					</div>
				</>
			) : (
				<LoginRequest />
			)}
		</>
	);
};
export default Mycources;
