import React from "react";
// import { Navbar } from "react-bootstrap";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";

const Courselanding = () => {
	return (
		<>
			<NewNavbar />
			<div style={{ paddingBottom: "100px", background: "#29922E" }}>
				<div
					className="row"
					style={{
						paddingTop: "30px",
						paddingLeft: "30px",
						paddingBottom: "100px",
					}}>
					<div className="col">
						<div
							style={{
								fontFamily: "Manrope",
								fontSize: "52px",
								fontWeight: "600",
								lineHeight: "52px",
								letterSpacing: "-0.02em",
								color: "#ffffff",
							}}>
							Courses
						</div>
						<div className="row">
							<div
								style={{
									fontFamily: "Manrope",
									fontSize: "14px",
									fontWeight: "500",
									lineHeight: "14px",
									letterSpacing: "-0.02em",
									color: "#ffffff",
								}}>
								Skill development of farmers organizations and food processors
								with ease and scale of implementation of food safety and
								sustainable global standards.
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div
						className="col"
						style={{
							display: "flex",
							justifyContent: "space-between",
							paddingLeft: "50px",
							paddingRight: "50px",
						}}>
						<div
							style={{
								width: "400px",
								height: "70px",
								borderRadius: "100px",
								background: "#0d3e0d",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<div
								style={{
									fontFamily: "Manrope",
									fontSize: "20px",
									//   fontWeight: "600",
									lineHeight: "24px",
									letterSpacing: "-0.02em",
									textAlign: "right",
									color: "#ffffff",
								}}>
								On Farm Production Standards
							</div>
						</div>
						<div
							style={{
								width: "300px",
								height: "70px",
								borderRadius: "100px",
								display: "flex",
								justifyContent: "center",

								border: "1px solid #ffffff",
								alignItems: "center",
							}}>
							<div
								style={{
									fontFamily: "Manrope",
									fontSize: "20px",
									//   fontWeight: "600",
									lineHeight: "24px",
									letterSpacing: "-0.02em",
									textAlign: "center",
									color: "#ffffff",
								}}>
								Post Harvest Standards
							</div>
						</div>
						<div
							style={{
								width: "500px",
								height: "70px",
								borderRadius: "100px",
								background: "#0d3e0d",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<div
								style={{
									fontFamily: "Manrope",
									fontSize: "20px",
									//   fontWeight: "600",
									lineHeight: "24px",
									letterSpacing: "-0.02em",
									textAlign: "left",
									color: "#ffffff",
								}}>
								Sustainable Standards
							</div>
						</div>
					</div>
				</div>
				<div className="row" style={{ paddingTop: "80px" }}>
					<div
						className="col"
						style={{
							display: "flex",
							justifyContent: "space-between",
							paddingLeft: "50px",
							paddingRight: "50px",
						}}>
						<div
							style={{
								width: "500px",
								height: "70px",
								borderRadius: "100px",
								// background: "#0d3e0d",
								border: "1px solid #ffffff",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<div
								style={{
									fontFamily: "Manrope",
									fontSize: "20px",
									//   fontWeight: "600",
									lineHeight: "24px",
									letterSpacing: "-0.02em",
									textAlign: "right",
									color: "#ffffff",
								}}>
								Market Linkage For Certified Organizations
							</div>
						</div>
						<div
							style={{
								width: "280px",
								height: "70px",
								borderRadius: "100px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								background: "#0d3e0d",

								// border: "1px solid #ffffff",
							}}>
							<div
								style={{
									fontFamily: "Manrope",
									fontSize: "20px",
									//   fontWeight: "600",
									lineHeight: "24px",
									letterSpacing: "-0.02em",
									textAlign: "center",
									color: "#ffffff",
								}}>
								Export Documentation
							</div>
						</div>
						<div
							style={{
								width: "400px",
								height: "70px",
								borderRadius: "100px",
								// background: "#0d3e0d",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								border: "1px solid #ffffff",
							}}>
							<div
								style={{
									fontFamily: "Manrope",
									fontSize: "20px",
									//   fontWeight: "600",
									lineHeight: "24px",
									letterSpacing: "-0.02em",
									textAlign: "left",
									color: "#ffffff",
								}}>
								Life on Land
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default Courselanding;
