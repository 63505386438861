import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Shashi_sareen = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/anju_nayyar.png"
						alt=""
					/>

					<h4 className="">Anju Nayyar</h4>
					<p className="fs">
						Senior Advisor – Business Development & Alliances, YARA
						International
					</p>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<p className="fs">
							Anju Nayyar is currently in a senior advisory role cutting across
							public affairs and business development for the Growth and
							Commercial Division at Yara in the Asia-Africa region, where she
							has developed a passion for emerging markets, farming, and digital
							solutions. She leads relationships and business development across
							these regions positioning Yara for success with customers and
							cross-sector stakeholders in Asia and Africa.
						</p>

						<b>Past</b>
						<br />

						<p className="fs">
							Anju Nayyar is a versatile senior executive leader and a board
							member specializing in public affairs, high-value sales, and
							business development, creating win-win scenarios with measurable
							social impact. With over 23 years of leadership experience, Anju's
							work spans agriculture, healthcare and nutrition, technology,
							sustainability, finance, and media sectors. She is registered with
							the MCCIA. Talks about , CSR, ESG and statutory compliance.
						</p>
						<br />
						<b>SIGNIFICANT ACHIEVEMENTS:</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Grew to a profit Centre head while developing strategic
								alliances, tech transfer strategy, and partnering with countries
								to participate in technical trade shows.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Worked on 4 agtech applications from conception to
								implementation.
								<ul className="mx-5">
									<li>Farmcare</li>
									<li>Farmgo</li>
									<li>Farm Forward</li>
									<li>Yara Bodega</li>
								</ul>
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Through these applications aere able to connect 13 million
								farmers and actually enhance the farmer’s income.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								She has been a pioneer in setting up systems to digitize the KCC
								and has tried to impress upon the farming community the
								significance and need for a healthy credit.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Has stood up for the unrepresented and collaborated with 8 banks
								to make this possible on the ground.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Has addressed and brought about several strategic alliances to
								make sure the message of collaboration is spread and thus
								bringing about increase in yield.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								She is currently working on several collaborations to digitize
								the supply chain.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Explained to the stakeholders the value of FPOS and how can we
								leverage this Network.
							</li>{" "}
						</ul>
						<br />
						<b>INTERNATIONAL EXPOSURE:</b>
						<br />

						<p className="fs">
							Has worked and collaborated with 16 different countries through
							the Chem -Tech Foundation on Technology transfer for Enviro and
							clean Tech. Has worked in Singapore, Malaysia, Vietnam, Germany
							and the US in different ca[pacities.
						</p>
						<br />

						<b>PUBLICATIONS:</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								20 research publications are in diffrent journals like The Hindu
								Business Line, The National News (UAE) and The Pioneer,
								Entrepreneur India, Your story etc.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Several research publications and technical /Extension
								bulletins, brochure and videos for the benefit of farmers in
								English and Hindi.
							</li>{" "}
						</ul>
						<br />
						<b>EDUCATION</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								University of Cambridge, Sustainability Leadership (Pursuing)
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								<b>University of Zambia,</b> Master of Business Administration,
								Marketing
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								<b>University of Zambia,</b> BSC, Botany
							</li>{" "}
						</ul>
						<br />
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Shashi_sareen;
