import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Dr_s_n_singh = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/S-N-Singh.jpg"
						alt=""
					/>

					<h4 className="">Dr S.N. Singh</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />

						<b>Past</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Ex-Principal Scientist (Agronomy), ICAR-Indian Institute of
								Sugarcane Research, Lucknow, India.
							</li>
							<li>
								Worked with CIMMYT sponsored USAID project on“Accelerating the
								tillage revolution in the Indus-Gangesbasin: Fostering Resource
								Conservation Technologies topromote economic growth, resource
								conservation, andfoodsecurity.”.
							</li>{" "}
							<li>
								Worked as Co-coordinator with respect to the sugarcane training
								program for the scientists from Bangladesh.
							</li>{" "}
						</ul>
						<br />
						<b>Recognitions</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Conferred with Lifetime Contribution Award by Society for Sugar
								Research & Promotion , Lucknow - Year 2022.
							</li>
							<li>
								Conferred Best KVK Award, Pandit Deendaya lUpadhyay Krishi
								Vigyan Protsahan Puraskar by the Hon’ble Prime Minister of India
								New Delhi - Year 2018 on behalf of ICAR, New Delhi.
							</li>
							<li>
								Conferred Award of Excellence during International conference on
								“Green Technologies for Sustainable Development of Sugar and
								Related Industries - ICAR-IISR: Year 2019.
							</li>{" "}
							<li>
								Conferred Dr. O. P. GautamVishisht KrishiVaigyanikPuraskar2015
								by the Hon’ble Governor ofUttarPradeshat CSAU&T, Kanpur -2016.
							</li>{" "}
						</ul>
						<br />
						<b>Education</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Post Graduation in M.Sc. Agronomy (71.52%) at C.S.A.U of Kanpur
								in 1983.
							</li>{" "}
							<li>
								Doctorate in Ph.D. Agronomy at Agra University of Agra in 1993.
							</li>{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Dr_s_n_singh;
