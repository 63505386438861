import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Mr_Ramesh_Penmetcha = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						// class="img-fluid"
						src="../images/ramesh.jpg"
						alt="Mr Ramesh Penmetcha"
					/>

					<h4 className="">Mr Ramesh Penmetcha</h4>
					<br />
					<br />
					<h4 className="">Information Technology Expert</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Professional Experience (1990-2024):</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Worked extensively on cutting-edge infrastructure software
								development projects for the New York Stock Exchange (NYSE) over
								three decades
							</li>
							<li>
								{" "}
								<li>
									{" "}
									Held senior leadership roles, including Senior Systems
									Analyst, Senior Architect, and Managing Director.{" "}
								</li>
							</li>
							<li>
								{" "}
								Key projects included implementing state-of-the-art messaging
								systems using Pub/Sub technology for core NYSE application
								interconnectivity, designing high-throughput, low-latency
								trading platforms, and developing operational monitoring systems
								to ensure performance and alerting for critical applications.
							</li>

							<br />
						</ul>
						<b>Education:</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Master of Science (MS) in Computer Science, IIT Bombay, India
							</li>
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Mr_Ramesh_Penmetcha;
