import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";
const Shashi_sareen = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/Ms-Shashi-Sareen.jpg"
						alt=""
					/>

					<h4 className="">Ms. Shashi Sareen</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<p className="fs">
							{" "}
							International Advisor/ Consultant in the areas of food safety ,
							quality, nutrition and one-health associated with various
							international organizations (STDF, World Bank, FAO, UNESCWA,
							UNESCAP, WHO, IFC, etc.) and national (NABCB, India for
							Accreditation).
						</p>
						<br />
						<b>Past</b>
						<br />
						<b>
							CEO and Director, Export Inspection Council of India for 8 yrs.
						</b>

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Responsible for entire administration, technical, manpower
								aspects, assuring quality for exports of all products notified
								by Govt of India based on requirements of importing governments
								covering marine, poultry, egg, dairy, honey, spices, rice, etc.
								A major area addressed was establishing and implementing food
								control systems in these sectors including contaminant and
								residue monitoring and control programs to meet SPS requirements
								of importing countries like EU, US, Japan, Australia, etc.
								Strengthened labs to bring them up to world class with
								accreditation of these
							</li>{" "}
						</ul>
						<br />
						<b>Director, Bureau of Indian Standards,</b>

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Developing standards and implementing certification schemes
							</li>{" "}
						</ul>
						<br />
						<b>
							Senior Food Safety and Nutrition Officer, FAO Regional Office for
							Asia and the Pacific, United Nations.
						</b>

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								International experience of more than 7 years with FAO as
								responsible for capacity development and advise to governments
								and support country capacity strengthening in the area of food
								safety/quality/ nutrition; responsible for 24 countries.
								Assessed countries capacity on various aspects of food control
								system and supported them to meet their SPS compliance under WTO
								Agreement. Developed and implemented cooperation programmes in
								the area of food safety, quality and nutrition over the last 7-8
								years with international organizations namely FAO in
								collaboration with EU, UNICEF, World Bank, WHO, etc.
							</li>{" "}
						</ul>

						<b>
							Developing Quality Control & Certification Schemes for export.
						</b>

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Designed, developed and implemented quality control and
								certification schemes at the national level for various sectors
								(Fish & fishery products, Dairy products, Poultry & egg
								products, Honey, Basmati rice, Black pepper, Coffee, Instant
								foods, Cold storages, animal feeds) for the purpose of export
								incorporating the international requirements in terms of
								GMP/GHP/HACCP, traceability, raw material controls, recall
								systems, etc.
							</li>{" "}
						</ul>

						<br />
						<b>Good Agricultural Practices.</b>

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Supported SAARC, ASEAN and Arab regions to implement GAP in
								fruits and vegetables sector. Also developed and trained
								countries on implementing, a scheme and training manual on GAP
								for fruits and vegetables, a training manual on GAP for fruit
								and vegetable sector for Arab region and training manual for
								implementing ASEANGAP.
							</li>{" "}
						</ul>

						<br />
						<b>Vice President Quality, Aditya Birla Retail Limited.</b>

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Overall in charge of quality in one of the largest retail chains
								in India, having more than 600 retail outlets in the form of
								Super markets and Hyper-markets.
							</li>{" "}
						</ul>

						<br />
						<b>PROFESSIONAL QUALIFICATIONS</b>

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Quality Management Systems auditor and trainer as per ISO 9000.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Lead auditors Course on ISO 14001 Environmental Management
								Systems.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Auditor for HACCP (QAS, Australia; NSF, USA).
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								IRCA certified ISO 22000 FSMS Lead Auditor.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								IRCA certified ISO 22000 FSMS Lead Auditor.
							</li>{" "}
						</ul>

						<br />
						<b>Education</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Master’s Degree in Foods and Nutrition from Delhi University
								with first division and second position in Delhi University.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Master’s Degree in Human Resource and Organizational Development
								from Delhi University (Delhi School of Economics) with first
								division.
							</li>{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Shashi_sareen;
