import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Joginder_singh_minhas = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/Joginder-Singh-Minhas.jpg"
						alt=""
					/>

					<h4 className="">Joginder Singh Minhas</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Project Manager, CIP-APART Potato Value Chain,
							</li>
							<li>International Potato Center (CIP),</li>

							<br />
						</ul>
						<b>Past</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Chief Executive Officer, Jalandhar Potato Growers Biotech
								Producer Company Ltd, Lead a group of potato seed producers for
								quality seed production through geoponics technology and through
								good agricultural practices. Helped potato seed farmers in
								establishing a 2 million tuber capacity aeroponics facility in
								Jalandhar, Punjab, India
							</li>
							<li>
								Head, Central Potato Research Station, Jalandhar, Punjab State
							</li>{" "}
							<li>
								Principle Scientist, Agricultural Research Service of the Indian
								Council of Agricultural Research (ICAR), Central Potato Research
								Institute (CPRI) Shimla
							</li>{" "}
							<li>
								Scientist S2, Agricultural Research Service of the Indian
								Council of Agricultural Research (ICAR), Tribal Area Development
								Scheme Waghai
							</li>
						</ul>
						<br />
						<b>Membership in Professional Associations:</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Distinguished fellow, Indian Potato Association.
							</li>
							<li>Secretary, Indian Potato Association, 2004-2007.</li>
							<li>Vice President, Indian Potato Association, 2007-09.</li>{" "}
						</ul>
						<br />
						<b>Awards</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								“ICAR Outstanding Multi-disciplinary Team Research Award 2010”
								for development of heat tolerant variety Kufri Surya (Team
								leader)
							</li>
							<li>
								Merit Certificate (2004) by CPRI Shimla for significant
								contribution to potato research
							</li>{" "}
						</ul>
						<br />
						<b>Education</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Ph.D., Plant Physiology and Molecular Biology, Himachal Pradesh
								University, Shimla, India, 1999
							</li>{" "}
							<li>
								Master of Science (Hons.), Plant physiology, Punjab Agricultural
								University, Ludhiana, India, 1977
							</li>{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Joginder_singh_minhas;
