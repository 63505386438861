import axios from "axios";
export const Baseurl = () => {
	var baseurl1 = "";
	if (process.env.REACT_APP_HOST === "local") {
		baseurl1 = "http://localhost:3001/api/";
	} else {
		baseurl1 = window.location.origin + "/api/";
	}
	return baseurl1;
};
export const getDataAPI = async (url, token) => {
	const res = await axios.get(Baseurl() + `${url}`, {
		headers: { Authorization: token },
	});
	return res;
};
export const getDataPDF = async (url, post, token) => {
	const res = await axios.post(Baseurl() + `${url}`, post, {
		responseType: "blob",
		headers: { Authorization: token },
	});
	return res;
};

export const postDataAPI = async (url, post, token) => {
	const res = await axios.post(Baseurl() + `${url}`, post, {
		headers: {
			Authorization: token,
		},
	});
	return res;
};

export const putDataAPI = async (url, post, token) => {
	const res = await axios.put(Baseurl() + `${url}`, post, {
		headers: { Authorization: token },
	});
	return res;
};

export const patchDataAPI = async (url, post, token) => {
	const res = await axios.patch(Baseurl() + `${url}`, post, {
		headers: { Authorization: token },
	});
	return res;
};

export const deleteDataAPI = async (url, token) => {
	const res = await axios.delete(Baseurl() + `${url}`, {
		headers: { Authorization: token },
	});
	return res;
};
