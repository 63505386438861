import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Shashi_sareen = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/Prathap_Chandra_Shetty.jpg"
						alt=""
					/>

					<h4 className="">Prathap Chandra Shetty</h4>
					<p className="">Fisheries and Aquaculture Value Chain Expert</p>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Fisheries and Aquaculture Sector Management Expert for FAO of
								the United Nations.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Assessor BAP & Global GAP, National Accreditation Board for
								Certification Bodies (NABCB).
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								TWG Member, Global Food Safety Initiative (GFSI). Post-Harvest
								Handling of Terrestrial Animals and Farmed Seafood ( Consultant
								/ member).
							</li>{" "}
						</ul>

						<b>Past</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Thirty five years of professional activity in International
								seafood trade, processing practices, Sustainable Aquaculture
								Operations, International Food Standards, Seafood Trading, Trade
								capacity Building, Quality control, Sustainability, Social
								responsibility and Certification.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Standard writing with 150+ training manuals in HACCP,
								Management, Projects, and Training programs for the trainers-
								for all category of Fisheries operation and Aquaculture.
								Multilingual.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								<b>Technical Director,</b> The Fish in Company, USA. One among
								the leading seafood supplier in the world, having retail outlets
								in all of the 50 states in the USA.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Executive Director, Emirates Star Fisheries, Dubai, UAE.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Director, Shui Aqua Bios Exports Pvt. Ltd., Karnataka, India.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Chief General Manager, Trimarine Food Pvt. Ltd., Cochin, Kerala,
								India.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Quality Control Manager – Training and Development, Saudi
								Fisheries Company, Dammam, K.S.A.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Travelled approximately 100 countries.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Developed general regulations and operating guidelines to enable
								Public Sector´s effective marine aquaculture zoning and area
								management, as well as and make recommendations on models of
								services.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								<b>The International Fisheries Infrastructure Expert,</b> UNIDO
								Project on “Technical and Institutional Capacity Building for
								increase in production and development of the aquaculture and
								fisheries value chains in Ethiopia”.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								<b>HACCP Advisor,</b> Phase 1 &2, CTG for FAO/UNDP of the United
								Nations HACCP Certification Study and Local Capacity Development
								in Fishery Sector, a joint project , to implement the HACCP,
								Food Safety issues and Fisheries, Procedures for the Safe and
								Sanitary Processing and Importing of Fish and Fishery Products.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								FAO ( 2019) - HACCP Certification Study and Local Capacity
								Development in Fisheries Industry , BOSASO SOMALIA, January 2019
								, total pages 137.
							</li>{" "}
						</ul>
						<br />
						<b>Membership and Affiliation</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								The World Aquaculture Society & Asian-Pacific Chapter.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Global Aquaculture Alliance .
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Best Aquaculture Practices -Aquaculture Certification Council .
							</li>{" "}
						</ul>
						<br />
						<b>Professional Experience</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								“ISO 22000 and SA 8000” five days completed courses by The Food
								School,
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								HACCP Auditor, by SURE FISH, 413-3rd Ave., West SEATTLE, WA
								98119.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Accredited Certifier, training By ACC (Aquaculture Certification
								Council, USA.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Project evaluation expert : AAAID (Arab Authority for
								Agricultural Investment and Development) Khartoum, SUDAN,
								Certifier for evaluation of Aquaculture projects in the Middle
								East.
							</li>{" "}
						</ul>
						<br />

						<b>Educaation</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Masters in Fisheries Science, University of Agriculture Science,
								Bangalore.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Bachelors of Fisheries Science with emphasis on Aquaculture.
							</li>{" "}
						</ul>
						<br />
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Shashi_sareen;
