import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useSelector } from "react-redux";
import "./communitystyles.css";
import ImageCropper from "./imagecroppopup";
import LoginRequest from "./loginRequest";
import NewNavbar from "./newNavbar";
const Network = () => {
	const { theme, auth } = useSelector((state) => state);
	const communitiesArray = [1, 2, 3, 4, 1];
	const mostActiveCommunitiesArray = [1, 2, 3, 4, 5, 6];
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);
	const [show3, setShow3] = useState(false);
	const [showProfilePhotoModal, setShowProfilePhotoModal] = useState(false);
	const [showJoinModal, setShowJoinModal] = useState(false);
	const [croppedImage, setCroppedImage] = useState(undefined);
	const [croppedImageCoverPhoto, setCroppedImageCoverPhoto] =
		useState(undefined);
	const [croppedImageProfilePhoto, setCroppedImageProfilePhoto] =
		useState(undefined);
	const [getUpload, setGetUpload] = useState();
	const [size, setSize] = useState("");
	const [imageToCrop, setImageToCrop] = useState(undefined);
	const [imageToCropCoverPhoto, setImageToCropCoverPhoto] = useState(undefined);
	const [imageToCropProfilePhoto, setImageToCropProfilePhoto] =
		useState(undefined);

	const onUploadFile = (event) => {
		let sizeInBytes = event.target.files[0].size;
		var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		setSize(sizeInMB + "MB");

		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const image = reader.result;
				console.log(image);
				setGetUpload(image);
				setImageToCrop(image);
			});
			reader.readAsDataURL(event.target.files[0]);
		}
	};
	const onUploadFileCoverPhoto = (event) => {
		let sizeInBytes = event.target.files[0].size;
		var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		setSize(sizeInMB + "MB");

		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const image = reader.result;
				// console.log(image);
				// setGetUpload(image);
				setImageToCropCoverPhoto(image);
			});
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const onUploadFileProfilePhoto = (event) => {
		let sizeInBytes = event.target.files[0].size;
		var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		setSize(sizeInMB + "MB");

		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const image = reader.result;
				// console.log(image);
				// setGetUpload(image);
				setImageToCropProfilePhoto(image);
			});
			reader.readAsDataURL(event.target.files[0]);
		}
	};
	const handleClose = () => {
		setShow(false);
	};
	const handleOpen = () => {
		setShow(true);
	};
	const handleClose2 = () => {
		setShow2(false);
	};
	const handleOpen2 = () => {
		setShow2(true);
		setShowJoinModal(false);
	};

	const handleClose3 = () => {
		setShow3(false);
	};

	const handleCloseJoinModal = () => {
		setShowJoinModal(false);
	};
	const handleOpenjoinModal = () => {
		setShowJoinModal(true);
	};

	const handleCloseProfilePhotoModal = () => {
		setShowProfilePhotoModal(false);
		setShowJoinModal(true);
	};

	const handleOpenprofilePhotoModal = () => {
		setShowProfilePhotoModal(true);
		setShowJoinModal(false);
	};

	const FirstTab = (props) => {
		return (
			<div>
				<Button
					onClick={buttonClick}
					style={{
						backgroundColor: "#3CCE8F",
						border: "none",
						borderRadius: "4px",
						marginTop: "-6px",
					}}>
					<img src="./images/refer_icon.svg"></img>&nbsp;&nbsp;Invite and earn
				</Button>
			</div>
		);
	};
	const buttonClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// console.log("Button clicked");
		setShow3(true);
	};
	const inputFile = useRef(null);
	const onButtonClick = () => {
		// `current` points to the mounted file input element
		inputFile.current.click();
	};
	const inputFileCoverPhoto = useRef(null);
	const onButtonClickCoverPhoto = () => {
		// `current` points to the mounted file input element
		inputFileCoverPhoto.current.click();
	};

	const inputFileProfilePhoto = useRef(null);
	const onButtonClickProfilePhoto = () => {
		// `current` points to the mounted file input element
		inputFileProfilePhoto.current.click();
	};
	return (
		<>
			{/* <Header /> */}
			<NewNavbar />
			{auth.user ? (
				<>
					<div style={{ padding: "20px 60px 20px 120px" }}>
						<Tabs
							defaultActiveKey="home"
							id="uncontrolled-tab-example"
							// className="mb-3"
							style={{
								backgroundColor: "#fff",
								borderRadius: "15px",
								height: "55px",
							}}>
							<Tab eventKey="home" title="People">
								<div className="">
									<div className="row pt-3">
										<div
											className="col-8 card"
											style={{
												marginRight: "75px",
												border: "none",
												borderRadiusTop: "15px",
												marginLeft: "10px",
												borderRadius: "15px",
											}}>
											<div
												style={{
													backgroundColor: "#F4F7F9",
													padding: "15px 15px",
													marginLeft: "-12px",
													marginRight: "-12px",
													fontWeight: "600",
													fontSize: "16px",
													borderTopLeftRadius: "15px",
													borderTopRightRadius: "15px",
												}}>
												People you may like to connect
											</div>
											<div>
												<div className="row">
													{communitiesArray.map((e) => {
														return (
															<div
																className="col-3"
																style={{ padding: "10px 10px 10px 10px" }}>
																<div className="uc-community-li-main">
																	{" "}
																	<div className="uc-comm-like-imgs">
																		<img
																			// onError="communityBannerImgError(this);"
																			src="./images/common-background.png"
																			alt="img"
																		/>
																	</div>
																	<div className="uc-comm-userimg">
																		{" "}
																		<img
																			src="https://lh3.googleusercontent.com/a/ACg8ocI5543DTkdKPEsRuFOHPGyz2l6l8toFAzbyzmwaChhe5A4=s96-c"
																			alt=""
																			// onError="profileImgError(this);"
																			className="uc-comm-user-img"
																			// onClick="communityChangeNew(5421)"
																		/>{" "}
																	</div>
																	<div className="uc-right-top-wrap">
																		{/* <a
                                  href="javascript:void(0)"
                                  className="uc-option-btn"
                                >
                                  {" "}
                                  <img
                                    src="https://www.ulektz.com/assets/img/home/options.svg"
                                    alt="icon"
                                  />{" "}
                                </a>{" "} */}
																		<a
																			href="javascript:void(0)"
																			className="uc-rb-btn"
																			//   onClick="openReportCommunityPopup(5421,this)"
																		>
																			<span
																				className="uc-rc-pop-span"
																				style={{ position: "absolute" }}>
																				{/* <img src="https://www.ulektz.com/assets/img/home/Report_post.svg" />{" "}
                                    Report Community */}
																			</span>
																		</a>{" "}
																	</div>{" "}
																	<div className="uc-community-li-con">
																		{" "}
																		<div>
																			<h4>JAKKULA SURESH</h4>{" "}
																			<div className="uc-pro-location">
																				{" "}
																				<p> Student</p>{" "}
																				<div className="uc-pv-wrap-comb-mem">
																					{" "}
																					<div className="uc-pv-wrap">
																						{/* <a
                                          href="https://www.ulektz.com/in/53010781"
                                          style="background-color: #00B3F6;border-radius: 25px;text-align: center;"
                                        >
                                          <span style="text-transform: capitalize;font-weight: bold;">
                                            u
                                          </span>
                                        </a>
                                        <a href="https://www.ulektz.com/in/53010757">
                                          <img
                                            src="https://lh3.googleusercontent.com/a/ACg8ocJr3hF6LUSQCADedncmz7-BZdNA2_ob5_2ojLRphrW7=s96-c"
                                            alt="Abhinav shukla"
                                            style="border-radius: 25px;"
                                            // onError="this.onerror=null;this.src=https://www.ulektz.com/assets/img/header/common-user.svg"
                                          />
                                        </a>
                                        <a href="https://www.ulektz.com/in/53010572">
                                          <img
                                            src="https://lh3.googleusercontent.com/a/ACg8ocJOdghAab-mQGftAo5k83Ah4EtsjNuAUd7swuAI8ermOZ4=s96-c"
                                            alt="Sahana M"
                                            style="border-radius: 25px;"
                                            // onError="this.onerror=null;this.src=https://www.ulektz.com/assets/img/header/common-user.svg"
                                            className=""
                                          />
                                        </a> */}
																						{/* <a
                                          href="https://www.ulektz.com/in/53009052"
                                          style="background-color: #6a828e;border-radius: 25px;text-align: center;"
                                        >
                                          <span style="text-transform: capitalize;font-weight: bold;">
                                            M
                                          </span>
                                        </a> */}
																					</div>{" "}
																					<p
																						// onClick="communityChangeNew(5421)"
																						// className="pb-2"
																						style={{ marginBottom: "0rem" }}>
																						<span className="">Hyderabad</span>
																					</p>{" "}
																				</div>{" "}
																			</div>
																		</div>{" "}
																		<div
																			style={{
																				display: "flex",
																				justifyContent: "center",
																				marginTop: "30px",
																			}}>
																			<Button
																				style={{
																					background: "#20ce8f",
																					borderRadius: "5px",
																					width: "fit-content",
																					width: "100px",
																					height: "30px",
																					display: "flex",
																					alignItems: "center",
																					justifyContent: "center",
																					border: "none",
																				}}>
																				Connect
																			</Button>{" "}
																		</div>
																	</div>{" "}
																</div>
																{/* <div className="card">
                              <img
                                src="./images/profile.png"
                                className="uc-comm-user-img "
                              ></img>
                              <div>Nuclear Physics</div>
                              <div
                                style={{
                                  backgroundColor: "#E23F1C",
                                  color: "#fff",
                                  textAlign: "center",
                                }}
                              >
                                +Join
                              </div>
                            </div> */}
															</div>
														);
													})}
												</div>
											</div>
										</div>

										<div
											className="col-3 card"
											style={{
												// maxHeight: "calc(100vh - 235px)",
												border: "none",
												borderRadius: "15px",
											}}>
											<div
												style={{
													backgroundColor: "#F4F7F9",
													padding: "15px 15px",
													marginLeft: "-12px",
													marginRight: "-12px",
													fontWeight: "600",
													fontSize: "16px",
													borderTopLeftRadius: "15px",
													borderTopRightRadius: "15px",
												}}>
												My Connections
											</div>
											<div
												style={{
													overflowY: "auto",
													// maxHeight: "calc(100vh - 235px)",
													overflowX: "hidden",
												}}>
												<div style={{ marginTop: "10px" }}>
													<input
														type="text"
														className="form-control"
														placeholder="Search connections"></input>{" "}
												</div>

												{mostActiveCommunitiesArray.map((e) => {
													return (
														<>
															<div
																className="row"
																style={{ padding: "5px 5px" }}>
																<div
																	className="col-3"
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																	}}>
																	{" "}
																	<span>
																		<img
																			src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/7c86d5127c53dae5.jpeg"
																			alt=""
																			width="45px"
																			heght="45px"
																			// onError="this.onerror=null;this.src='https://www.ulektz.com/assets/img/community/community-def-logo.png'"
																			style={{ borderRadius: "50%" }}
																		/>
																	</span>
																</div>
																<div
																	className="col-9"
																	style={{ paddingLeft: "0px" }}>
																	<span
																		className="uc-community-cnt"
																		// onClick="communityChangeNew(132)"
																	>
																		<div className="row">
																			<div className="col-9">
																				<p className="uc-communityLike-in">
																					<h5>Dr. Sadiq Sait M.S</h5>
																					<span>
																						Founder and CEO - uLektz.com
																						[AI-powered Ecosystem of Higher
																						Education]
																						{/* <br />
                                        41866 members */}
																					</span>
																				</p>
																			</div>
																			<div
																				className="col-3"
																				style={{ paddingLeft: "0px" }}>
																				<img
																					src="./images/Sent.svg"
																					style={{
																						paddingLeft: "0px",
																						border: "1px solid #ddd",
																						padding: "6px",
																						borderRadius: "2px",
																					}}></img>
																			</div>
																		</div>
																	</span>
																</div>
																<a
																	data-id="132"
																	href="javascript:void(0);"
																	className="uc-com-join-prof-toggle"></a>
															</div>
															<hr
																style={{
																	marginTop: "0px",
																	marginBottom: "0px",
																}}
															/>
														</>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							</Tab>
							<Tab eventKey="profile" title="My Connections">
								<div className="">
									<div className="row" style={{ paddingTop: "15px" }}>
										<div
											className="col-8 card"
											style={{
												marginRight: "75px",
												marginLeft: "10px",
												border: "none",
												borderRadius: "15px",
											}}>
											<div
												className="row"
												style={{
													backgroundColor: "#F4F7F9",
													padding: "15px 15px",
													marginLeft: "-12px",
													marginRight: "-12px",
													fontWeight: "600",
													fontSize: "16px",
													borderTopLeftRadius: "15px",
													borderTopRightRadius: "15px",
												}}>
												<div
													className="col-8"
													style={{ display: "flex", alignItems: "center" }}>
													My Connections 12
												</div>
												<div
													className="col-1"
													style={{
														width: "auto",
														paddingRight: "0px",
														paddingLeft: "0px",
													}}>
													{/* <button
														className="ripple-button uc-extra-options-button"
														id="uc-add-clsJRS"
														style={{
															background: "#fff",
															width: "35px",
															height: "35px",
															borderRadius: "4px",
															border: "1px solid #ddd",
														}}>
														<img
															className="uc-extra-options-img"
															src="./images/sort_icon.svg"
															alt=""
														/>
													</button> */}
												</div>
												<div className="col-3">
													<input
														type="text"
														placeholder="Search"
														style={{
															padding: "4px 4px 4px 20px",
															border: "1px solid #ddd",
															borderRadius: "4px",
															backgroundImage: "url(./images/search.svg)",
															backgroundRepeat: "no-repeat",
															backgroundSize: "18px",
															backgroundPosition: "left",
														}}></input>
												</div>
											</div>

											<div>
												<div
													className="row"
													style={{ padding: "10px 10px", cursor: "pointer" }}
													onClick={() => {
														window.location.href = "/communitykrishigap";
													}}>
													{communitiesArray.map(() => {
														return (
															<>
																<div style={{ padding: "10px" }}>
																	<div
																		className="card"
																		style={{
																			padding: "15px 15px",
																		}}>
																		<div className="row">
																			<div className="col-1">
																				{" "}
																				<img
																					src="./images/murthy.png"
																					alt=""
																					width="68px"
																					style={{ borderRadius: "50%" }}
																				/>
																			</div>
																			<div
																				className="col-9"
																				//   style={{
																				//     display: "flex",
																				//     alignItems: "center",
																				//     justifyContent: "flex-start",
																				//   }}
																			>
																				<h3
																					style={{
																						fontSize: "16px",
																						fontWeight: "500",
																						paddingLeft: "20px",
																					}}>
																					Dr Suriya Narayana Moorty&nbsp;&nbsp;
																					{/* <span
                                        style={{
                                          backgroundColor: "#0076E1",
                                          color: "#fff",
                                          padding: "1px",
                                          borderRadius: "2px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Member
                                      </span> */}
																				</h3>
																				<div
																					style={{
																						fontSize: "14px",
																						color: "#999",
																						paddingLeft: "20px",
																					}}>
																					Chief Operationg Officer
																				</div>
																				<div
																					style={{
																						fontSize: "14px",
																						color: "#999",
																						paddingLeft: "20px",
																					}}>
																					6 Mutual Connections
																				</div>
																				<div
																					style={{
																						paddingLeft: "20px",
																						fontSize: "14px",
																						display: "flex",
																						alignItems: "center",
																					}}>
																					<img src="./images/location_on.svg"></img>
																					&nbsp; Chennai
																				</div>
																			</div>

																			<div
																				className="col-2"
																				//   style={{
																				//     display: "flex",
																				//     alignItems: "center",
																				//     justifyContent: "center",
																				//   }}
																			>
																				<img src="./images/options.svg"></img>
																				<br />
																				<Button
																					style={{
																						background: "#fff",

																						fontSize: "13px",
																						borderRadius: "4px",
																						color: "#000",
																						border: "1px solid #ddd",
																					}}>
																					Message
																				</Button>
																			</div>
																		</div>
																	</div>
																</div>
															</>
														);
													})}
												</div>
											</div>
										</div>

										<div
											className="col-3 card"
											style={{
												// maxHeight: "450px",
												border: "none",
												borderRadius: "15px",
											}}>
											<div
												style={{
													backgroundColor: "#20ce8f",
													padding: "15px 15px",
													marginLeft: "-12px",
													marginRight: "-12px",
													fontWeight: "600",
													fontSize: "16px",
													borderTopLeftRadius: "5px",
													borderTopRightRadius: "5px",
												}}>
												<img src="./images/People_connect-img.svg"></img>
												<div style={{ textAlign: "center", color: "#fff" }}>
													People you may like to connect
												</div>
											</div>
											<div>
												{" "}
												{mostActiveCommunitiesArray.map((e) => {
													return (
														<>
															<div
																className="row"
																style={{ padding: "5px 5px" }}>
																<div
																	className="col-3"
																	style={{
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "center",
																	}}>
																	{" "}
																	<span>
																		<img
																			src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/7c86d5127c53dae5.jpeg"
																			alt=""
																			width="45px"
																			heght="45px"
																			// onError="this.onerror=null;this.src='https://www.ulektz.com/assets/img/community/community-def-logo.png'"
																			style={{ borderRadius: "50%" }}
																		/>
																	</span>
																</div>
																<div
																	className="col-9"
																	style={{ paddingLeft: "0px" }}>
																	<span className="uc-community-cnt">
																		<div className="row">
																			<div className="col-9">
																				<p className="uc-communityLike-in">
																					<h5>Dr. Sadiq Sait M.S</h5>
																					<span>
																						Founder and CEO - uLektz.com
																						[AI-powered Ecosystem of Higher
																						Education]
																						{/* <br />
                                        41866 members */}
																					</span>
																				</p>
																			</div>
																			<div
																				className="col-3"
																				style={{ paddingLeft: "0px" }}>
																				<img
																					src="./images/Add-connection.svg"
																					style={{
																						paddingLeft: "0px",
																						background: "#EFEFEF",
																						padding: "6px",
																						borderRadius: "2px",
																					}}></img>
																			</div>
																		</div>
																	</span>
																</div>
																<a
																	data-id="132"
																	href="javascript:void(0);"
																	className="uc-com-join-prof-toggle"></a>
															</div>
															<hr
																style={{
																	marginTop: "0px",
																	marginBottom: "0px",
																}}
															/>
														</>
													);
												})}
												<div
													className="lightgreen"
													style={{
														textAlign: "center",
														textDecoration: "underline",
													}}>
													See All
												</div>
											</div>
										</div>
									</div>
								</div>
							</Tab>
							<Tab eventKey="contact" title="Invitations">
								<Tabs
									defaultActiveKey="Invitation Received"
									id="uncontrolled-tab-example"
									style={{
										backgroundColor: "#F4F7F9",
										borderBottomLeftRadius: "15px",
										borderBottomRightRadius: "15px",
									}}
									className="newtabs">
									<Tab
										eventKey="Invitation Received"
										title="Invitation Received">
										{" "}
										<div className="pt-3">
											<div className="row" style={{}}>
												<div
													className="col-8 card"
													style={{
														marginRight: "75px",
														marginLeft: "10px",
														border: "none",
														borderRadius: "15px",
													}}>
													<div
														className="row"
														style={{
															backgroundColor: "#F4F7F9",
															padding: "15px 15px",
															marginLeft: "-12px",
															marginRight: "-12px",
															fontWeight: "600",
															fontSize: "16px",
															borderTopLeftRadius: "15px",
															borderTopRightRadius: "15px",
														}}>
														<div
															className="col-8"
															style={{ display: "flex", alignItems: "center" }}>
															Invitations Received
														</div>

														<div
															className="col-1"
															style={{
																width: "auto",
																paddingRight: "0px",
																paddingLeft: "0px",
															}}>
															{/* <button
																className="ripple-button uc-extra-options-button"
																id="uc-add-clsJRS"
																style={{
																	background: "#fff",
																	width: "35px",
																	height: "35px",
																	borderRadius: "4px",
																	border: "1px solid #ddd",
																}}>
																<img
																	className="uc-extra-options-img"
																	src="./images/sort_icon.svg"
																	alt=""
																/>
															</button> */}
														</div>

														<div className="col-3" style={{ textAlign: "end" }}>
															<input
																type="text"
																placeholder="Search"
																style={{
																	padding: "4px 4px 4px 20px",
																	border: "1px solid #ddd",
																	borderRadius: "4px",
																	backgroundImage: "url(./images/search.svg)",
																	backgroundRepeat: "no-repeat",
																	backgroundSize: "18px",
																	backgroundPosition: "left",
																}}></input>
														</div>
													</div>
													{/* <div>
                        <div className="row" style={{ padding: "10px 10px" }}>
                          {communitiesArray.map((e) => {
                            return (
                              <div className="col-12">
                                <div
                                  className="row"
                                  style={{
                                    padding: "10px 10px",
                                    border: "1px solid #000",
                                  }}
                                >
                                  <div className="col-2">
                                    <img
                                      src="./images/krishigaplogo.png"
                                      style={{
                                        borderRadius: "50%",
                                        height: "70px",
                                      }}
                                    ></img>
                                  </div>
                                  <div className="col-9">
                                    <div className="row">
                                      <div className="col-10">
                                        Global Food Safety and Sustainability
                                        Standards Platform for Networking,
                                        Learning and Development
                                      </div>
                                      <div
                                        className="col-2"
                                        style={{
                                          fontSize: "12px",
                                          background: "#FBB3A3",
                                          height: "20px",
                                        }}
                                      >
                                        Moderator
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-7">
                                        <img
                                          src="./images/krishigaplogo.png"
                                          style={{ height: "30px" }}
                                        ></img>
                                        27 members
                                      </div>

                                      <div
                                        className="public-text col-5"
                                        onClick="communityChangeNew(5421)"
                                      >
                                        <span className="uc-star-count">
                                          <a
                                            href="javascript:void(0)"
                                            className="cmn-btn uc-normal-btn"
                                          >
                                            <img src="https://www.ulektz.com/assets/img/profile/Public_icon.svg" />{" "}
                                            Public
                                          </a>
                                          <a
                                            href="javascript:void(0)"
                                            className="cmn-btn uc-blue-btn type"
                                          >
                                            <span className="">Professional</span>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-1">
                                    <div className="uc-right-top-wrap">
                                      {" "}
                                      <a
                                        href="javascript:void(0)"
                                        className="uc-option-btn pop-open"
                                      >
                                        {" "}
                                        <img
                                          src="https://www.ulektz.com/assets/img/home/options.svg"
                                          alt="icon"
                                        />{" "}
                                      </a>{" "}
                                      <a
                                        href="javascript:void(0)"
                                        className="uc-rb-btn uc-leave-btn opt-pop"
                                        onClick="removeBlockPopup(5641, 19,this)"
                                      >
                                        <span className="uc-ltc-pop-span d-none">
                                          <img
                                            src="https://www.ulektz.com/assets/img/community/Leave.svg"
                                            alt=""
                                          />
                                          Leave this Community
                                        </span>
                                      </a>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div> */}
													<div style={{ textAlign: "center" }}>
														<img src="./images/nrf.svg"></img>
														<div
															className="lightgreen"
															style={{ fontSize: "28px" }}>
															No invitation here
														</div>
													</div>
												</div>

												<div
													className="col-3 card"
													style={{
														// maxHeight: "450px",
														border: "none",
														borderRadius: "15px",
													}}>
													<div
														style={{
															backgroundColor: "#20ce8f",
															padding: "15px 15px",
															marginLeft: "-12px",
															marginRight: "-12px",
															fontWeight: "600",
															fontSize: "16px",
															borderTopLeftRadius: "5px",
															borderTopRightRadius: "5px",
														}}>
														<img src="./images/People_connect-img.svg"></img>
														<div style={{ textAlign: "center", color: "#fff" }}>
															People you may like to connect
														</div>
													</div>
													<div>
														{" "}
														{mostActiveCommunitiesArray.map((e) => {
															return (
																<>
																	<div
																		className="row"
																		style={{ padding: "5px 5px" }}>
																		<div
																			className="col-3"
																			style={{
																				display: "flex",
																				alignItems: "center",
																				justifyContent: "center",
																			}}>
																			{" "}
																			<span>
																				<img
																					src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/7c86d5127c53dae5.jpeg"
																					alt=""
																					width="45px"
																					heght="45px"
																					// onError="this.onerror=null;this.src='https://www.ulektz.com/assets/img/community/community-def-logo.png'"
																					style={{ borderRadius: "50%" }}
																				/>
																			</span>
																		</div>
																		<div
																			className="col-9"
																			style={{ paddingLeft: "0px" }}>
																			<span className="uc-community-cnt">
																				<div className="row">
																					<div className="col-9">
																						<p className="uc-communityLike-in">
																							<h5>Dr. Sadiq Sait M.S</h5>
																							<span>
																								Founder and CEO - uLektz.com
																								[AI-powered Ecosystem of Higher
																								Education]
																								{/* <br />
                                        41866 members */}
																							</span>
																						</p>
																					</div>
																					<div
																						className="col-3"
																						style={{ paddingLeft: "0px" }}>
																						<img
																							src="./images/Add-connection.svg"
																							style={{
																								paddingLeft: "0px",
																								background: "#EFEFEF",
																								padding: "6px",
																								borderRadius: "2px",
																							}}></img>
																					</div>
																				</div>
																			</span>
																		</div>
																		<a
																			data-id="132"
																			href="javascript:void(0);"
																			className="uc-com-join-prof-toggle"></a>
																	</div>
																	<hr
																		style={{
																			marginTop: "0px",
																			marginBottom: "0px",
																		}}
																	/>
																</>
															);
														})}
														<div
															className="lightgreen"
															style={{
																textAlign: "center",
																textDecoration: "underline",
															}}>
															See All
														</div>
													</div>
												</div>
											</div>
										</div>
									</Tab>
									<Tab
										eventKey="Join Request Sent"
										title="Connection Request Sent">
										{" "}
										<div className="">
											<div className="row" style={{ paddingTop: "15px" }}>
												<div
													className="col-8 card"
													style={{
														marginRight: "75px",
														marginLeft: "10px",
														border: "none",
														borderRadius: "15px",
													}}>
													<div
														className="row"
														style={{
															backgroundColor: "#F4F7F9",
															padding: "15px 15px",
															marginLeft: "-12px",
															marginRight: "-12px",
															fontWeight: "600",
															fontSize: "16px",
															borderTopLeftRadius: "15px",
															borderTopRightRadius: "15px",
														}}>
														<div
															className="col-8"
															style={{ display: "flex", alignItems: "center" }}>
															Invitation Sent 46
														</div>

														<div
															className="col-1"
															style={{
																width: "auto",
																paddingRight: "0px",
																paddingLeft: "0px",
															}}>
															{/* <button
																className="ripple-button uc-extra-options-button"
																id="uc-add-clsJRS"
																style={{
																	background: "#fff",
																	width: "35px",
																	height: "35px",
																	borderRadius: "4px",
																	border: "1px solid #ddd",
																}}>
																<img
																	className="uc-extra-options-img"
																	src="./images/sort_icon.svg"
																	alt=""
																/>
															</button> */}
														</div>

														<div className="col-3" style={{ textAlign: "end" }}>
															<input
																type="text"
																placeholder="Search"
																style={{
																	padding: "4px 4px 4px 20px",
																	border: "1px solid #ddd",
																	borderRadius: "4px",
																	backgroundImage: "url(./images/search.svg)",
																	backgroundRepeat: "no-repeat",
																	backgroundSize: "18px",
																	backgroundPosition: "left",
																}}></input>
														</div>
													</div>
													{communitiesArray.map(() => {
														return (
															<>
																<div style={{ padding: "10px" }}>
																	<div
																		className="card"
																		style={{
																			padding: "15px 15px",
																		}}>
																		<div className="row">
																			<div className="col-1">
																				{" "}
																				<img
																					src="./images/murthy.png"
																					alt=""
																					width="68px"
																					style={{ borderRadius: "50%" }}
																				/>
																			</div>
																			<div
																				className="col-9"
																				//   style={{
																				//     display: "flex",
																				//     alignItems: "center",
																				//     justifyContent: "flex-start",
																				//   }}
																			>
																				<h3
																					style={{
																						fontSize: "16px",
																						fontWeight: "500",
																						paddingLeft: "20px",
																					}}>
																					Dr Suriya Narayana Moorty&nbsp;&nbsp;
																					{/* <span
                                        style={{
                                          backgroundColor: "#0076E1",
                                          color: "#fff",
                                          padding: "1px",
                                          borderRadius: "2px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Member
                                      </span> */}
																				</h3>
																				<div
																					style={{
																						fontSize: "14px",
																						color: "#999",
																						paddingLeft: "20px",
																					}}>
																					Chief Operationg Officer
																				</div>
																				<div
																					style={{
																						paddingLeft: "20px",
																						fontSize: "14px",
																						display: "flex",
																						alignItems: "center",
																					}}>
																					<img src="./images/location_on.svg"></img>
																					&nbsp; Chennai
																				</div>
																			</div>

																			<div
																				className="col-2"
																				//   style={{
																				//     display: "flex",
																				//     alignItems: "center",
																				//     justifyContent: "center",
																				//   }}
																			>
																				<Button
																					style={{
																						background: "#B4B5B5",

																						fontSize: "13px",
																						borderRadius: "4px",
																						color: "#fff",
																						border: "1px solid #ddd",
																						width: "100px",
																					}}>
																					Pending
																				</Button>
																				<div
																					style={{ marginTop: "10px" }}></div>
																				<Button
																					style={{
																						background: "#fff",

																						fontSize: "13px",
																						borderRadius: "4px",
																						color: "#000",
																						border: "1px solid #ddd",
																						width: "100px",
																					}}>
																					Cancel
																				</Button>
																			</div>
																		</div>
																	</div>
																</div>
															</>
														);
													})}
												</div>

												<div
													className="col-3 card"
													style={{
														// maxHeight: "450px",
														border: "none",
														borderRadius: "15px",
													}}>
													<div
														style={{
															backgroundColor: "#20ce8f",
															padding: "15px 15px",
															marginLeft: "-12px",
															marginRight: "-12px",
															fontWeight: "600",
															fontSize: "16px",
															borderTopLeftRadius: "5px",
															borderTopRightRadius: "5px",
														}}>
														<img src="./images/People_connect-img.svg"></img>
														<div style={{ textAlign: "center", color: "#fff" }}>
															People you may like to connect
														</div>
													</div>
													<div>
														{" "}
														{mostActiveCommunitiesArray.map((e) => {
															return (
																<>
																	<div
																		className="row"
																		style={{ padding: "5px 5px" }}>
																		<div
																			className="col-3"
																			style={{
																				display: "flex",
																				alignItems: "center",
																				justifyContent: "center",
																			}}>
																			{" "}
																			<span>
																				<img
																					src="https://d3s9yd6qcwjotm.cloudfront.net/ProfileImage/7c86d5127c53dae5.jpeg"
																					alt=""
																					width="45px"
																					heght="45px"
																					// onError="this.onerror=null;this.src='https://www.ulektz.com/assets/img/onError/community-def-logo.png'"
																					style={{ borderRadius: "50%" }}
																				/>
																			</span>
																		</div>
																		<div
																			className="col-9"
																			style={{ paddingLeft: "0px" }}>
																			<span className="uc-community-cnt">
																				<div className="row">
																					<div className="col-9">
																						<p className="uc-communityLike-in">
																							<h5>Dr. Sadiq Sait M.S</h5>
																							<span>
																								Founder and CEO - uLektz.com
																								[AI-powered Ecosystem of Higher
																								Education]
																								{/* <br />
                                        41866 members */}
																							</span>
																						</p>
																					</div>
																					<div
																						className="col-3"
																						style={{ paddingLeft: "0px" }}>
																						<img
																							src="./images/Add-connection.svg"
																							style={{
																								paddingLeft: "0px",
																								background: "#EFEFEF",
																								padding: "6px",
																								borderRadius: "2px",
																							}}></img>
																					</div>
																				</div>
																			</span>
																		</div>
																		<a
																			data-id="132"
																			href="javascript:void(0);"
																			className="uc-com-join-prof-toggle"></a>
																	</div>
																	<hr
																		style={{
																			marginTop: "0px",
																			marginBottom: "0px",
																		}}
																	/>
																</>
															);
														})}
														<div
															className="lightgreen"
															style={{
																textAlign: "center",
																textDecoration: "underline",
															}}>
															See All
														</div>
													</div>
												</div>
											</div>
										</div>
									</Tab>
								</Tabs>
							</Tab>
							<Tab title={<FirstTab />}></Tab>
						</Tabs>
					</div>
					<Modal
						show={show3}
						onHide={handleClose3}
						dialogClassName="modal-sm-30px"
						size="lg"
						// backdropClassName="childmodal"
						// backdrop="static"
						centered
						style={{ borderRadius: "55px" }}
						// onEntered={() => props.handleEntered}
					>
						<Modal.Body style={{ padding: "20px 20px" }}>
							<div
								style={{ textAlign: "end", cursor: "pointer" }}
								onClick={handleClose3}>
								<img src="./images/closeOne.png"></img>
							</div>
							<div
								className="lightgreen"
								style={{ fontSize: "32px", textAlign: "center" }}>
								Invite your contact to uLektz
							</div>
							<div style={{ fontSize: "18px", textAlign: "center" }}>
								Syncing your contacts is the fastest way to grow your network
							</div>
							<div
								className="card"
								style={{
									padding: "30px 10px",
									margin: "20px 50px 20px 50px",
									background: "#fbfcfd",
								}}>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										fontSize: "18px",
										fontWeight: "400",
										marginBottom: "15px",
									}}>
									Directly invite your email contacts to join on uLektz
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										marginBottom: "20px",
									}}>
									<textarea
										placeholder="Enter email address here,separated by comma"
										className="form-control"
										style={{ width: "400px", border: "1px solid green" }}
										rows="1"></textarea>
								</div>

								<div style={{ display: "flex", justifyContent: "center" }}>
									<Button
										className="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
										// onClick="joinForm('MTMy',this)"
										style={{
											background: "#20ce8f",
											border: "none",
											width: "fix-content",
											padding: "7px 15px",
											borderRadius: "4px",
											minWidth: "100px",
											marginBottom: "20px",
										}}>
										Invite
									</Button>
								</div>

								<div
									className="uc-modal-footer share-footer"
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										padding: "20px 12px 12px 12px",
									}}>
									<div className="uc-inv-shr-icons" style={{ display: "flex" }}>
										<a
											href="https://wa.me/?text= Join Global Food Safety and Sustainability Standards Platform for Networking, Learning and Development on uLektz to connect with members of our community, build new relationships, and get access to members-only resources for education, skills, and careers.Use this invite from me and earn up to ₹1000 for signing up.https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
											className="popover__wrapper icons"
											id="whatsappComRef"
											target="_blank"
											// className="icons"
											rel="noreferrer">
											<img src="./images/WhatsApp.svg" alt="" />
											{/* <div className="popover__content">
                        <p>WhatsApp</p>
                      </div> */}
										</a>

										<a
											href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
											className="popover__wrapper icons"
											id="linkedinComRef"
											target="_blank"
											// className="icons"
											rel="noreferrer">
											<img src="./images/linkedin.svg" alt="" />
											{/* <div className="popover__content">
                        <p>LinkedIn</p>
                      </div> */}
										</a>

										<a
											href="https://www.facebook.com/dialog/share?app_id=604152004970299&amp;display=popup&amp;href=https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
											className="popover__wrapper icons"
											id="facebookComRef"
											target="_blank"
											// className="icons"
											rel="noreferrer">
											<img src="./images/fb.svg" alt="" />
											{/* <div className="popover__content">
                        <p>Facebook</p>
                      </div> */}
										</a>

										<a
											href="https://twitter.com/intent/tweet?url=https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4&amp;text= Join Global Food Safety and Sustainability Standards Platform for Networking, Learning and Development on uLektz to connect with members of our community, build new relationships, and get access to members-only resources for education, skills, and careers.Use this invite from me and earn up to ₹1000 for signing up."
											className="popover__wrapper icons"
											id="twitterComRef"
											target="_blank"
											// className="icons"
											rel="noreferrer">
											<img src="./images/twitter.svg" alt="" />
											{/* <div className="popover__content">
                        <p>Twitter</p>
                      </div> */}
										</a>
										<input
											type="hidden"
											value="Join Global Food Safety and Sustainability Standards Platform for Networking, Learning and Development on uLektz to connect with members of our community, build new relationships, and get access to members-only resources for education, skills, and careers.Use this invite from me and earn up to ₹1000 for signing up.https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
											id="copyComRefUrl"
										/>
										<a
											href="javascript: void(0);"
											className="popover__wrapper icons"
											// onClick="copyComUrl();"
											// className="icons"
										>
											<img src="./images/Copy.svg" alt="" />
											{/* <div className="popover__content">
                        <p>Copy message</p>
                      </div> */}
										</a>
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>
					<Modal
						show={show}
						onHide={handleClose}
						dialogClassName="modal-sm-30px"
						size="md"
						backdropClassName="childmodal"
						// backdrop="static"
						centered
						// onEntered={() => props.handleEntered}
					>
						<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
							<div className="row">
								<div className="col-11">
									<h2
										style={{
											fontSize: "18px",
											fontWeight: "500",
											padding: "15px",
										}}>
										Select Image
									</h2>
								</div>
								<div className="col-1" style={{ padding: "15px" }}>
									<img src="./images/closeOne.png"></img>
								</div>
							</div>
							<hr />
							{imageToCropCoverPhoto ? (
								<ImageCropper
									imageToCrop={imageToCropCoverPhoto}
									onImageCropped={(croppedImage) => {
										console.log(croppedImage);
										setCroppedImageCoverPhoto(croppedImage);
									}}
									coverphoto="coverphoto"
								/>
							) : (
								<div
									style={{
										backgroundColor: "#efefef",
										height: "50vh",
										width: "34vw",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<img
										src="./images/cover_image_icon.svg"
										style={{ height: "90px" }}></img>
								</div>
							)}

							<div
								style={{
									fontSize: "13px",
									fontWeight: "300",
									textAlign: "center",
									paddingTop: "10px",
								}}>
								Recommended size - 1920 x 1080px, Max. 2 MB
							</div>
							<div
								className="row"
								style={{ backgroundColor: "#efefef", padding: "10px 10px" }}>
								<div className="col-8">
									<input
										type="file"
										id="file"
										ref={inputFileCoverPhoto}
										style={{ display: "none" }}
										onChange={onUploadFileCoverPhoto}
									/>
									<button
										onClick={onButtonClickCoverPhoto}
										style={{
											background: "#fff",
											border: "none",
											padding: "5px",
											borderRadius: "5px",
										}}>
										<img src="./images/camera.svg" alt="" />{" "}
										<span style={{ fontSize: "12px" }}>Select Photo</span>
									</button>
									{/* <button
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button> */}
								</div>
								<div className="col-2">
									<div>
										<Button
											style={{
												background: "#fff",
												border: "none",
												height: "35px",
												color: "#000",
												border: "1px solid #000",
												borderRadius: "15px",
											}}
											onClick={handleClose}>
											Cancel
										</Button>
									</div>
								</div>
								<div className="col-2">
									<div>
										<Button
											style={{
												background: "#3CCE8F",
												border: "none",
												height: "35px",
												//   width:"40px",
												borderRadius: "15px",
												//   display:"flex",
												//   alignItems:"center",
												//   justifyContent:"end"
											}}>
											Upload
										</Button>
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>
					<Modal
						show={show2}
						onHide={handleClose2}
						dialogClassName="modal-sm-30px"
						size="md"
						backdropClassName="childmodal"
						// backdrop="static"
						centered
						// onEntered={() => props.handleEntered}
					>
						<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
							<div className="row">
								<div className="col-11">
									<h2
										style={{
											fontSize: "18px",
											fontWeight: "500",
											padding: "15px",
										}}>
										Select Image
									</h2>
								</div>
								<div
									className="col-1"
									style={{ padding: "15px", marginLeft: "-20px" }}>
									<img src="./images/closeOne.png"></img>
								</div>
							</div>
							<hr style={{ margin: "0px" }} />
							<ImageCropper
								imageToCrop={imageToCrop}
								onImageCropped={(croppedImage) => {
									setCroppedImage(croppedImage);
								}}
							/>
							{imageToCrop === undefined && (
								<div
									style={{
										//   backgroundColor: "#efefef",
										height: "50vh",
										width: "34vw",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<img
										src="./images/Communities-icon.svg"
										style={{ height: "42vh" }}></img>
								</div>
							)}

							<div
								className="row"
								style={{
									backgroundColor: "#efefef",
									padding: "10px 10px 10px 10px",
								}}>
								<div className="col-8">
									<input
										type="file"
										id="file"
										ref={inputFile}
										style={{ display: "none" }}
										onChange={onUploadFile}
									/>
									<button
										onClick={onButtonClick}
										style={{
											background: "#fff",
											border: "none",
											padding: "5px",
											borderRadius: "5px",
										}}>
										<img src="./images/camera.svg" alt="" />{" "}
										<span style={{ fontSize: "12px" }}>Select Photo</span>
									</button>
									{/* <button
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button> */}
								</div>
								<div className="col-2"></div>
								<div className="col-2">
									<div>
										<Button
											style={{
												background: "#3CCE8F",
												border: "none",
												height: "35px",
												//   width:"40px",
												borderRadius: "15px",
												//   display:"flex",
												//   alignItems:"center",
												//   justifyContent:"end"
											}}>
											Upload
										</Button>
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>
					<Modal
						show={showJoinModal}
						onHide={handleCloseJoinModal}
						dialogClassName="modal-sm-30px"
						size="md"
						backdropClassName="childmodal"
						// backdrop="static"
						centered
						// onEntered={() => props.handleEntered}
					>
						<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
							<div className="row">
								<div className="col-11" style={{ paddingLeft: "0px" }}>
									<h2
										style={{
											fontSize: "18px",
											fontWeight: "500",
											padding: "10px",
											marginBottom: "0px",
											display: "flex",
										}}>
										Join - Nuclear Physics &nbsp;
										<span
											className="public-text"
											// onClick="communityChangeNew(5421)"
										>
											<span className="uc-star-count">
												<a
													href="javascript:void(0)"
													className="cmn-btn uc-normal-btn">
													<img src="./images/Public_icon.svg" /> Public
												</a>
											</span>
										</span>{" "}
									</h2>
								</div>
								<div
									className="col-1"
									style={{ padding: "10px", cursor: "pointer" }}
									onClick={handleCloseJoinModal}>
									<img src="./images/closeOne.png"></img>
								</div>
							</div>
							<hr style={{ marginTop: "0px", marginBottom: "0px" }} />
							<div
								className="uc-pro-left-img"
								style={{ padding: "20px 40px", textAlign: "center" }}>
								{croppedImageProfilePhoto === undefined ? (
									<img
										src="./images/krishigaplogo.png"
										id="createComprofileimg"
										alt=""
										className="uc-profile-images clsProfileImg"
										style={{
											height: "112px",
											width: "112px",
											borderRadius: "50%",
											border: "1px solid #ddd",
										}}
									/>
								) : (
									<img
										src={URL.createObjectURL(croppedImageProfilePhoto)}
										id="createComprofileimg"
										alt=""
										className="uc-profile-images clsProfileImg"
										style={{
											height: "112px",
											width: "112px",
											borderRadius: "50%",
											border: "1px solid #ddd",
										}}
									/>
								)}

								<label className="uc-cam-icon">
									<img
										id="cameraicon"
										src="./images/camera.svg"
										alt=""
										style={{
											backgroundColor: "#E4E3E0",
											marginLeft: "-30px",
											padding: "6px 6px",
											borderRadius: "40%",
											marginTop: "75px",
										}}
										onClick={handleOpenprofilePhotoModal}
									/>
								</label>
							</div>
							<div style={{ padding: "15px 45px 10px" }}>
								<label for="name" style={{ paddingBottom: "5px" }}>
									Name*
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Name"></input>
								<label
									for="name"
									style={{ paddingTop: "10px", paddingBottom: "5px" }}>
									Profile Headline*
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Profile Headline"></input>
								<label
									for="name"
									style={{ paddingTop: "10px", paddingBottom: "5px" }}>
									Location*
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Location"></input>
								<label
									for="name"
									style={{ paddingTop: "10px", paddingBottom: "5px" }}>
									Email*
								</label>
								<input
									type="email"
									className="form-control"
									placeholder="Enter Email"></input>
								<label
									for="name"
									style={{ paddingTop: "10px", paddingBottom: "5px" }}>
									Mobile Number*
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Mobile Number"></input>
								<label
									for="name"
									style={{ paddingTop: "10px", paddingBottom: "5px" }}>
									Select Role*
								</label>
								<select
									className="form-control select_role select-hidden"
									id="select_role"
									name="formFieldName[select_role]"
									required=""
									profession-selected="0">
									<option value="">Select</option>
									<option value="4">Member</option>
									<option value="17">Alumni/Guest</option>
								</select>
							</div>
							<div
								className="row"
								style={{
									padding: "15px 15px 15px 15px",
									display: "flex",
									alignItems: "center",
									background: "#F1F1F1",
									//   marginLeft: "0px",
									//   marginRight:"0px"
								}}>
								<div className="col-9"></div>
								<div className="col-3">
									<span
										className="col-1"
										style={{
											//   border: "1px solid #ddd",
											borderRadius: "5px",
											padding: "4px 15px 6px 20px",
											//   width: "auto",
											paddingLeft: "20px",
											color: "#fff",
											background: "#E23F1C",
										}}>
										+ Join
									</span>
								</div>
							</div>
						</Modal.Body>
					</Modal>

					<Modal
						show={showProfilePhotoModal}
						onHide={handleCloseProfilePhotoModal}
						dialogClassName="modal-sm-30px"
						size="md"
						backdropClassName="childmodal"
						// backdrop="static"
						centered
						// onEntered={() => props.handleEntered}
					>
						<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
							<div className="row">
								<div className="col-11">
									<h2
										style={{
											fontSize: "18px",
											fontWeight: "500",
											padding: "15px",
										}}>
										Profile Photo
									</h2>
								</div>
								<div className="col-1" style={{ padding: "15px" }}>
									<img src="./images/closeOne.png"></img>
								</div>
							</div>
							<hr />
							{imageToCropProfilePhoto ? (
								<ImageCropper
									imageToCrop={imageToCropProfilePhoto}
									onImageCropped={(croppedImage) => {
										console.log(croppedImage);
										setCroppedImageProfilePhoto(croppedImage);
									}}
									coverphoto="coverphoto"
								/>
							) : (
								<div
									style={{
										backgroundColor: "#efefef",
										height: "50vh",
										width: "34vw",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<img
										src="./images/profile.svg"
										style={{ height: "90px" }}></img>
								</div>
							)}

							<div
								style={{
									fontSize: "13px",
									fontWeight: "300",
									textAlign: "center",
									paddingTop: "10px",
								}}>
								Recommended size - 1920 x 1080px, Max. 2 MB
							</div>
							<div
								className="row"
								style={{ backgroundColor: "#efefef", padding: "10px 10px" }}>
								<div className="col-8">
									<input
										type="file"
										id="file"
										ref={inputFileProfilePhoto}
										style={{ display: "none" }}
										onChange={onUploadFileProfilePhoto}
									/>
									<button
										onClick={onButtonClickProfilePhoto}
										style={{
											background: "#fff",
											border: "none",
											padding: "5px",
											borderRadius: "5px",
										}}>
										<img src="./images/camera.svg" alt="" />{" "}
										<span style={{ fontSize: "12px" }}>Select Photo</span>
									</button>
									{/* <button
                style={{
                  background: "#fff",
                  border: "none",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="https://www.ulektz.com/assets/img/home/camera.svg"
                  alt=""
                />{" "}
                <span style={{ fontSize: "12px" }}>Select Photo</span>
              </button> */}
								</div>
								<div className="col-2">
									<div>
										<Button
											style={{
												background: "#fff",
												border: "none",
												height: "35px",
												color: "#000",
												border: "1px solid #000",
												borderRadius: "15px",
											}}
											onClick={handleCloseProfilePhotoModal}>
											Cancel
										</Button>
									</div>
								</div>
								<div className="col-2">
									<div>
										<Button
											style={{
												background: "#3CCE8F",
												border: "none",
												height: "35px",
												//   width:"40px",
												borderRadius: "15px",
												//   display:"flex",
												//   alignItems:"center",
												//   justifyContent:"end"
											}}>
											Upload
										</Button>
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>
				</>
			) : (
				<LoginRequest />
			)}
		</>
	);
};

export default Network;
