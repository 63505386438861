import React from "react";
import Footer from "../common/footer";
// import NewNavbar from "./newNavbar";

const Fpos = () => {
	return (
		<>
			{/* <NewNavbar /> */}
			<div class="container-fluid bg-primary py-5 mb-5 page-header">
				<div class="container py-5">
					<div class="row justify-content-center">
						<div class="col-lg-10 text-center">
							<h1 class="display-3 text-white animated slideInDown">Events</h1>
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb justify-content-center">
									<li class="breadcrumb-item">
										<a class="text-white" href="index.php">
											Home
										</a>
									</li>
									<li
										class="breadcrumb-item text-white active"
										aria-current="page">
										Events
									</li>
								</ol>
								<a href="/home" class="btn btn-success btn-sm">
									Go Back
								</a>
							</nav>
						</div>
					</div>
				</div>
			</div>
			<div class="container">
				<div class="page-detail-line">
					<p>
						FPOs INDUSTRY PARTNERSHIP PROGRAM-{" "}
						<strong>12TH OCTOBER 2023 : </strong>
					</p>
					<br />
					<p>
						Mr.Kotela Srihari, Founder Krishigap Digital Solutions. Speaking at
						FPO'S: Industry Partnership Program, Hyderabad, Organized by Indian
						Chambers of Food and Agriculture, NABARD and SFAC
					</p>
				</div>
				<div class="row">
					<div class="col-md-3">
						<img
							src="./images/387808386_122103382676073381_3287361327232021098_n.jpg"
							alt=""
							style={{ width: "275px" }}
						/>
					</div>
					<div class="col-md-3">
						<img
							src="./images/387818306_122103382610073381_1332078765598969753_n.jpg"
							alt=""
							style={{ width: "275px" }}
						/>
					</div>
					<div class="col-md-3">
						<img
							src="./images/387839522_122103382580073381_4160206242867344493_n.jpg"
							alt=""
							style={{ width: "275px" }}
						/>
					</div>
					<div class="col-md-3">
						<img
							src="./images/391551145_122103382526073381_2938081320028843314_n.jpg"
							alt=""
							style={{ width: "275px" }}
						/>
					</div>
				</div>
			</div>
			<div>
				<Footer className="jnav"></Footer>
			</div>
		</>
	);
};

export default Fpos;
