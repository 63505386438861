import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Dr_abraham_verghese = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/DrAbrahamVerghese.png"
						alt=""
					/>

					<h4 className="">Dr. Abraham Verghese</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Chairman, International Phytosanitary Research & Services.
							</li>
							<li> Editor-in-Chief, Insect Environment.</li>
							<li>
								Asian Representative, Internal Steering Committee on Fruit Fly.
							</li>{" "}
							<br />
						</ul>
						<b>Past</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Director, ICAR-National Bureau of Agricultural Insect Resources
								(NBAIR), Bangalore
							</li>
							<li>
								Principal Scientist & Head, Entomology ICAR-Indian Institute of
								Horticultural Research Bangalore.
							</li>{" "}
							<li>Chief Editor, Pest Management in Horticultural Ecosystem.</li>{" "}
							<li>
								National Project Coordinator, India–UK-Integrated Management of
								Fruit Flies in India.
							</li>
							<li>
								Member of FAO Asia Pacific Phytosanitary Committee, Bangkok,
								Rome.
							</li>{" "}
							<li>
								IPM Consultant, for Agriculture and Processed Food Process
								Export Development Authority (APEDA).
							</li>{" "}
						</ul>
						<br />
						<b>Awards</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Life Time Achievement Award at the WORLD BIODIVERSITY CONGRESS,
								University of Sri Lanka.
							</li>
							<li>
								Life Time Achievement Award, by Institution of Agricultural
								Technology, Bangalore-2019.
							</li>
							<li>
								Biotech Product and Process Development and Commercialization
								Award by Department of Biotechnology.
							</li>{" "}
							<li>
								AusAID International Award from Australia for his work in grape
								trips management.
							</li>
							<li>
								International Biodiversity and Environmental Award in
								Professional Category by International Congress on Biodiversity,
								Serbia.
							</li>{" "}
						</ul>
						<br />
						<b>Education</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								M.Sc (Agri)
							</li>{" "}
							<li>
								Ph.D, FRES (UK), Post-doc training at Imperial College London,
								UK
							</li>{" "}
							<li>Gold Medal for first Rank in both M.Sc. and Ph.D.</li>{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Dr_abraham_verghese;
