import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Dr_amar_nath_sharma = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/amar-nath-sharma.jpg"
						alt=""
					/>

					<h4 className="">Dr Amar Nath Sharma</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Founder member and General Secretary of Society for Soybean
								Research and Development at ICAR-Indian Institute of Soybean
								Research, Indore, India.
							</li>{" "}
							<br />
						</ul>
						<b>Past</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Worked as a Consultant with Solidaridad Regional Expertise
								Center (SREC) under Solidaridad Asia Network, New Delhi (From
								July 2020 to March 2023) to provide technical support to
								strengthen ongoing Smart Agri Project (SAP) in Soybean which is
								based on LOT, sensors and development of advisory based on the
								parameters and information received through SAP platform etc.
							</li>
							<li>Head, Crop Protection and I/C AICRP on Soybean.</li>{" "}
							<li>Principal Investigator of AICRP on Soybean (Entomology).</li>{" "}
						</ul>
						<br />
						<b>Recognitions</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Life Member of Indian Society of Oilseeds Research, Directorate
								of Oilseed Research, Hyderabad, India.
							</li>
							<li>
								Member of a high-level team constituted by the ICAR, Govt. of
								India, for surveying the soybean areas in the States of
								Rajasthan (2000), Madhya Pradesh (2003) and Maharashtra (2008)
								affected by Spodoptera lituradamage.
							</li>
							<li>
								Expert-member in the State level Steering Committee of
								“Awareness-cum-surveillance program for major pests of soybean
								in soybean-cotton based cropping system in Maharashtra.
							</li>{" "}
							<li>
								Member of Technical Committee to formulate Indian Standards for
								Sustainable Soybean (ISSS).
							</li>{" "}
						</ul>
						<br />
						<b>Education</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								M.Sc. (Zoology),Barkatulla University, Bhopal, MP.
							</li>{" "}
							<li>
								Ph.D. (Agricultural Entomology), GB Pant University, Pantnagar.
							</li>{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Dr_amar_nath_sharma;
