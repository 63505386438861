import React from "react";
import Footer from "../common/footer";
import NewNavbar from "../pages/newNavbar";

const Sustainableiniatives = () => {
	return (
		<>
			<NewNavbar />
			<div className="row">
				<div className="col" style={{ height: "386px" }}>
					<img
						src="../images/homebanner.jpg"
						style={{ width: "", height: "" }}
					/>
				</div>
			</div>

			<div
				class="container-xxl py-5"
				style={{
					paddingTop: "0rem !important",
					paddingBottom: "0rem !important",
				}}>
				<div class="container">
					<h1 class="text-center">Sustainable Standards</h1>

					<ul class="text-justify">
						<li>
							In this section, we have covered. ISO 14001 Environmental
							Management, ISO 50001 Energy Management ISO 45001 Occupational
							health and safety SA 8000 Social Accountability International
							Rainforest Alliance Standard-wise Quality Manuals, Procedures,
							Record templates, Auditor's qualifications, Workers' Health and
							Welfare, and Government promotion schemes are available.
						</li>
						<li>
							{" "}
							You can search for the required information standard-wise by
							clicking on the respective icons.
						</li>
						<li>
							{" "}
							Additionally, we offer skill development programs on the
							implementation of these standards and the qualification of
							auditors, conducted digitally.
						</li>
					</ul>

					<div class="row g-4">
						<div class="col-md-12">
							<div class="row">
								<div
									class="col-lg-3 col-sm-6 wow fadeInUp"
									data-wow-delay="0.1s"
									style={{
										visibility: "visible",
										animationDelay: "0.1s",
										animationName: "fadeInUp",
									}}>
									<div class="cardbgc hovercard text-center position-relative">
										<a href="food-safety-standards_SI.php">
											<div class="m-img">
												<img
													style={{ height: "auto", width: "100%" }}
													src="./images/foodsafety.jpg"
												/>
											</div>
											<div class="fontWhite">Standards</div>
										</a>
									</div>
								</div>
								<div
									class="col-lg-3 col-sm-6 wow fadeInUp"
									data-wow-delay="0.1s"
									style={{
										visibility: "visible",
										animationDelay: "0.1s",
										animationName: "fadeInUp",
									}}>
									<div class="cardbgc hovercard text-center position-relative">
										<a href="training-module.php">
											<div class="m-img">
												<img
													style={{ height: "auto", width: "100%" }}
													src="./images/skills-development.jpg"
												/>
											</div>
											<div class="fontWhite">Skills Development</div>
										</a>
									</div>
								</div>

								<div
									class="col-lg-3 col-sm-6 wow fadeInUp"
									data-wow-delay="0.1s"
									style={{
										visibility: "visible",
										animationDelay: "0.1s",
										animationName: "fadeInUp",
									}}>
									<div class="cardbgc hovercard text-center position-relative">
										<a href="internal-audit_SI.php">
											<div class="m-img">
												<img
													style={{ height: "auto", width: "100%" }}
													src="./images/internalaudit.jpg"
												/>
											</div>
											<div class="fontWhite">Internal Audit</div>
										</a>
									</div>
								</div>

								<div
									class="col-lg-3 col-sm-6 wow fadeInUp"
									data-wow-delay="0.1s"
									style={{
										visibility: "visible",
										animationDelay: "0.1s",
										animationName: "fadeInUp",
									}}>
									<div class="cardbgc hovercard text-center position-relative">
										<a href="workers-health_SI.php">
											<div class="m-img">
												<img
													style={{ height: "auto", width: "100%" }}
													src="./images/workershealth.jpg"
												/>
											</div>
											<div class="fontWhite">
												Workers Health, Safety &amp; Welfare
											</div>
										</a>
									</div>
								</div>

								<div
									class="col-lg-3 col-sm-6 wow fadeInUp"
									data-wow-delay="0.1s"
									style={{
										visibility: "visible",
										animationDelay: "0.1s",
										animationName: "fadeInUp",
									}}>
									<div class="cardbgc hovercard text-center position-relative">
										<a href="other-option_SI.php">
											<div class="m-img">
												<img
													style={{ height: "auto", width: "100%" }}
													src="./images/farm.jpg"
												/>
											</div>
											<div class="fontWhite">
												Government Promoting Institutions
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div>
				<Footer className="jnav"></Footer>
			</div>
		</>
	);
};

export default Sustainableiniatives;
