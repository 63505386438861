import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Ms_chetna_ipar = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						// class="img-fluid"
						src="../images/Chetna_Ipar.jpg"
						alt=""
					/>
					<h4 className="">Ms. Chetna Ipar</h4>
					<p className="fs"> Food Safety, Quality and Regulatory Compliances</p>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Food Safety, Quality and Regulatory Consultant and Trainer
								(On-site and Off-site).
							</li>
							<li> PCQI Lead Instructor (USFDA FSPCA Recognized).</li>
							<li>
								{" "}
								BRCGS Global Standard Food Safety Issue 9 Lead Auditor Training,
								Auditor Training and Sites Training, Conversion for Auditors,
								Conversion for Sites.{" "}
							</li>
							<li> FSSC Lead Auditor. </li>
							<li>
								{" "}
								BRCGS Product Safety Management Courses such as Hazard Analysis
								and Critical Control Points (HACCP), Hazard Analysis and
								Risk-Based Preventive Controls (HARPC), Hazard Analysis and Risk
								Assessment (HARA), Internal Auditor, Root Cause Analysis, Risk
								Assessment, Validation and Verification, Vulnerability
								Assessment for Food Fraud, Environmental Monitoring.
							</li>
							<li>
								{" "}
								BRCGS Global Standard Food Safety Issue 9 Conversion for
								Auditors, Conversion for Sites, Auditor Training, Lead Auditor
								Training and Sites Training.{" "}
							</li>
							<li>USFDA Preventive Control of Human Food (PCQI). </li>
							<li> FSSAI based Quality and Regulatory requirements.</li>
							<br />
						</ul>
						<br />
						<b>
							Designing Factory Compliances with regards to Audit Requirements
						</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Food Safety Management System (HACCP, VACCP, TACCP and relevant
								systems).
							</li>
							<li>Hazard Analysis and Risk Assessment Plan.</li>{" "}
							<li>Quality Compliance and Documentation Guidance.</li>{" "}
							<li> Quality Assurance, GMP, GHP.</li>
						</ul>
						<br />
						<span className="fs" style={{ fontWeight: 500 }}>
							{" "}
							Conducting 2nd Party and 3rd Party Audits across the Food and
							Beverage Industry as per GFSI requirements, UNICEF, WHO and World
							Food Program (WFP).
						</span>
						<br />
						<br />
						<span className="fs" style={{ fontWeight: 500 }}>
							Regulatory Support as per USFDA, EFSA, Codex Alimentarius, FSSAI.
						</span>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Label Declarations.
							</li>{" "}
							<li>Product Guidelines and Categorization.</li>{" "}
							<li> Import and Export Guidelines and Compliances.</li>
						</ul>
						<br />
						<b>Past</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Worked with Nestle India and France; Ernst and Young; Hexagon
								Nutrition Private Limited in the departments of Product, Quality
								and Regulatory Compliance.
							</li>
							<li>
								Handled products in the categories of Chocolates and
								Confectionery, Dairy, Coffee and Beverages, Extruded Products
								and Snacks, Sauces and Seasonings, Functional Ingredients
								Nutraceuticals (Food for Special Dietary Uses; Food for Special
								Medical Purpose; Health Supplements; Ready to use Therapeutic
								Foods and Ready to Use Supplementary Foods; Fortified Rice
								Kernels, Vitamin and Mineral Premixes).
							</li>{" "}
						</ul>
						<br />
						<b>Education</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Masters in Food Technology (Innovation and Management) -
								Wageningen University (The Netherlands).
							</li>{" "}
							<li>
								Bachelor of Technology (Food Engineering and Technology) –
								Institute of Chemical Technology, Mumbai (Formerly, U.D.C.T).
							</li>
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Ms_chetna_ipar;
