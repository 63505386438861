import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Shashi_sareen = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/Ca-ramchandra.jpg"
						alt=""
					/>

					<h4 className="">CA Ramachandra Rao Tummala</h4>
					<p className="fs">Finance, Systems & Compliance Expert</p>
					<p className="fs">Founder :T R R & Associates</p>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<p className="fs">
							T R R & Associates, a chartered Accountants firm based out of
							Hyderabad, engaged to provide services in the areas of Audit,
							Investigation, Due Diligence, Accounting outsourcing, Taxation
							(Direct and Indirect), Company Law Matters for both domestic and
							foreign companies, Management Consultancy, Fixed Assets
							Verification, Information System Audit and FEMA.
						</p>

						<b>Past</b>
						<br />

						<ul className="mx-5 fs">
							<li>
								SAP Technical Architect/Consultant – FICO Module (NTT Data ,
								Intelligroup Asia Pvt Ltd)
							</li>
							<li>Finance Controller – GE (Lighting Division)</li>
						</ul>
						<br />
						<b>TECHNOLOGY BACKGROUND:</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Over 15 years of Industry experience in Core Controllership
								Finance (GE and NTTData)
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								SAP Consulting Experience ( Intelligroup Asia Pvt Ltd)
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								E2E SAP Implementation.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Hands on experience in accounting & controlling Audit, Internal
								and System Controls,Taxation,all commercial aspects of business.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								B1 USA VISA Holder-valid till 2023.
							</li>{" "}
						</ul>
						<br />
						<b>INTERNATIONAL EXPOSURE:</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Handled E2E SAP Implementation for NTT Data Inc- Assignment
								handled on site at Boston & Philadelphia in 2013
							</li>{" "}
						</ul>
						<br />

						<b>Educaation</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Chartered Accountant – FCA
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Bachelor of Commerce from Andhra University.
							</li>{" "}
						</ul>
						<br />
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Shashi_sareen;
