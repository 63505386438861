import React from "react";
import Footer from "../common/footer";
import NewNavbar from "../pages/newNavbar";

const Newsread = () => {
	return (
		<>
			<NewNavbar />
			<div class="container-fluid bg-primary py-5 mb-5 page-header">
				<div class="container py-5">
					<div class="row justify-content-center">
						<div class="col-lg-10 text-center">
							<h1 class="display-3 text-white animated slideInDown">News</h1>
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb justify-content-center">
									<li class="breadcrumb-item">
										<a class="text-white" href="index.php">
											Home
										</a>
									</li>
									<li
										class="breadcrumb-item text-white active"
										aria-current="page">
										News
									</li>
								</ol>
								<a href="/home" class="btn btn-success btn-sm">
									Go Back
								</a>
							</nav>
						</div>
					</div>
				</div>
			</div>
			<div
				class="container-xxl py-5"
				style={{
					paddingTop: "0rem !important",
					paddingBottom: "0rem !important",
				}}>
				<div class="container">
					<div class="row">
						<div class="page-detail-line">
							Hon'ble Prime Minister Vision for Global Standards is being
							fulfilled. India Good Agricultural Practices(IndG.A.P) became
							equivalent to Global G.A.P ( www.globalgap.org), facilitating the
							export of Fruits and Vegetables from India to over 120 countries.
							It will boost the income of farmers and food processors
							Benchmarking Recognition letter from Global G.A.P is attached
						</div>
						<center>
							<img src="./images/newsletter.jpg" class="page-detail-line" />
						</center>
					</div>
				</div>
			</div>
			<div>
				<Footer className="jnav"></Footer>
			</div>
		</>
	);
};

export default Newsread;
