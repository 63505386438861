import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Mr_Sudhakar = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						// class="img-fluid"
						src="../images/sudhakar.jpg"
						alt="Mr Sudhakar Gande"
					/>

					<h4 className="">Mr Sudhakar Gande</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>

						<p className="fs">
							Currently holds multiple positions, including
							Co-Chairman-Aerospace & Air Defense Task Force at Federation of
							Indian Chambers of Commerce & Industry and other directorships.
						</p>

						<br />
						<b>Past</b>
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Senior Director at American Express Bank Ltd
							</li>
							<li>
								{" "}
								<li>
									{" "}
									Mr. Gande previously served as Vice Chairman at Axiscades
									Aerospace & Technologies Pvt Ltd
								</li>
							</li>
							<li> Chairman at FICCI-Karnataka State Council</li>
							<li> Vice Chairman at AXISCADES Technologies Ltd.,</li>

							<br />
						</ul>
						<b>Education:</b>
						<br />
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								Graduate degree at Indian Institute of Technology New Delhi and
								MBA at Faculty of Management Studies.
							</li>
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Mr_Sudhakar;
