const Eventlanding = () => {
	return (
		<>
			<div
				className="row "
				// style={{ background: "", paddingLeft: "50px", paddingTop: "50px" }}
			>
				<div className="col">
					<div
						style={{
							// fontFamily: "Manrope",
							// fontSize: "52px",
							// fontWeight: "600",
							lineHeight: "52px",
							fontSize: "34px",
							fontWeight: "500",
							color: "#1F6644",
						}}>
						Latest Events
					</div>
					<div className="row">
						<div
							className="fs"
							style={{
								// fontFamily: "Manrope",
								fontSize: "14px",
								fontWeight: "400",
								lineHeight: "14px",
								textAlign: "left",
							}}>
							Our Loyalty and Rewards solution helps maximize customer
							retention, drive more sales and reduce acquisition costs
						</div>
					</div>
				</div>
			</div>

			<div
				class="row row-cols-1 row-cols-lg-4 g-4"
				style={{ paddingTop: "2%" }}>
				<div class="col-md-4">
					<div class="card h-p100">
						<div
							id="carouselExampleIndicators02"
							class="carousel slide carousel-fade"
							data-bs-ride="carousel"
							data-bs-interval="5000">
							<ol class="carousel-indicators">
								<li
									data-bs-target="#carouselExampleIndicators02"
									data-bs-slide-to="0"
									class="active"></li>
								<li
									data-bs-target="#carouselExampleIndicators02"
									data-bs-slide-to="1"></li>
								<li
									data-bs-target="#carouselExampleIndicators02"
									data-bs-slide-to="2"></li>
								<li
									data-bs-target="#carouselExampleIndicators02"
									data-bs-slide-to="3"></li>
								<li
									data-bs-target="#carouselExampleIndicators02"
									data-bs-slide-to="4"></li>
								<li
									data-bs-target="#carouselExampleIndicators02"
									data-bs-slide-to="5"></li>
								<li
									data-bs-target="#carouselExampleIndicators02"
									data-bs-slide-to="6"></li>
								<li
									data-bs-target="#carouselExampleIndicators02"
									data-bs-slide-to="7"></li>
							</ol>
							<div class="carousel-inner">
								<div class="carousel-item active">
									<img
										class="d-block w-100"
										src="../images/Nutrihub-ICAR-IIMR-Webinar.jpg"
										alt="Nutrihub ICAR IIMR Webinar"
									/>
								</div>
							</div>
							<a
								class="carousel-control-prev"
								href="#carouselExampleIndicators02"
								data-bs-target="#carouselExampleIndicators02"
								role="button"
								data-bs-slide="prev">
								<span
									class="carousel-control-prev-icon"
									aria-hidden="true"></span>
							</a>
							<a
								class="carousel-control-next"
								href="#carouselExampleIndicators02"
								data-bs-target="#carouselExampleIndicators02"
								role="button"
								data-bs-slide="next">
								<span
									class="carousel-control-next-icon"
									aria-hidden="true"></span>
							</a>
						</div>
						<div class="card-body mt-3">
							{/* <!-- <h4 class="card-title b-0 px-0">Card title</h4> --> */}
							<p
								style={{
									fontWeight: "700",
									fontSize: "15px",
									paddingBottom: "10px",
								}}>
								KrishiGap organized a National Webinar on September 25th,2023 on
								global food safety standards for startups in associ...{" "}
							</p>
							<div class="" style={{ textAlign: "center" }}>
								<button
									style={{
										width: "80px",
										height: "30px",
										borderRadius: "100px",
										// background: "#000000",
										background: "#0266001A",
										border: "none,",
									}}>
									<div
										className=""
										style={{ color: "green", fontSize: "12px" }}>
										Read More
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card h-p100">
						<div
							id="carouselExampleIndicators01"
							class="carousel slide carousel-fade"
							data-bs-ride="carousel"
							data-bs-interval="5000">
							<ol class="carousel-indicators">
								<li
									data-bs-target="#carouselExampleIndicators01"
									data-bs-slide-to="0"
									class=""></li>
								<li
									data-bs-target="#carouselExampleIndicators01"
									data-bs-slide-to="1"
									class=""></li>
								<li
									data-bs-target="#carouselExampleIndicators01"
									data-bs-slide-to="2"
									class="active"
									aria-current="true"></li>
								<li
									data-bs-target="#carouselExampleIndicators01"
									data-bs-slide-to="3"
									class=""></li>
								<li
									data-bs-target="#carouselExampleIndicators01"
									data-bs-slide-to="4"></li>
							</ol>
							<div class="carousel-inner">
								<div class="carousel-item">
									<img
										class="d-block w-100"
										src="../images/387808386_122103382676073381_3287361327232021098_n.jpg"
										alt="First slide"
									/>
								</div>
								<div class="carousel-item">
									<img
										class="d-block w-100"
										src="../images/387818306_122103382610073381_1332078765598969753_n.jpg"
										alt="Second slide"
									/>
								</div>

								<div class="carousel-item active">
									<img
										class="d-block w-100"
										src="../images/387839522_122103382580073381_4160206242867344493_n.jpg"
										alt="Third slide"
									/>
								</div>

								<div class="carousel-item">
									<img
										class="d-block w-100"
										src="../images/391551145_122103382526073381_2938081320028843314_n.jpg"
										alt="Fourth slide"
									/>
								</div>
							</div>
							<a
								class="carousel-control-prev"
								href="#carouselExampleIndicators01"
								data-bs-target="#carouselExampleIndicators01"
								role="button"
								data-bs-slide="prev">
								<span
									class="carousel-control-prev-icon"
									aria-hidden="true"></span>
							</a>
							<a
								class="carousel-control-next"
								href="#carouselExampleIndicators01"
								data-bs-target="#carouselExampleIndicators01"
								role="button"
								data-bs-slide="next">
								<span
									class="carousel-control-next-icon"
									aria-hidden="true"></span>
							</a>
						</div>
						<div class="card-body mt-3">
							{/* <!-- <h4 class="card-title b-0 px-0">Card title</h4> --> */}
							<p
								style={{
									fontWeight: "700",
									fontSize: "15px",
									paddingBottom: "10px",
								}}>
								Mr.Kotela Srihari, Founder Krishigap Digital Solutions. Speaking
								at FPO'S: Industry Partnership Program, Hydera...{" "}
							</p>
							<div class="" style={{ textAlign: "center" }}>
								<button
									style={{
										width: "80px",
										height: "30px",
										borderRadius: "100px",
										// background: "#000000",
										background: "#0266001A",
										border: "none,",
									}}>
									<div
										className=""
										style={{ color: "green", fontSize: "12px" }}>
										Read More
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card h-p100">
						<div
							id="carouselExampleIndicators03"
							class="carousel slide carousel-fade"
							data-bs-ride="carousel"
							data-bs-interval="5000">
							<ol class="carousel-indicators">
								<li
									data-bs-target="#carouselExampleIndicators03"
									data-bs-slide-to="0"
									class="active"
									aria-current="true"></li>
								<li
									data-bs-target="#carouselExampleIndicators03"
									data-bs-slide-to="1"
									class=""></li>
								<li
									data-bs-target="#carouselExampleIndicators03"
									data-bs-slide-to="2"
									class=""></li>
								<li
									data-bs-target="#carouselExampleIndicators03"
									data-bs-slide-to="3"
									class=""></li>
								<li
									data-bs-target="#carouselExampleIndicators03"
									data-bs-slide-to="4"
									class=""></li>
								<li
									data-bs-target="#carouselExampleIndicators03"
									data-bs-slide-to="5"
									class=""></li>
								<li
									data-bs-target="#carouselExampleIndicators03"
									data-bs-slide-to="6"></li>
							</ol>
							<div class="carousel-inner">
								<div class="carousel-item active">
									<img
										class="d-block w-100"
										src="../images/Image-5.jpg"
										alt="First slide"
									/>
								</div>
								<div class="carousel-item">
									<img
										class="d-block w-100"
										src="../images/Image-6.jpg"
										alt="Second slide"
									/>
								</div>

								<div class="carousel-item">
									<img
										class="d-block w-100"
										src="../images/Image-7.jpg"
										alt="Third slide"
									/>
								</div>

								<div class="carousel-item">
									<img
										class="d-block w-100"
										src="../images/Image-8.jpg"
										alt="Fourth slide"
									/>
								</div>

								<div class="carousel-item">
									<img
										class="d-block w-100"
										src="../images/Image-9.jpg"
										alt="Fifth slide"
									/>
								</div>

								<div class="carousel-item">
									<img
										class="d-block w-100"
										src="../images/Image-10.jpg"
										alt="Sixth slide"
									/>
								</div>
							</div>
							<a
								class="carousel-control-prev"
								href="#carouselExampleIndicators03"
								data-bs-target="#carouselExampleIndicators03"
								role="button"
								data-bs-slide="prev">
								<span
									class="carousel-control-prev-icon"
									aria-hidden="true"></span>
							</a>
							<a
								class="carousel-control-next"
								href="#carouselExampleIndicators03"
								data-bs-target="#carouselExampleIndicators03"
								role="button"
								data-bs-slide="next">
								<span
									class="carousel-control-next-icon"
									aria-hidden="true"></span>
							</a>
						</div>
						<div class="card-body mt-3">
							{/* <!-- <h4 class="card-title b-0 px-0">Card title</h4> --> */}
							<p
								style={{
									fontWeight: "700",
									fontSize: "15px",
									paddingBottom: "10px",
								}}>
								Kotela Srihari speaking at US India SME Council and Montgomery
								County government delegation,Maryland St...{" "}
							</p>
							<div class="" style={{ textAlign: "center" }}>
								<button
									style={{
										width: "80px",
										height: "30px",
										borderRadius: "100px",
										// background: "#000000",
										background: "#0266001A",
										border: "none,",
									}}>
									<div
										className=""
										style={{ color: "green", fontSize: "12px" }}>
										Read More
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-4">
					<div class="card h-p100">
						<div
							id="carouselExampleIndicators04"
							class="carousel slide carousel-fade"
							data-bs-ride="carousel"
							data-bs-interval="5000">
							<ol class="carousel-indicators">
								<li
									data-bs-target="#carouselExampleIndicators04"
									data-bs-slide-to="0"
									class="active"
									aria-current="true"></li>
								<li
									data-bs-target="#carouselExampleIndicators04"
									data-bs-slide-to="1"
									class=""></li>
								<li
									data-bs-target="#carouselExampleIndicators04"
									data-bs-slide-to="2"></li>
							</ol>
							<div class="carousel-inner">
								<div class="carousel-item active">
									<img
										class="d-block w-100"
										src="../images/event-27-12-2023-1.jpg"
										alt="First slide"
									/>
								</div>
								<div class="carousel-item">
									<img
										class="d-block w-100"
										src="../images/event-27-12-2023-2.jpg"
										alt="Second slide"
									/>
								</div>
							</div>

							<a
								class="carousel-control-prev"
								href="#carouselExampleIndicators04"
								data-bs-target="#carouselExampleIndicators04"
								role="button"
								data-bs-slide="prev">
								<span
									class="carousel-control-prev-icon"
									aria-hidden="true"></span>
							</a>
							<a
								class="carousel-control-next"
								href="#carouselExampleIndicators04"
								data-bs-target="#carouselExampleIndicators04"
								role="button"
								data-bs-slide="next">
								<span
									class="carousel-control-next-icon"
									aria-hidden="true"></span>
							</a>
						</div>
						<div class="card-body mt-3">
							{/* <!-- <h4 class="card-title b-0 px-0">Card title</h4> --> */}
							<p
								style={{
									fontWeight: "700",
									fontSize: "15px",
									paddingBottom: "10px",
								}}>
								Exciting News in Agriculture! We're thrilled to share that the
								Quality Council of India has officially launched ...{" "}
							</p>
							<div class="" style={{ textAlign: "center" }}>
								<button
									style={{
										width: "80px",
										height: "30px",
										borderRadius: "100px",
										// background: "#000000",
										background: "#0266001A",
										border: "none,",
									}}>
									<div
										className=""
										style={{ color: "green", fontSize: "12px" }}>
										Read More
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Eventlanding;
