import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";

const Ourteam = () => {
	return (
		<div style={{}}>
			<NewNavbar />
			<div
				style={{
					backgroundImage: "url('../images/globalacademybg.jpg')",
					backgroundRepeat: "no-repeat",
				}}>
				<div
					className="container"
					style={{
						paddingTop: "30px",
						paddingBottom: "30px",
						// backgroundColor: "#ffffff",
					}}>
					<h1 className="" style={{ textAlign: "center" }}>
						Our Team
					</h1>

					{/* <h3 className="">Company formation</h3> */}
					<div className="row">
						<div class="col" style={{}}>
							<p class="mb-4 fs">
								The Company’s leadership team is supported by an experienced
								Advisory Board and domain experts specializing in:
							</p>
							<ul className="mx-5 fs">
								<li>Global Standards in Food Safety and Sustainability.</li>
								<li>Product Development.</li>
								<li>Organic, Dairy, and Aquaculture Value Chains.</li>
								<li>Information Technology.</li>
								<li>Finance.</li>
							</ul>
						</div>
					</div>

					<div id="exTab2">
						<ul class="nav nav-tabs">
							<li class="active">
								<a href="#1" data-toggle="tab">
									Founder
								</a>
							</li>
							<li>
								<a href="#9" data-toggle="tab">
									Co-Founder
								</a>
							</li>
							<li>
								<a href="#10" data-toggle="tab">
									Advisory Board
								</a>
							</li>
							<li>
								<a href="/experts">Experts</a>
							</li>
						</ul>
					</div>

					<div class="tab-content ">
						<div class="tab-pane active" id="1">
							<h3 class="pt-3">Founder</h3>
							<div class="row">
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./srihari_kotela">
													<img
														style={{ width: "231px", height: "231px" }}
														class=""
														src="../images/shri-hari.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-#d8f5c6"
													href="#"
													style={{
														color: "#fff",
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="/srihari_kotela">
												<h5 class="mb-0" style={{ color: "#000" }}>
													Srihari Kotela
												</h5>
											</a>
											<small>
												Expert in Agricultural, Food Safety and Sustainability
												Standards
											</small>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="tab-pane" id="9">
							<h3 class="pt-3 pb-3">Co-Founder</h3>
							<div class="row">
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./surbhi_agarwal">
													<img
														style={{ width: "231px", height: "231px" }}
														class=""
														src="../images/Surbhi_Agarwal.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-#d8f5c6"
													href="#"
													style={{
														color: "#fff",
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="">
												<h5 class="mb-0">Surbhi Agarwal</h5>
											</a>
											<small>Co Founder</small>
											<br />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-pane" id="10">
							<h3 class="pt-3 pb-3">Advisory Board</h3>
							<p>
								The team is assisted by experienced professionals in the
								industry segment
							</p>
							<div class="row">
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./mr_sanjay_dave">
													<img
														style={{ width: "231px", height: "231px" }}
														class=""
														src="../images/MrSanjayDave.png"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-#d8f5c6"
													href="#"
													style={{
														color: "#fff",
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="">
												<h5 class="mb-0">Mr. Sanjay Dave</h5>
											</a>
											<small>Food Safety Specialist</small>
											<br />
											<small>Ex Global Chair Person Codex</small>
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./mr_anil_jauhri">
													<img
														style={{ width: "231px", height: "231px" }}
														class=""
														src="../images/anil-jauhri.png"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-#d8f5c6"
													href="#"
													style={{
														color: "#fff",
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="">
												<h5 class="mb-0">Mr. Anil Jauhri</h5>
											</a>
											<small>Standard Specialist</small>
											<br />
											<small>Ex CEO NABCB</small>
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./ms_shashi_sareen">
													<img
														style={{ width: "231px", height: "231px" }}
														class=""
														src="../images/Ms-Shashi-Sareen.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-#d8f5c6"
													href="#"
													style={{
														color: "#fff",
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="">
												<h5 class="mb-0">Ms Shashi Sareen</h5>
											</a>
											<small>Ex CEO &amp; Director</small>
											<br />
											<small>
												Export Inspection Council of India and Ex Director,
												Bureau of Indian Standards
											</small>
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./dr_leslie">
													<img
														style={{ width: "231px", height: "231px" }}
														class=""
														src="../images/DrLeslieDBourquin.png"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-#d8f5c6"
													href="#"
													style={{
														color: "#fff",
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="">
												<h5 class="mb-0">Dr. Leslie D. Bourquin</h5>
											</a>
											<small>Food Safety Specialist</small>
											<br />
											<small>
												Dept Chairperson Food safety, Michigan State University
											</small>
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./mr_baskar_kotte">
													<img
														style={{ width: "231px", height: "231px" }}
														class=""
														src="../images/BaskarKotte.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-#d8f5c6"
													href="#"
													style={{
														color: "#fff",
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="">
												<h5 class="mb-0">Mr. Baskar Kotte</h5>
											</a>
											<small>Standard Specialist</small>
											<br />
											<small>Founder Quality System Enhancement Inc, USA</small>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./mr_sudhakar">
													<img
														style={{ width: "231px", height: "231px" }}
														class=""
														src="../images/sudhakar.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-#d8f5c6"
													href="#"
													style={{
														color: "#fff",
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./mr_sudhakar">
												<h5 class="mb-0">Mr.&nbsp;Sudhakar&nbsp;Gande</h5>
											</a>
											<small>
												Investment&nbsp;Banker,&nbsp;Tech&nbsp;Entrepreneur
											</small>
											<br />
											<small>
												Ex&nbsp;Sr.&nbsp;Director,&nbsp;American&nbsp;Express&nbsp;Bank
											</small>
											<br />
											<small>
												Former&nbsp;CEO,&nbsp;Jupiter&nbsp;Capital&nbsp;Pvt.&nbsp;Ltd.
											</small>
											<br />
											<small>
												Former&nbsp;Vice&nbsp;Chairman,&nbsp;AxisCades
												Technologies&nbsp;Ltd.
											</small>
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./mr_ramesh_penmetcha">
													<img
														style={{ width: "231px", height: "231px" }}
														class=""
														src="../images/ramesh.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-#d8f5c6"
													href="#"
													style={{
														color: "#fff",
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./mr_ramesh_penmetcha">
												<h5 class="mb-0">Mr.&nbsp;Ramesh&nbsp;Penmetcha</h5>
											</a>
											<small>
												Information&nbsp;Technology&nbsp;Specialist{" "}
											</small>
											<br />
											<small>
												Former&nbsp;MD,&nbsp;New&nbsp;York&nbsp;Stock&nbsp;Exchange{" "}
											</small>
											<br />
											<small>
												Held&nbsp;Senior&nbsp;Leadership&nbsp;roles&nbsp;for&nbsp;over
												&nbsp;three&nbsp;decades{" "}
											</small>
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-6">
									<div class="team-item ">
										<div
											class="overflow-hidden"
											style={{ background: "#d8f5c6" }}>
											<center>
												<a href="./dr_gopi_krishna_santhanam">
													<img
														style={{ width: "231px", height: "231px" }}
														class=""
														src="../images/gopikrishna.jpg"
														alt=""
													/>
												</a>
											</center>
										</div>
										<div
											class="position-relative d-flex justify-content-center"
											style={{ marginTop: "-23px", background: "#d8f5c6" }}>
											<div
												class=" d-flex justify-content-center pt-2 px-1"
												style={{ background: "#d8f5c6" }}>
												<a
													class="btn btn-sm-square  mx-1 text-#d8f5c6"
													href="#"
													style={{
														color: "#fff",
														background: "#00a039",
														borderRadius: "0px",
													}}>
													<i class="fa fa-facebook"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													{" "}
													<i class="fa fa-twitter"></i>
												</a>
												<a
													class="btn btn-sm-square btn-primary mx-1 text-#d8f5c6"
													href="#"
													style={{
														background: "#00a039",
														borderRadius: "0px",
														border: "none",
														display: "flex",
													}}>
													<i class="fa fa-instagram"></i>
												</a>
											</div>
										</div>
										<div
											class="text-center p-4"
											style={{ background: "#d8f5c6" }}>
											<a href="./dr_gopi_krishna_santhanam">
												<h5 class="mb-0">
													Mr.&nbsp;Gopi&nbsp;Krishna&nbsp;Santhanam
												</h5>
											</a>
											<small>
												Information&nbsp;Technology&nbsp;and&nbsp; <br />
												Smart&nbsp;Farming&nbsp;Specialist,{" "}
											</small>
											<br />
											<small>
												Co-Founder&nbsp;and&nbsp;CEO,&nbsp;Ascentya,
												<br />
												&nbsp;R&D&nbsp;Solutions&nbsp;Pvt&nbsp;Ltd
											</small>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
export default Ourteam;
