import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
export default function Jobs() {
	return (
		<>
			<NewNavbar />
			<div
				style={{
					backgroundImage: "url('../images/globalacademybg.jpg')",
					backgroundRepeat: "no-repeat",
				}}>
				<div
					className="container"
					style={{ paddingTop: "30px", paddingBottom: "30px" }}>
					<h1 className="">Placement Opportunities in the Food Supply Chain</h1>

					<div className="row">
						<div class="col" style={{}}>
							<ul className="mx-5" style={{ paddingLeft: "50px" }}>
								<li
									style={{
										listStyle: "numeric",
										fontSize: "20px",
										fontWeight: "500",
									}}>
									<b> Industry Connections</b>: Facilitate partnerships between
									quality personnel/students and food supply chain companies for
									job opportunities and internships.
								</li>

								<li
									style={{
										listStyle: "numeric",
										fontSize: "20px",
										fontWeight: "500",
									}}>
									{" "}
									<b>Career Readiness</b>: Equip students with essential skills
									and knowledge to ensure they are job ready.
								</li>

								<li
									style={{
										listStyle: "numeric",
										fontSize: "20px",
										fontWeight: "500",
									}}>
									<b> Networking</b>: Create a platform for students to connect
									with industry professionals and mentors, fostering valuable
									relationships.
								</li>

								<li
									style={{
										listStyle: "numeric",
										fontSize: "20px",
										fontWeight: "500",
									}}>
									{" "}
									<b>Continuous Improvement</b>: Collect feedback from industry
									partners to enhance curriculum and placement strategies.
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "100svw",
					height: "20svh",
					backgroundImage: "url(../images/globalacademybg.jpg)",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
				}}>
				<h1 style={{ color: "#000" }}>This feature is Coming soon!!</h1>
			</div>
			{/* <Comingsoon /> */}
			<Footer />
		</>
	);
}
