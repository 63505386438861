import React from "react";

export default function Comingsoon() {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100svw",
				height: "100svh",
				backgroundImage: "url(../images/globalacademybg.jpg)",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}>
			<h1 style={{ color: "#000" }}>This feature is Coming soon!!</h1>
		</div>
	);
}
