import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";

export default function Modelfarm() {
	return (
		<>
			<NewNavbar />
			<div
				style={{
					backgroundImage: "url('../images/globalacademybg.jpg')",
					backgroundRepeat: "no-repeat",
				}}>
				<div
					className="container"
					style={{ paddingTop: "30px", paddingBottom: "30px" }}>
					<h4>
						MODEL FARM WITH GLOBAL FOOD SAFETY AND SUSTAINABILITY STANDARDS
					</h4>
					<p className="fs">
						Model Farm demonstrates compliance with Global Food Safety and
						Sustainability Standards while incorporating Smart Farming
						Practices.
					</p>
					<br />
					<div className="row">
						<div className="col-8">
							<img
								style={{ width: "100%", borderRadius: "25px" }}
								class=""
								src="../images/modalfarm.png"
								alt=""
							/>
						</div>
					</div>
					<br />
					<b>KEY COMPONENTS - MODEL FARM</b>

					<p className="fs">
						Demonstrating traceability and conformance to Global Food Safety
						Standards for Crop Production
					</p>
					<b>MODEL FARM INFRASTRUCTURE</b>
					<ul className="mx-5 fs" style={{ listStyle: "none" }}>
						<li>1. Skill Development Centre</li>
						<li>2. Office Quarters & Infra Center</li>
						<li>3. Apiary</li>
						<li>4. Input Preparation & Storage Facility</li>
						<li>5. Dairy Farming</li>
						<li>6. Grading & Harvested Produce</li>
						<li>7. Precision Components</li>
						<ul className="mx-5 fs" style={{ listStyle: "none" }}>
							<li>7.1 Sensor</li>
							<li>7.2 Sensor</li>
							<li>7.3 Sensor</li>
							<li>7.4 Data Transmitter & Receiver</li>
							<li>7.5 Satellite Information</li>
							<li>7.6 Drone Services</li>
						</ul>
					</ul>

					<b>ADDITIONAL INCOME FACILITATION</b>
					<p className="fs">
						Demonstrations of best practices in beekeeping, dairy farming,
						Apiary.
					</p>

					<b>VALUE ADDED SERVICES</b>
					<p className="fs">
						Demonstrations of best practices in Grading & Harvested Produce,
						Input Preparation & Storage
					</p>
				</div>
				<Footer />
			</div>
		</>
	);
}
