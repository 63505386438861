import React from "react";
import { useSelector } from "react-redux";
import LeftSide from "../../components/message/LeftSide";
import RightSide from "../../components/message/RightSide";
import LoginRequest from "../loginRequest";
import NewNavbar from "../newNavbar";
const Message = () => {
	const { profile, auth } = useSelector((state) => state);
	console.log("auth", auth);
	return (
		<>
			{/* <Header /> */}
			<NewNavbar />
			{auth.user ? (
				<div className="message d-flex">
					<div
						className="col-md-4 px-0"
						style={{ borderRight: "1px solid #ddd" }}>
						<LeftSide />
					</div>

					<div className="col-md-8 px-0">
						<RightSide />
					</div>
				</div>
			) : (
				<LoginRequest />
			)}
		</>
	);
};

export default Message;
