import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";
const Sriharikotela = () => {
	return (
		<>
			<NewNavbar />
			<div>
				<div
					className="container"
					style={{
						paddingTop: "30px",
						paddingBottom: "30px",
						// background: "white",
					}}>
					<div className="row">
						<div class="col" style={{}}>
							<center>
								<a href="">
									<img
										style={{ width: "170px", height: "170px" }}
										class=""
										src="../images/shri-hari.jpg"
										alt=""
									/>
								</a>
								<h4>Srihari kotela</h4>
							</center>
						</div>
					</div>

					{/* <h1 className="">Key Objectives</h1> */}
					<div className="row">
						<div class="col" style={{}}>
							{/* <p class="mb-4">
              Our objective is to tackle the critical challenges faced by
              farmers' organizations and food processors in adopting and scaling
              global standards. Our key objectives are to:
            </p> */}
							<p className="fs">
								Expert in Agricultural, Food Safety and Sustainability Standards
							</p>
							<ul style={{ paddingLeft: "50px" }} className="mx-5 fs">
								<li style={{ listStyle: "disc" }}>
									<b>Chairperson:</b>India Good Agricultural Practices
									Certification Committee, appointed by the Quality Council of
									India (QCI), an autonomous body under the Ministry of
									Commerce, Govt of India.
								</li>
								<li style={{ listStyle: "disc" }}>
									<b>Award Recipient:</b>Honored with the prestigious Lifetime
									Achievement Award by the President of the United States for
									lifelong dedication to strengthening the nation through
									volunteer service.
								</li>
								<li style={{ listStyle: "disc" }}>
									ISO 17065 Technical Expert for India Good Agricultural
									Practices
								</li>
								<li style={{ listStyle: "disc" }}>
									<b>Founder:</b> Food Cert India, a former leading accredited
									inspection and certification body for global food safety and
									sustainability standards. The company was successfully
									acquired by TATA Projects Ltd., a TATA group company and now
									operates as TQ Cert Services Pvt. Ltd.
									<a href="https//:www.tqcert.in" target="blank">
										(www.tqcert.in)
									</a>
								</li>
								<li style={{ listStyle: "disc" }}>
									<b>Former Committee Member: </b> Contributed to several expert
									committees formed by prominent organizations such as the
									Bureau of Indian Standards (BIS), Food Safety and Standards
									Authority of India (FSSAI), Quality Council of India,
									Department of Ayush (Government of India), and the
									Confederation of Indian Industry (CII). These roles involved
									the development of key standards, including:
								</li>
								<ul className="mx-5">
									<li>National Agricultural Code </li>
									<li>Good Hygiene Practices </li>
									<li>Retail and Animal Husbandry Practices </li>
									<li>
										Basic Food Safety Management Systems for food establishments{" "}
									</li>
									<li>
										Certification Procedures for professionals in food safety
										compliance{" "}
									</li>
									<li>Globally competitive horticulture chains </li>
									<li>
										Voluntary Certification Mark for the food processing sector{" "}
									</li>
								</ul>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default Sriharikotela;
