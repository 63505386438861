import "font-awesome/css/font-awesome.min.css";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../common/footer";
import Carousel1 from "./Carousel1";
import NewNavbar from "./newNavbar";
// import NewNavbar from "./newNavbar";
// import NewNavbar from "./NewNavbar";
// import Courselanding from "./courselanding";

const Newhome = () => {
	const responsive1 = {
		desktop: {
			breakpoint: {
				max: 3000,
				min: 1400,
			},
			items: 1,
			partialVisibilityGutter: 40,
		},
		desktop1: {
			breakpoint: {
				max: 1400,
				min: 1024,
			},
			items: 1,
			partialVisibilityGutter: 40,
		},
		mobile: {
			breakpoint: {
				max: 767,
				min: 0,
			},
			items: 1,
			partialVisibilityGutter: 30,
		},
		tablet: {
			breakpoint: {
				max: 1024,
				min: 767,
			},
			items: 1,
			partialVisibilityGutter: 30,
		},
	};
	const responsive = {
		desktop: {
			breakpoint: {
				max: 3000,
				min: 1400,
			},
			items: 4,
			partialVisibilityGutter: 40,
		},
		desktop1: {
			breakpoint: {
				max: 1400,
				min: 1024,
			},
			items: 4,
			partialVisibilityGutter: 40,
		},
		mobile: {
			breakpoint: {
				max: 767,
				min: 0,
			},
			items: 3,
			partialVisibilityGutter: 30,
		},
		tablet: {
			breakpoint: {
				max: 1024,
				min: 767,
			},
			items: 2,
			partialVisibilityGutter: 30,
		},
	};
	return (
		<div>
			{/* <Navbar /> */}

			<NewNavbar />
			<Carousel
				responsive={responsive1}
				arrows={true}
				autoPlay
				infinite
				slidesToSlide={1}>
				<div style={{ height: "400px" }}>
					<div
						className="bg"
						style={{
							backgroundImage: "url('../images/homelanding1.png')",
							// opacity: 0.7,
							filter: "contrast(.7) brightness(.7)",
							height: "400px",
							width: "100%",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}></div>
					<div className="row">
						<div
							className="col-5"
							style={{ paddingLeft: "120px", paddingTop: "40px" }}>
							<div
								// className="content"
								style={{
									position: "fixed",
									// fontFamily: "Manrope",
									fontSize: "30px",
									fontWeight: "700",
									lineHeight: "52px",
									letterSpacing: "-0.02em",
									textAlign: "left",
									color: "#fff",
								}}>
								{" "}
								Guidance on Compliance with Global
								<br /> Food Safety, Sustainability, USDA, and
								<br />
								USFDA Standards in the Food Supply
								<br /> Chain
							</div>
							{/* </div> */}
						</div>
					</div>
				</div>
				<div style={{ height: "400px" }}>
					<div
						className="bg"
						style={{
							backgroundImage: "url('../images/homelanding2.png')",
							filter: "contrast(.7) brightness(.7)",
							height: "400px",
							width: "100%",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}></div>
					<div className="row">
						<div
							className="col-5"
							style={{ paddingLeft: "120px", paddingTop: "40px" }}>
							<div
								style={{
									position: "fixed",
									// fontFamily: "Manrope",
									fontSize: "30px",
									fontWeight: "700",
									lineHeight: "52px",
									letterSpacing: "-0.02em",
									textAlign: "left",
									color: "#ffffff",
								}}>
								{" "}
								AI Technology Poised to Revolutionize
								<br /> Skill Development to facilitate compliance
								<br /> with the Standards in the food supply <br />
								chain
							</div>
						</div>
					</div>
				</div>

				<div style={{ height: "400px" }}>
					<div
						className="bg"
						style={{
							backgroundImage: "url('../images/homelanding3.jpg')",
							height: "400px",
							width: "100%",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}></div>
					<div className="row">
						<div
							className="col-5"
							style={{ paddingLeft: "120px", paddingTop: "40px" }}>
							<div
								style={{
									position: "fixed",
									// fontFamily: "Manrope",
									fontSize: "30px",
									fontWeight: "700",
									lineHeight: "52px",
									letterSpacing: "-0.02em",
									textAlign: "left",
									color: "#fff",
								}}>
								{" "}
								Unlock Your Potential and Evaluate Your
								<br /> Expertise in Compliance with Food <br />
								Supply Chain Standards
							</div>
						</div>
					</div>
				</div>
				<div style={{ height: "400px" }}>
					<div
						className="bg"
						style={{
							backgroundImage: "url('../images/homelanding4.jpg')",
							height: "400px",
							width: "100%",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}></div>
					<div className="row">
						<div
							className="col-5"
							style={{ paddingLeft: "120px", paddingTop: "40px" }}>
							<div
								style={{
									position: "fixed",
									// fontFamily: "Manrope",
									fontSize: "30px",
									fontWeight: "700",
									lineHeight: "52px",
									letterSpacing: "-0.02em",
									textAlign: "left",
									color: "#fff",
								}}>
								{" "}
								At the Global Academy, we advocate for
								<br /> inclusive, human-centered
								<br /> learning,enhanced by the power of
								<br /> technology
							</div>
						</div>
					</div>
				</div>
			</Carousel>
			<div
				className="row"
				style={{
					backgroundColor: "#1F6644",
					marginTop: "",
					color: "white",
					padding: "20px 0 20px 0",
				}}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-around",
						fontSize: "30px",
						width: "100%",
					}}>
					<span>1+</span>
					<span>1+</span>
					<span>1+</span>
					<span>1+</span>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-around",
						fontSize: "14px",
						width: "100%",
					}}>
					<span>Courses</span>
					<span>Course Takers</span>
					<span>Major Events</span>
					<span>Digital Library</span>
				</div>
			</div>
			<div className="row" style={{ backgroundColor: "#fff" }}>
				<div className="container">
					<div className="row" style={{ padding: "50px 0 100px 0" }}>
						<div
							className="col-lg-6 col-md-12"
							style={{ padding: "40px 0px 0px 40px" }}>
							<div
								style={{
									fontSize: "42px",
									fontWeight: "600",
									lineHeight: "52px",
								}}>
								<div style={{ color: "#1F6644" }}>
									Ensuring Better Food <br />
									Standards Worldwide
								</div>
							</div>

							<div
								className="fs"
								style={{
									fontSize: "14px",
									fontWeight: "400",
									lineHeight: "22px",
									marginTop: "20px",
								}}>
								We are committed to digitally empowering stakeholders across the
								food supply chain by promoting compliance with global Food
								Safety and Sustainability Standards. Through our innovative
								approach, we bridge the gap between knowledge and practice,
								supporting farmers, processors, and other food supply chain
								actors in meeting international standards.
							</div>

							<div className="mt-3">
								<button
									onClick={() => (window.location.href = "/aboutus")}
									style={{
										width: "100px",
										height: "45px",
										borderRadius: "12px",
										backgroundColor: "#E8A700",
										border: "2px solid #E8A700",
									}}>
									<div style={{ color: "black" }}>Learn More</div>
								</button>
							</div>
						</div>

						<div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
							<img
								style={{
									//   width: "50%",
									maxWidth: "80%",
									height: "auto",
									borderRadius: "10px",
								}}
								className="img-fluid"
								src="../images/homelanding3.jpg"
								alt="Farming"
							/>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row" style={{ paddingBottom: "50px" }}>
						<div
							className="col-lg-12 col-md-12"
							// style={{ padding: "40px 0px 0px 40px" }}
						>
							<div className="col-11">
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}>
									<div
										style={{
											fontSize: "42px",
											fontWeight: "600",
											lineHeight: "52px",
											color: "#1F6644",
										}}>
										What Global Academy does
									</div>
									<div className="mt-6">
										<button
											onClick={() => (window.location.href = "/aboutus")}
											style={{
												width: "100px",
												height: "45px",
												borderRadius: "12px",
												backgroundColor: "#E8A700",
												border: "2px solid #E8A700",
												display: "block",
											}}>
											<div style={{ color: "black" }}>Learn More</div>
										</button>
									</div>
								</div>
							</div>

							<div
								className="fs"
								style={{
									fontSize: "14px",
									fontWeight: "400",
									lineHeight: "22px",
									marginBottom: "10px",
									paddingBottom: "50px",
								}}>
								Bridging the gap between knowledge and practice and empowering
								farmers to reduce their carbon footprint by adhering to these
								standards.
							</div>
							<div
								// className="row"
								style={{
									display: "flex",
									flexWrap: "wrap",
									gap: "10px",
									// paddingRight: "40px",
								}}>
								{/* <img
									className="col-3"
									style={{
										// width: "100%",
										// maxWidth: "calc(25% - 10px)",
										height: "auto",
										borderRadius: "10px",
									}}
									src="../images/DigitalLibraryimg.png"
									alt="Digital Library"
								/> */}
								<div
									// className="col-3"
									onClick={() =>
										(window.location.href =
											"/courses/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af")
									}
									style={{
										borderRadius: "12px 160px 12px 160px",
										height: "372px",
										backgroundColor: "#1F6644",
										flexDirection: "column",
										padding: "0",
										width: "100%",
										maxWidth: "calc(25% - 10px)",
										textAlign: "right",
										border: "1px solid green",
										cursor: "pointer",
									}}>
									<img
										alt="jobs"
										src="../images/coursesnew.jpg"
										style={{
											// position: "absolute",
											width: "100%",
											height: "197px",
											textAlign: "end",
											borderRadius: "12px 160px 0 0 ",
											// border: "1px solid green",
										}}
									/>
									<div>
										<p className="new-title-header">Courses</p>
										<p className="new-title-content">
											Skill Development Courses on Global Food Safety and
											Sustainability Standards to strengthen your expertise.
										</p>
									</div>
								</div>
								{/* <img
									className="col-3"
									style={{
										width: "100%",
										maxWidth: "calc(25% - 10px)",
										height: "auto",
										borderRadius: "10px",
									}}
									src="../images/Coursesimg.png"
									alt="Courses"
								/> */}

								<div
									// className="col-3"
									onClick={() =>
										(window.location.href =
											"/digitallibrary/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af")
									}
									style={{
										borderRadius: "160px 12px 160px 12px",
										height: "372px",
										backgroundColor: "#1F6644",
										flexDirection: "column",
										padding: "0",
										width: "100%",
										maxWidth: "calc(25% - 10px)",
										// display: "grid",
										border: "1px solid green",
										cursor: "pointer",
									}}>
									<img
										alt="jobs"
										src="../images/digitallibrarynew.jpg"
										style={{
											// position: "absolute",
											width: "100%",
											height: "197px",
											textAlign: "end",
											borderRadius: "160px 12px 0 0 ",
											// border: "1px solid green",
										}}
									/>
									<div>
										<p className="new-title-header">Digital Library</p>
										<p className="new-title-content">
											Collection of videos on good agricultural practices in the
											implementation of On Farm Production related Standards.
										</p>
									</div>
								</div>
								{/* <img
									// className="col-3"
									style={{
										width: "100%",
										maxWidth: "calc(25% - 10px)",
										height: "auto",
										borderRadius: "10px",
									}}
									src="../images/DiscussionBoardimg.png"
									alt="Discussion Board"
								/> */}
								<div
									// className="col-3"
									onClick={() => (window.location.href = "/foodsafetyacademy")}
									style={{
										borderRadius: "12px 160px 12px 160px",
										height: "372px",
										backgroundColor: "#1F6644",
										flexDirection: "column",
										padding: "0",
										width: "100%",
										maxWidth: "calc(25% - 10px)",
										textAlign: "right",
										border: "1px solid green",
										cursor: "pointer",
									}}>
									<img
										alt="jobs"
										src="../images/discusionboardimg.jpg"
										style={{
											// position: "absolute",
											width: "100%",
											height: "197px",
											textAlign: "end",
											borderRadius: "9px 158px 0 0 ",
											// border: "1px solid green",
										}}
									/>
									<div>
										<p className="new-title-header">Discussion Board</p>
										<p className="new-title-content">
											Join the discussion Board to engage with peers and
											professionals in the food safety and sustainability
											sector.
										</p>
									</div>
								</div>
								<div
									// className="col-3"
									onClick={() =>
										(window.location.href =
											"/jobs/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af")
									}
									style={{
										borderRadius: "160px 12px 160px 12px",
										height: "372px",
										backgroundColor: "#1F6644",
										flexDirection: "column",
										padding: "0",
										width: "100%",
										maxWidth: "calc(25% - 10px)",
										cursor: "pointer",
										border: "1px solid green",
									}}>
									<img
										alt="jobs"
										src="../images/jobsnew.jpg"
										style={{
											// position: "absolute",
											width: "100%",
											height: "197px",
											textAlign: "end",
											borderRadius: "160px 12px 0 0 ",
											// border: "1px solid green",
										}}
									/>
									<div>
										<p className="new-title-header">
											Placement <br /> Opportunities
										</p>
										<p className="new-title-content">
											Opportunities for for food safety professionals and
											graduates across the food supply chain.
										</p>
									</div>
								</div>
								{/* <img
									style={{
										width: "100%",
										maxWidth: "calc(25% - 10px)",
										height: "auto",
										borderRadius: "10px",
									}}
									src="../images/Eventsimg.png"
									alt="Events"
								/> */}
							</div>
						</div>
					</div>
				</div>

				<div className="col-12" style={{ padding: "40px 0px 0px 40px" }}>
					<div
						className="row"
						style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
						// style={{ cursor: "pointer" }}
						onClick={() =>
							(window.location.href =
								"/courses/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af")
						}>
						<div className="col-lg-12 ">
							{/* <div
								style={{
									// fontFamily: "Manrope",
									fontSize: "34px",
									fontWeight: "500",
									color: "#1F6644",
									lineHeight: "31.96px",
									paddingBottom: "5px",
								}}>
								Courses
							</div> */}
							<div className="row mt-2">
								{/* <div
									className="fs"
									style={{
										// fontFamily: "Manrope",
										fontSize: "14px",
										fontWeight: "400",
										lineHeight: "14px",
										paddingBottom: "50px",
									}}>
									Skill Development Courses on Global Food Safety and
									Sustainability Standards are designed to strengthen your
									expertise.
								</div> */}

								{/* <Carousel
									responsive={responsive}
									arrows={true}
									autoPlay
									infinite
									slidesToSlide={1}>
									<div className="col-12" style={{}}>
										<img
											alt="courses"
											src="../images/Courses1img.png"
											style={{
												width: "330px",
												height: "230px",
												border: "1px solid green",
												borderRadius: "5px",
											}}
										/>
										<div
											style={{
												marginTop: "8px",
												fontSize: "14px",
												color: "#333",
												textAlign: "left",
											}}>
											<b>Storage and Warehouses</b>
											<br />
											{/* Duration- 8 hrs *
										</div>
									</div>
									<div className="col-12" style={{}}>
										<img
											alt="courses"
											src="../images/Courses2img.png"
											style={{
												width: "330px",
												height: "230px",
												// height: "130px",
												border: "1px solid green",
												borderRadius: "5px",
												marginLeft: "20px",
											}}
										/>
										<div
											style={{
												marginTop: "8px",
												fontSize: "14px",
												color: "#333",
												textAlign: "left",
												marginLeft: "20px",
											}}>
											<b>General Manufacturing of Spices</b> <br />
											{/* Duration- 8 hrs *
										</div>
									</div>
									<div className="col-12" style={{}}>
										<img
											alt="courses"
											src="../images/Courses3img.png"
											style={{
												width: "330px",
												height: "230px",
												border: "1px solid green",
												borderRadius: "5px",
												marginLeft: "20px",
											}}
										/>
										<div
											style={{
												marginTop: "8px",
												fontSize: "14px",
												color: "#333",
												textAlign: "left",
												marginLeft: "20px",
											}}>
											<b>Catering</b>
											<br />
											{/* Duration- 4 hrs *
										</div>
									</div>
									<div className="col-12" style={{}}>
										<img
											alt="courses"
											src="../images/Courses4img.png"
											style={{
												width: "330px",
												height: "230px",
												// height: "130px",
												border: "1px solid green",
												borderRadius: "5px",
												marginLeft: "20px",
												// marginTop: "20px",
											}}
										/>
										<div
											style={{
												marginTop: "8px",
												fontSize: "14px",
												color: "#333",
												marginLeft: "20px",
												textAlign: "left",
											}}>
											<b> General Manufacturing of organic vegetables</b> <br />
											{/* Duration- 20 hrs *
										</div>
									</div>
								</Carousel> */}

								{/* <div
									// className="mt-3"
									style={{
										marginBottom: "10px",
										paddingBottom: "50px",
										paddingTop: "50px",
									}}>
									<button
										onClick={() => (window.location.href = "/aboutus")}
										style={{
											width: "130px",
											height: "45px",
											borderRadius: "12px",
											backgroundColor: "#E8A700",
											border: "2px solid #E8A700",
											marginLeft: "auto",
											marginRight: "auto",
											display: "block",
										}}>
										<div style={{ color: "black" }}>View All Courses</div>
									</button>
								</div> */}
							</div>
						</div>
					</div>
					{/* <hr style={{ height: "1px", color: "black" }}></hr> */}

					{/* <div className="col-12">
						<div
							style={{
								fontSize: "34px",
								fontWeight: "500",
								lineHeight: "31.96px",
								paddingBottom: "5px",
								color: "#1F6644",
								paddingTop: "50px",
								// textAlign: "center", // Center the heading
							}}>
							Digital Library
						</div>
						<div
							className="row mt-2 justify-content-center"
							style={{ paddingBottom: "50px" }}>
							<div
								className="fs"
								style={{
									fontSize: "14px",
									fontWeight: "400",
									lineHeight: "14px",
									paddingBottom: "50px",
									//   textAlign: "center", // Center the description
								}}>
								Collection of videos on good agricultural practices in the
								implementation of on Farm Production related Standards.
							</div>
							<div
								className="row mt-4 justify-content-center"
								style={{
									display: "flex",
									alignItems: "center",
								}}>
								<div className="col-12 col-md-6 d-flex justify-content-center">
									<img
										onClick={() =>
											(window.location.href =
												"/digitallibrary/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af")
										}
										alt="digital library"
										src="../images/DigitalLibrary1img.png"
										style={{
											cursor: "pointer",
											height: "400px",
											border: "1px solid green",
											borderRadius: "5px",
										}}
									/>
								</div>
								<div className="col-12 col-md-6 d-flex justify-content-center">
									<img
										onClick={() =>
											(window.location.href =
												"/digitallibrary/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af")
										}
										alt="digital library"
										src="../images/DigitalLibrary2img.png"
										style={{
											cursor: "pointer",
											height: "400px",
											border: "1px solid green",
											borderRadius: "5px",
										}}
									/>
								</div>
							</div>
						</div>
					</div> */}

					{/* <hr style={{ height: "1px", color: "black" }}></hr> */}
				</div>
				{/* <div
					className="row"
					style={{
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
						paddingTop: "50px",
						paddingBottom: "50px",
					}}
					// onClick={() => (window.location.href = "/foodsafetyacademy")}
				>
					<div
						className="col-12"
						style={{
							backgroundImage: `url('./images/DiscussionBoardBackground.png'), url('../images/DBboardimg.png')`, // Two background images: the board and DBboardimg
							backgroundSize: "cover, 250px", // Cover the first image fully, while setting size for the second
							backgroundRepeat: "no-repeat, no-repeat", // Prevent both images from repeating
							backgroundPosition: "center, right bottom", // Center the background and place the DBboard image in the right corner
							marginBottom: "30px",
							minHeight: "400px", // Ensure enough height for the background
							padding: "20px",

							// margin:"0px"
						}}>
						<div
							style={{
								fontSize: "34px",
								fontWeight: "500",
								lineHeight: "31.96px",
								paddingBottom: "5px",
								color: "white",
							}}>
							Discussion Board
							<div className="row mt-2">
								<div
									className="fs"
									style={{
										fontSize: "14px",
										fontWeight: "400",
										lineHeight: "14px",
										color: "white",
									}}>
									Join the discussion Board to engage with peers and
									professionals in the food safety and sustainability sector.
								</div>
							</div>
							<div>
								<img
									alt="discussion board"
									src="../images/DBboardimg.png"
									style={{
										height: "250px",
										borderRadius: "5px",
										marginTop: "10px",
										marginLeft: "auto", // This will push the image to the right
										display: "block", // Ensure the image is treated as a block element
										marginRight: "2%",
									}}
								/>
							</div>
						</div>
						<div className="mt-3" style={{ marginBottom: "10px" }}>
							<button
								onClick={() => (window.location.href = "/foodsafetyacademy")}
								style={{
									width: "100px",
									height: "45px",
									borderRadius: "12px",
									backgroundColor: "#E8A700",
									border: "2px solid #E8A700",
									display: "block",
								}}>
								<div style={{ color: "black" }}>Know More</div>
							</button>
						</div>
					</div>
				</div> */}

				<div style={{ padding: "0px 0px 0px 40px" }}>
					<div> {/* <Eventlanding /> */}</div>
					<div>
						<div
							style={{
								fontSize: "34px",
								fontWeight: "500",
								lineHeight: "31.96px",
								paddingBottom: "5px",
								color: "#1F6644",
								paddingTop: "50px",
								// textAlign: "center", // Center the heading
							}}>
							Read Reviews From Course takers
						</div>
						<div
							className="row mt-2 justify-content-center"
							style={{ paddingBottom: "50px" }}>
							<div
								className="fs"
								style={{
									fontSize: "14px",
									fontWeight: "400",
									lineHeight: "14px",
									paddingBottom: "50px",
									//   textAlign: "center", // Center the description
								}}>
								Skill development of farmers organizations and food processors
								with ease and scale of implementation of food safety and
								sustainable global standards.
							</div>
						</div>
						<Carousel1
							responsive={responsive}
							arrows={true}
							autoPlay
							infinite
							slidesToSlide={1}>
							<div
								style={{
									border: "1px solid #ddd", // Optional border to make the container visible
									padding: "20px",
									borderRadius: "8px",
									width: "300px", // Adjust based on your needs
									height: "auto",
									boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Adds shadow for a card-like feel
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
								}}>
								{/* Main Text Content */}
								<div style={{ marginBottom: "20px" }}>
									<p
										style={{
											fontSize: "16px",
											fontWeight: "500",
											color: "#333",
											lineHeight: "1.5",
										}}>
										Here's some engaging text content in the container. It
										provides a brief overview of something important.
									</p>
								</div>

								{/* Profile Section */}
								<div
									style={{
										display: "flex",
										alignItems: "center",
										borderTop: "1px solid #ddd",
										paddingTop: "10px",
									}}>
									{/* Profile Picture */}
									<img
										src="../images/profile-pic.png" // Replace with your profile picture source
										alt="Profile Pic"
										style={{
											width: "40px",
											height: "40px",
											borderRadius: "50%",
											marginRight: "10px",
										}}
									/>

									{/* Profile Name and Posted Time */}
									<div style={{ display: "flex", flexDirection: "column" }}>
										<span
											style={{
												fontSize: "14px",
												fontWeight: "600",
												color: "#333",
											}}>
											John Doe
										</span>
										<span
											style={{
												fontSize: "12px",
												fontWeight: "400",
												color: "#777",
											}}>
											Posted 2 hours ago
										</span>
									</div>
								</div>
							</div>
						</Carousel1>
					</div>
					<div className="col-12">
						<div
							style={{
								marginBottom: "3%",
								marginTop: "3%",
								// marginRight:"12%",
								backgroundImage: "url('../images/FoodStandardsimg.png')",
								height: "auto",
								width: "100%",
								// borderBottomRightRadius:"18%",
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover", // Ensures background image covers the div without distortion
								display: "flex", // Use flexbox for side-by-side layout
								// justifyContent: "space-between", // Space out the text and the image
								alignItems: "center", // Vertically center the content
								// padding: "5% 0", // Add padding around the content
							}}
							//   className="img-fluid"
						>
							{/* Left Text Content */}
							<div className="col-6">
								<div
									style={{
										fontSize: "34px",
										fontWeight: "500",
										lineHeight: "31.96px",
										paddingLeft: "10%",
										paddingTop: "10%",
										color: "black",
									}}>
									Raising Food Standards <br /> Globally!
									<div className="row mt-2">
										{/* <div
											className="fs"
											style={{
												fontSize: "14px",
												fontWeight: "400",
												lineHeight: "14px",
												color: "white",
												paddingTop: "2%",
											}}>
											Start your journey with Krishi gap today!
										</div> */}
									</div>
									<div
										className="mt-3"
										style={{ marginBottom: "10px", paddingTop: "13%" }}>
										<button
											onClick={() => (window.location.href = "/aboutus")}
											style={{
												width: "auto",
												height: "auto",
												borderRadius: "12px",
												backgroundColor: "#1F6644",
												display: "block",
												padding: "10px",
											}}>
											<div style={{ color: "white", fontSize: "16px" }}>
												Start your Journey
											</div>
										</button>
									</div>
								</div>
							</div>

							{/* Right Side Image */}
							<div className="col-6">
								<img
									height="415px"
									alt="Food Standards"
									src="../images/LOGO.PNG"
									style={{
										marginLeft: "2%",
										float: "right",
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};
export default Newhome;
