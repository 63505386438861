import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";
const Shashi_sareen = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/T_Appaji_Rao.jpg"
						alt=""
					/>

					<h4 className="">T.Appaji Rao</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Registered Principle Consultant –FSMS, HACCP with NBQP- Quality
								Council of India.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Approved Trainer for HACCP by DNV– Norway.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Approved FoSTaC Trainer.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								FSMS Lead Auditor Trainer.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Qualified FSSAI Food Hygiene Auditor in from FSSAI2021.
							</li>{" "}
						</ul>

						<b>Past</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								15 Years of work and over 20 years of consultancy and training
								experience in Dairy Value Chain.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Consultant; Trainer; Auditor For Food Safety (Including BRC
								Food), QMS, EMS & OHSAS.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Adl. G.M.(Production) of UHT Treated Milk and Aseptic Packing of
								Milk and Milk Products at AMRIT FOODS.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Manager in Production ,Procurement & Sales of Milk and Milk
								Products (including packing of UHT Treated Milk and Milk.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Conducted over 700 Audits as Specialist , Auditor and Lead
								Auditor for Certification Bodies.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Provided Consultation to more than 250 organizations in
								attaining certifications towards ISO9001, ISO 14001, OHSAS 18001
								and HACCP & ISO22000, FSSC22000.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Provided Consultation for developing FSSAI Schedule 4
								Requirements of GMP &GHP for Dairy industries for successful III
								Party Audits.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Training on GMP, GHP & HACCP and auditing to the Auditors of
								Bangladesh Standards Institute, Dhaka to attain NABCB
								accreditation.
							</li>{" "}
						</ul>
						<br />
						<b>Professional Qualifications:</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Certified Lead Auditor for Quality Management Systems.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Certified Lead Auditor for Food Safety Systems-HACCP.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Certified Lead Auditor for EMS 14001,OHSAS18001.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Lead Auditor –Energy Management System.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Certified Assessor for Laboratory Accreditation–ISO17025:2017&
								ILAC-G3.
							</li>{" "}
						</ul>
						<br />

						<b>Education</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Bachelor of Science Dairy Technology– 1983 from National Dairy
								Research Institute.
							</li>{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Shashi_sareen;
