import React from "react";
import Footer from "../common/footer";
import NewNavbar from "./newNavbar";
// import Header from "../components/header/Header";
const Shashi_sareen = () => {
	return (
		<>
			<NewNavbar />
			<div
				className="container"
				style={{ paddingTop: "30px", paddingBottom: "30px" }}>
				<center>
					<img
						style={{ width: "201px", height: "201px" }}
						//   class="img-fluid"
						src="../images/Dr.-P-V-S-M-Gouri.jpg"
						alt=""
					/>

					<h4 className="">Dr. P V S M Gouri</h4>
				</center>
				<div className="row">
					<div class="col" style={{ paddingLeft: "50px" }}>
						<br />
						<b>Present</b>
						<br />
						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Executive Director & CEO , Association of Indian Organic
								Industry, New Delhi
							</li>{" "}
						</ul>

						<b>Past</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								A professional with over 25 years of experience spanning
								Corporate, Academic and Regulatory sectors.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Technical Advisor, Suminter Organics (India) Pvt Limited) 2018-
								2019).Responsible for strategic advisory assignments for
								agribusiness in the area of certification agriculture export and
								R&D etc.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Vice President, Food & Organics, Round Glass H2O) Pvt Ltd
								(2016-2018). Responsible for implementation of Quality
								Management System in the organic food vertical.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Advisor, APEDA and National Accreditation Body (NAB) 2003-2015.
								Overall development of the National Organic Program for
								Production (NPOP) implemented by Agricultural & Processed Food
								Export Development Authority (APEDA) under the Ministry of
								Commerce and Industry, Govt of India for development of exports.
								Framing of National Standards under NPOP and Capacity building.
								Establishment and implementation of the Quality Management
								System (QMS) for accreditation of Certification Bodies (CBs),
								assessment & audits of the Certification Bodies.
							</li>{" "}
						</ul>
						<br />
						<b>Acadamic and Membership Expereince</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Assessor with National Body for Accreditation of Certification
								Bodies (NABCB)since September 2018.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Represented APEDA in Codex Alimentarius (2004-2015)
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Represented APEDA as an Expert in several National Committees of
								Ministry of Agriculture, Bureau of Indian Standards (BIS), Food
								Standards and Safety Authority (FSSAI), Ministry of Environment
								and Forests (2009-10), Ministry of Textiles, Government of India
								(2009-10) and Planning Commission.
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								The International Task Force on Harmonization and Equivalence in
								Organic Agriculture (IFOAM/UNCTAD/FAO) (2003-2008).
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Working group member of The Criteria Committee of The
								International Federation for Organic Agricultural Movements
								(IFOAM), Germany (2001-2002).
							</li>{" "}
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Indira Gandhi National Open University (IGNOU) (2003-2006), New
								Delhi Visiting Faculty on Organic Agriculture Developed the
								curriculum and course material for the certificate course in
								organic farming.
							</li>{" "}
						</ul>
						<br />

						<b>Education</b>
						<br />

						<ul className="mx-5 fs">
							<li
								style={{
									listStyle: "disc",

									//   fontWeight: "500",
								}}>
								{" "}
								Ph.D. in Life Sciences & Post Doctorate in Tissue culture.
							</li>{" "}
						</ul>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Shashi_sareen;
